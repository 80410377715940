import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, Space, Table, Button, notification, Spin, Row, Col, Statistic, Tag } from 'antd';
import { DownloadOutlined, DollarOutlined, CreditCardOutlined, QrcodeOutlined, BankOutlined, WalletOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const DailySalesDashboard = () => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };


  const fetchSalesData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
  
      // Adjust the endDate to include the entire day
      const adjustedEndDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(
        `${API_URL}/payment/income-reports`,
        {
          params: {
            startDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: adjustedEndDate,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      processData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({
        message: 'Error fetching data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [API_URL, startDate, endDate]);
  

  useEffect(() => {
    fetchSalesData();
  }, [fetchSalesData]);

  const processData = (rawData) => {
    const grouped = rawData.reduce((acc, payment) => {
      const date = moment(payment.created_at).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = {
          date,
          cash: 0,
          credit_card: 0,
          qr_code: 0,
          transfer: 0,
          na: 0,
          total: 0,
        };
      }

      const method = payment.payment_method?.toLowerCase() || 'na';
      const amount = Number(payment.payment_amount) || 0;

      switch (method) {
        case 'cash':
          acc[date].cash += amount;
          break;
        case 'credit card':
          acc[date].credit_card += amount;
          break;
        case 'qr code':
          acc[date].qr_code += amount;
          break;
        case 'transfer':
          acc[date].transfer += amount;
          break;
        default:
          acc[date].na += amount;
      }
      
      acc[date].total += amount;
      return acc;
    }, {});

    setSalesData(Object.values(grouped).sort((a, b) => moment(a.date).diff(moment(b.date))));
  };

  const exportToCSV = () => {
    const headers = [
      t('date'),
      t('cash'),
      t('credit_card'),
      t('qr_code'),
      t('transfer'),
      t('na'),
      t('total')
    ];
    const csvData = salesData.map(row => [
      row.date,
      row.cash.toFixed(2),
      row.credit_card.toFixed(2),
      row.qr_code.toFixed(2),
      row.transfer.toFixed(2),
      row.na.toFixed(2),
      row.total.toFixed(2)
    ]);
  
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${t('sales_report')}_${startDate}_${endDate}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportToPDF = async () => {
    try {
      const element = document.querySelector('.daily-reports-container');
      if (!element) {
        notification.error({
          message: 'Error',
          description: 'Could not find the report container to export.',
        });
        return;
      }
  
      // Adjust to include styles for the exported PDF
      const options = {
        scale: 2, // Higher scale for better resolution
        useCORS: true,
        backgroundColor: '#ffffff', // Ensure white background
      };
  
      const canvas = await html2canvas(element, options);
      const imageData = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF('p', 'pt', 'a4'); // Portrait, points, A4 size
      const margin = 24; // Margin in points (24px)
      const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2; // Adjust for left and right margins
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      // Add image to the PDF with margin
      pdf.addImage(imageData, 'PNG', margin, margin, pdfWidth, pdfHeight);
  
      // If content exceeds one page, add additional pages
      let currentHeight = pdfHeight + margin;
      while (currentHeight > pdf.internal.pageSize.getHeight()) {
        pdf.addPage();
        currentHeight -= pdf.internal.pageSize.getHeight();
        pdf.addImage(imageData, 'PNG', margin, -(currentHeight - margin), pdfWidth, pdfHeight);
      }
  
      const fromDate = moment(startDate).format('YYYY-MM-DD');
      const toDate = moment(endDate).format('YYYY-MM-DD');
  
      pdf.save(`Daily_Sales_Report_${fromDate}_to_${toDate}.pdf`);
      notification.success({
        message: 'Success',
        description: 'PDF exported successfully.',
      });
    } catch (error) {
      console.error('Error exporting PDF:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to export PDF.',
      });
    }
  };
  

  const calculateTotals = () => {
    return salesData.reduce((acc, row) => ({
      cash: acc.cash + row.cash,
      credit_card: acc.credit_card + row.credit_card,
      qr_code: acc.qr_code + row.qr_code,
      transfer: acc.transfer + row.transfer,
      na: acc.na + row.na,
      total: acc.total + row.total
    }), { cash: 0, credit_card: 0, qr_code: 0, transfer: 0, na: 0, total: 0 });
  };

  const totals = calculateTotals();

  const columns = [
    {
        title: t('date'),
        dataIndex: 'date',
        key: 'date',
        render: (date) => formatDate(date),
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },

    {
      title: 'Cash',
      dataIndex: 'cash',
      key: 'cash',
      align: 'right',
      render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2 }),
    },
    {
      title: 'Credit Card',
      dataIndex: 'credit_card',
      key: 'credit_card',
      align: 'right',
      render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2 }),
    },
    {
      title: 'QR Code',
      dataIndex: 'qr_code',
      key: 'qr_code',
      align: 'right',
      render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2 }),
    },
    {
      title: 'Transfer',
      dataIndex: 'transfer',
      key: 'transfer',
      align: 'right',
      render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2 }),
    },
    {
      title: 'N/A',
      dataIndex: 'na',
      key: 'na',
      align: 'right',
      render: value => value.toLocaleString(undefined, { minimumFractionDigits: 2 }),
    },
    {
        title: t('total'),
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        render: (value) => (
          <div className="total-cell">
            <span className="total-currency">฿</span>
            {value.toLocaleString(undefined, { minimumFractionDigits: 2 })}
          </div>
        ),
        sorter: (a, b) => a.total - b.total,
      }
  ];

  return (
    <Spin spinning={loading}>
      <div className="daily-reports-container">
        <Space direction="vertical" size="large" className="w-full">
          <Card>
            <Space className="w-full" direction="vertical">
              <Row justify="space-between" align="middle">
                <Col>
                  <Space size="middle">
                    <Space>
                      <span>{t('From')}:</span>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select a date"
                        dateFormat="dd-MM-yyyy"
                        className="ant-datepicker"
                      />
                    </Space>
                    <Space>
                      <span>{t('To')}:</span>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="Select end date"
                        dateFormat="dd-MM-yyyy"
                        className="ant-datepicker"
                      />
                    </Space>
                    <Button 
                      icon={<DownloadOutlined />}
                      onClick={exportToCSV}
                    >
                      {t('export_to_csv')}
                    </Button>
                    <Button
                    icon={<DownloadOutlined />}
                    onClick={exportToPDF}
                    >
                    {t('Export to PDF')}
                    </Button>

                  </Space>
                </Col>
              </Row>
            </Space>
          </Card>
  
          {/* Statistics Cards */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <DollarOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#1890ff' }} />
                      <span>{t('ยอดทั้งหมด')}</span>
                    </div>
                  }
                  value={totals.total}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <WalletOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#52c41a' }} />
                      <span>{t('cash')}</span>
                    </div>
                  }
                  value={totals.cash}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <CreditCardOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#722ed1' }} />
                      <span>{t('credit_card')}</span>
                    </div>
                  }
                  value={totals.credit_card}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <QrcodeOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#faad14' }} />
                      <span>{t('qr_code')}</span>
                    </div>
                  }
                  value={totals.qr_code}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <BankOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#eb2f96' }} />
                      <span>{t('transfer')}</span>
                    </div>
                  }
                  value={totals.transfer}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <Card bordered={false} className="statistic-card">
                <Statistic
                  title={
                    <div className="statistic-title">
                      <QuestionCircleOutlined className="statistic-icon" style={{ fontSize: '24px', color: '#bfbfbf' }} />
                      <span>{t('ไม่ระบุ')}</span>
                    </div>
                  }
                  value={totals.na}
                  precision={2}
                  prefix="฿"
                />
              </Card>
            </Col>
          </Row>
  
          {/* Charts */}
          <Row gutter={16}>
            <Col span={24}>
              <Card title={t('ยอดรายรับ')}>
                <div style={{ height: 400 }}>
                  <ResponsiveContainer>
                    <LineChart data={salesData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#1890ff"
                        name={t('total_sales')}
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>
  
          <Row gutter={16}>
            <Col span={24}>
              <Card title={t('ยอดรายรับตามวิธีการชำระเงิน')}>
                <div style={{ height: 400 }}>
                  <ResponsiveContainer>
                    <BarChart data={salesData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="cash"
                        fill="#52c41a"
                        name={t('cash')}
                        stackId="a"
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                        <Bar
                        dataKey="credit_card"
                        fill="#1890ff"
                        name={t('credit_card')}
                        stackId="a"
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                        <Bar
                        dataKey="qr_code"
                        fill="#faad14"
                        name={t('qr_code')}
                        stackId="a"
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                        <Bar
                        dataKey="transfer"
                        fill="#722ed1"
                        name={t('transfer')}
                        stackId="a"
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                        <Bar
                        dataKey="na"
                        fill="#bfbfbf"
                        name={t('na')}
                        stackId="a"
                        label={{ position: 'top', formatter: (value) => `฿${value.toLocaleString()}` }}
                        />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </Col>
          </Row>
  
          {/* Data Table */}
          <Card>
            <Table
              columns={columns.map(col => ({
                ...col,
                title: t(col.dataIndex || col.key)
              }))}
              dataSource={salesData}
              pagination={false}
              scroll={{ x: true }}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>{t('total')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {totals.cash.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      {totals.credit_card.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      {totals.qr_code.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      {totals.transfer.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      {totals.na.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      {totals.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Card>
        </Space>
      </div>
    </Spin>
  );
};

export default withRoleAccess(DailySalesDashboard, ['superadmin']);
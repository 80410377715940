import React from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { DollarOutlined, CreditCardOutlined, TeamOutlined, CalendarOutlined, FileTextOutlined, CoffeeOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../hoc/withRoleAccess';
import './Dashboard.css';

const AdDashboard = () => {
  const { t } = useTranslation();

  const menuItems = [
    { icon: <CoffeeOutlined />, title: 'admin', link: '/adminpanel' },
    { icon: <DollarOutlined />, title: 'sales', link: '/sales' },
    { icon: <CreditCardOutlined />, title: 'payment', link: '/payment' },
    { icon: <FileTextOutlined />, title: 'expense', link: '/expense' },
    { icon: <TeamOutlined />, title: 'graduategroup', link: '/graduategroup' },
    { icon: <CalendarOutlined />, title: 'class', link: '/allclasses' },
  ];

  return (
    <div style={{ padding: '32px' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '24px', textAlign: 'center' }}>{t('Admin Dashboard')}</h1>
      <Row gutter={[32, 32]}>
        {menuItems.map((item, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Link to={item.link}>
              <Card 
                hoverable 
                className="dashboard-button-card"
                style={{ 
                  height: '200px', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  borderRadius: '15px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  transition: 'all 0.3s'
                }}
              >
                {React.cloneElement(item.icon, { style: { fontSize: '4rem', marginBottom: '16px' } })}
                <h2 style={{ fontSize: '1.5rem', margin: 0 }}>{t(item.title)}</h2>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default withRoleAccess(AdDashboard, ['admin']);
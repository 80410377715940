import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthContext'; // Make sure this path is correct
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>  
          <App />  
        </AuthProvider>
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();

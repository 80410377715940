import React, { useState } from 'react';
import { Tabs } from 'antd';
import TestBooking from './TestBooking';
import TestResult from './TestResult';
import TestList from './TestList';
import TestBookingPage from './TestBookingPage';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import './Test.css'; // Import the CSS file

const { TabPane } = Tabs;

const MainTest = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("2");

  const handleTabChange = (key) => {
    setActiveKey(key); // Update the active tab key
  };

  return (
    <div className="main-test-container">
      <Sidebar />
      <Header />
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab={t('Book Test')} key="1">
          <TestBookingPage key={activeKey} /> {/* Force refresh */}
        </TabPane>
        <TabPane tab={t('Test List')} key="2">
          <TestBooking key={activeKey} /> {/* Force refresh */}
        </TabPane>
        <TabPane tab={t('Add Test Result')} key="3">
          <TestResult key={activeKey} /> {/* Force refresh */}
        </TabPane>
        <TabPane tab={t('Pass List')} key="4">
          <TestList key={activeKey} /> {/* Force refresh */}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withRoleAccess(MainTest, ['admin', 'superadmin', 'teacher']);

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Table, Input, Select, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import moment from 'moment';
import 'antd/dist/reset.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

const { Option } = Select;

const TestResult = () => {
  const { t, i18n } = useTranslation();
  const [bookings, setBookings] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [resultData, setResultData] = useState({});
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]); 
  const [resultFilter, setResultFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchBookings = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test/book-test`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const fetchedBookings = response.data || [];
      setBookings(fetchedBookings); // Store the raw data
      setFilteredBookings(fetchedBookings); // Initially, set filtered data to the full list
    } catch (error) {
      console.error('Error fetching test bookings:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      const [startDate, endDate] = dates;
      let filtered = bookings.filter(booking =>
        moment(booking.test_date).isBetween(
          moment(startDate).startOf('day'),
          moment(endDate).endOf('day'),
          null,
          '[]'
        )
      );
      if (resultFilter !== 'all') {
        filtered = filtered.filter(booking => booking.pass_fail === resultFilter);
      }
      setFilteredBookings(filtered);
    } else {
      let filtered = bookings;
      if (resultFilter !== 'all') {
        filtered = filtered.filter(booking => booking.pass_fail === resultFilter);
      }
      setFilteredBookings(filtered);
    }
  };
  const handleSearch = (value) => {
    const searchTerm = value.toLowerCase();
    let filtered = bookings.filter(booking =>
      (booking.student_firstnamethai && booking.student_firstnamethai.toLowerCase().includes(searchTerm)) ||
      (booking.student_lastnamethai && booking.student_lastnamethai.toLowerCase().includes(searchTerm)) ||
      (booking.student_firstnameenglish && booking.student_firstnameenglish.toLowerCase().includes(searchTerm)) ||
      (booking.student_lastnameenglish && booking.student_lastnameenglish.toLowerCase().includes(searchTerm)) ||
      (booking.nationalid && booking.nationalid.toLowerCase().includes(searchTerm))
    );
    
    if (resultFilter !== 'all') {
      filtered = filtered.filter(booking => booking.pass_fail === resultFilter);
    }
  
    if (dateRange[0] && dateRange[1]) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter(booking =>
        moment(booking.test_date).isBetween(
          moment(startDate).startOf('day'),
          moment(endDate).endOf('day'),
          null,
          '[]'
        )
      );
    }
    
    setFilteredBookings(filtered);
  };

  const handleSaveResult = async (record) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/test/test-results/${record.id}`, {
        pass_fail: resultData[record.id]?.pass_fail,
        score: resultData[record.id]?.score,
        paperscore: resultData[record.id]?.paperscore, // Include paperscore
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success(t('Test result saved successfully'));
      setEditingRow(null); // Stop editing after saving
      fetchBookings(); // Refresh bookings
    } catch (error) {
      console.error('Error saving test result:', error);
      message.error(t('Error saving test result'));
    }
  };
  

  const handleInputChange = (value, field, record) => {
    setResultData({
      ...resultData,
      [record.id]: {
        ...resultData[record.id],
        [field]: value,
      },
    });
  };
  

  const handleResultFilterChange = (value) => {
    setResultFilter(value);
    if (value === 'all') {
      setFilteredBookings(bookings);
    } else {
      const filtered = bookings.filter(booking => booking.pass_fail === value);
      setFilteredBookings(filtered);
    }
  };

  const csvHeaders = [
    { label: t('Test Date'), key: 'test_date' },
    { label: t('Student'), key: 'student' },
    { label: t('Course'), key: 'course_name' },
    { label: t('Subject'), key: 'subject' },
    { label: t('Test Result'), key: 'pass_fail' },
    { label: t('Score'), key: 'score' },
    { label: t('Paperscore'), key: 'paperscore' }, // Include paperscore
    { label: t('Updated By'), key: 'updated_by_firstname' },
  ];
  
  const csvData = bookings.map(booking => ({
    test_date: moment(booking.test_date).format('YYYY-MM-DD'),
    student: `${booking.student_firstnamethai} ${booking.student_lastnamethai}`,
    course_name: booking.course_name,
    subject: booking.subject,
    pass_fail: booking.pass_fail || t('Not Set'),
    score: booking.score || t('Not Set'),
    paperscore: booking.paperscore || t('Not Set'), // Include paperscore
    updated_by_firstname: booking.updated_by_firstname || t('Not Updated'),
  }));
  

  const columns = [
    {
      title: t('Test Date'),
      dataIndex: 'test_date',
      key: 'test_date',
      render: (date) => {
        if (!date) return 'N/A'; // Handle missing dates
    
        // Format the date based on the user's locale
        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',  // You can change to 'short' or 'numeric' if desired
          day: '2-digit',
        }).format(new Date(date));
      }
    },
    {
      title: t('student'),
      key: 'student_name',
      render: (text, record) => (
        <div>
          <div>{`${record.student_firstnamethai} ${record.student_lastnamethai}`}</div>
          <div>{`${record.student_firstnameenglish} ${record.student_lastnameenglish}`}</div>
        </div>
      ),
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('course'),
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject',
    },
  
    {
      title: t('practicescore'),
      key: 'score',
      render: (_, record) =>
        editingRow === record.id ? (
          <Input
            type="number"
            value={resultData[record.id]?.score || record.score}
            onChange={(e) => handleInputChange(e.target.value, 'score', record)}
            placeholder={t('Enter Score')}
          />
        ) : (
          // Ensure that the score is displayed without unnecessary decimals
          record.score ? Math.round(record.score) : t('Not Set')
        ),
    },
    
    {
      title: t('paperscore'),
      key: 'paperscore',
      render: (_, record) =>
        editingRow === record.id ? (
          <Input
            type="number"
            value={resultData[record.id]?.paperscore || record.paperscore}
            onChange={(e) => handleInputChange(e.target.value, 'paperscore', record)}
            placeholder={t('Enter Paperscore')}
          />
        ) : (
          record.paperscore || t('Not Set')
        ),
    },
    {
      title: t('Test Result'),
      key: 'pass_fail',
      render: (_, record) => {
        if (editingRow === record.id) {
          return (
            <Select
              value={resultData[record.id]?.pass_fail || record.pass_fail}
              onChange={(value) => handleInputChange(value, 'pass_fail', record)}
              placeholder={t('Select Pass or Fail')}
            >
              <Option value="pass">{t('Pass')}</Option>
              <Option value="fail">{t('Fail')}</Option>
            </Select>
          );
        } else {
          if (record.pass_fail === 'fail') {
            return <span style={{ color: 'red', fontWeight: 'bold' }}>{t('Fail')}</span>;
          } else if (record.pass_fail === 'pass') {
            return <span style={{ color: 'green', fontWeight: 'bold' }}>{t('Pass')}</span>;
          } else {
            return t('Not Set');
          }
        }
      },
    },
    {
      title: t('updatedby'),
      dataIndex: 'updated_by_nickname', // Display the name of the user who updated the result
      key: 'updated_by_nickname',
      render: (text) => text || t('Not Updated'),
    },
    {
      title: t('edit'),
      key: 'actions',
      render: (_, record) =>
        record.pass_fail || record.score ? (
          // If pass_fail or score is set, show the result as updated and don't show the edit button
          <span>{t('updated')}</span>
        ) : editingRow === record.id ? (
          <>
            <Button onClick={() => handleSaveResult(record)}>{t('Save')}</Button>
            <Button onClick={() => setEditingRow(null)}>{t('Cancel')}</Button>
          </>
        ) : (
          <Button onClick={() => setEditingRow(record.id)}>{t('Edit Result')}</Button>
        ),
    },
  ];

  return (
    <div className="test-result-container">
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
      <DatePicker
      className="ant-datepicker"
        selected={dateRange[0]} // Correctly select the start date from dateRange
        onChange={handleDateRangeChange} // Use the correct handler
        startDate={dateRange[0]} // Start date from dateRange
        endDate={dateRange[1]} // End date from dateRange (no need to define endDate separately)
        selectsRange
        dateFormat="yyyy-MM-dd"
        placeholderText={t('Select Date Range')}
      />
 
        <Input.Search
          placeholder={t('Search by Student Name or National ID')}
          onSearch={handleSearch}
          enterButton
          style={{ maxWidth: '300px' }}
        />
              <Select
          style={{ width: 150, height: 40}}
          value={resultFilter}
          onChange={handleResultFilterChange}
        >
          <Option value="all">{t('All')}</Option>
          <Option value="pass">{t('Pass')}</Option>
          <Option value="fail">{t('Fail')}</Option>
        </Select>

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="test_results.csv"
          className="ant-btn ant-btn-default"
          style={{ marginLeft: '10px' }}
        >
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
        </CSVLink>
      </div>

      <div className="test-result-content">
      <Table
          dataSource={filteredBookings}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredBookings.length,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '150'],
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>

    </div>
  );
};

export default withRoleAccess(TestResult, ['admin', 'superadmin', 'teacher']);

import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Statistic, Table, Spin, notification, Space, Button } from 'antd';
import { BankOutlined, DollarCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const SalesTransactionDashboard = () => {
  const { t, i18n } = useTranslation();
  const [salesTransactions, setSalesTransactions] = useState([]);
  const [groupedTransactions, setGroupedTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const API_URL = process.env.REACT_APP_API_URL;


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };

  const fetchSalesTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/sales/sales-transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transactions = response.data;
      console.log('Sales Transactions:', transactions);
      setSalesTransactions(transactions);
      calculatePaymentSummary(salesTransactions); // Use original transactions
    groupTransactionsByDate(salesTransactions);
    } catch (error) {
      console.error('Error fetching sales transactions:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch sales transactions.',
      });
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchSalesTransactions();
  }, [fetchSalesTransactions]);

  useEffect(() => {
    if (salesTransactions.length > 0) {
      groupTransactionsByDate(salesTransactions);
    }
  }, [startDate, endDate, salesTransactions]);

  const groupTransactionsByDate = (transactions) => {
    if (!transactions || transactions.length === 0) return;
  
    // Group transactions by date
    const grouped = transactions.reduce((acc, transaction) => {
      const date = transaction.transaction_date
        ? moment(transaction.transaction_date).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
  
      if (!acc[date]) {
        acc[date] = {
          date,
          totalAmount: 0,
          finalAmount: 0,
          vat: 0,
          transactions: 0,
        };
      }
  
      acc[date].totalAmount += Number(transaction.total_amount ?? 0);
      acc[date].finalAmount += Number(transaction.final_amount ?? 0);
      acc[date].vat += Number(transaction.vat ?? 0);
      acc[date].transactions += 1;
  
      return acc;
    }, {});
  
    // Convert grouped data to an array and sort by date
    const groupedArray = Object.values(grouped).sort((a, b) =>
      moment(a.date, 'YYYY-MM-DD') - moment(b.date, 'YYYY-MM-DD')
    );
  
    console.log('Grouped Transactions:', groupedArray);
  
    // Filter grouped transactions based on the selected date range
    const filtered = groupedArray.filter((item) => {
      const itemDate = moment(item.date, 'YYYY-MM-DD');
      return itemDate.isSameOrAfter(moment(startDate, 'YYYY-MM-DD')) && itemDate.isSameOrBefore(moment(endDate, 'YYYY-MM-DD'));
    });
  
    console.log('Filtered Transactions:', filtered);
  
    // Update state with the filtered and grouped data
    setGroupedTransactions(groupedArray);
    setFilteredTransactions(filtered);
  
    // Update the payment summary based on the filtered transactions
    calculatePaymentSummary(filtered);
  };
  
  const calculatePaymentSummary = (transactions) => {
    // Filter transactions by date range first
    const filteredTransactions = transactions.filter(transaction => {
      const transactionDate = moment(transaction.transaction_date);
      return transactionDate.isBetween(startDate, endDate, 'day', '[]');
    });
  
    // Then calculate summaries for filtered transactions
    const summary = filteredTransactions.reduce((acc, transaction) => {
      // Add debug log
      console.log('Processing transaction:', {
        date: transaction.transaction_date,
        method: transaction.payment_method,
        amount: transaction.final_amount
      });
  
      const method = transaction.payment_method?.toLowerCase(); // Convert to lowercase
      
      if (!acc[method]) {
        acc[method] = {
          amount: 0,
          count: 0
        };
      }
      
      acc[method].amount += Number(transaction.final_amount || 0);
      acc[method].count += 1;
  
      return acc;
    }, {});
  
    console.log('Final Summary:', summary);
    setPaymentSummary(summary);
  
    return summary; // Return for debugging
  };
  
  // Update useEffect to handle date changes
  useEffect(() => {
    if (salesTransactions.length > 0) {
      calculatePaymentSummary(salesTransactions);
    }
  }, [startDate, endDate, salesTransactions]);
  
  const exportToPDF = async () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    const dashboardElement = document.getElementById('dashboard-content');

    if (dashboardElement) {
      const canvas = await html2canvas(dashboardElement, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 190; // Adjust for PDF width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      doc.save('sales-transaction-dashboard.pdf');
    }
  };

  const summaryColumns = [
    {
      title: t('date'), // Translated column header
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t('Total Quantity Sold'), // Translated column header
      dataIndex: 'transactions',
      key: 'transactions',
      align: 'right',
    },
    {
      title: t('Total_sales'), // Translated column header
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'right',
      render: (amount) => `฿${Number(amount).toLocaleString()}`,
    },
    {
      title: t('VAT'), // Translated column header
      dataIndex: 'vat',
      key: 'vat',
      align: 'right',
      render: (vat) => `฿${Number(vat).toLocaleString()}`,
    },
    {
      title: t('subtotal'), // Translated column header
      dataIndex: 'finalAmount',
      key: 'finalAmount',
      align: 'right',
      render: (amount) => `฿${Number(amount).toLocaleString()}`,
    },
  ];

  return (
    <Spin spinning={loading}>
      <div>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div id="dashboard-content">
            {/* Date Range Filter */}
            <Card>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <label htmlFor="start-date">From:</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select start date"
                  dateFormat="dd-MM-yyyy"
                  className="ant-datepicker"
                  id="start-date"
                />
                <label htmlFor="end-date">To:</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="Select end date"
                  dateFormat="dd-MM-yyyy"
                  className="ant-datepicker"
                  id="end-date"
                />
                <Button onClick={exportToPDF}>
                {t('Export to PDF')}
          </Button>
              </div>
          
            </Card>

            {/* Payment Method Statistics */}
            <Row gutter={[16, 16]} style={{ marginBottom: 24, marginTop: 24 }}>
            <Col xs={24} sm={12} md={8}>
                <Card 
                    bordered={false}
                    style={{ 
                    background: 'linear-gradient(135deg, #1890ff 0%, #096dd9 100%)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                    }}
                >
                    <Statistic
                    title={<span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '16px' }}>{t('fullpay')}</span>}
                    value={paymentSummary['fullpay']?.amount || 0}
                    precision={2}
                    suffix="฿"
                    prefix={<BankOutlined style={{ color: 'rgba(255,255,255,0.85)' }} />}
                    valueStyle={{ color: '#ffffff', fontSize: '24px', fontWeight: 'bold' }}
                    />
                    <div style={{ color: 'rgba(255,255,255,0.7)', marginTop: 8 }}>
                    {paymentSummary['fullpay']?.count || 0} transactions
                    </div>
                </Card>
                </Col>

                <Col xs={24} sm={12} md={8}>
                <Card 
                    bordered={false}
                    style={{ 
                    background: 'linear-gradient(135deg, #52c41a 0%, #389e0d 100%)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                    }}
                >
                    <Statistic
                    title={<span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '16px' }}>{t('scholarship')}</span>}
                    value={paymentSummary['scholarship']?.amount || 0}
                    precision={2}
                    suffix="฿"
                    prefix={<DollarCircleOutlined style={{ color: 'rgba(255,255,255,0.85)' }} />}
                    valueStyle={{ color: '#ffffff', fontSize: '24px', fontWeight: 'bold' }}
                    />
                    <div style={{ color: 'rgba(255,255,255,0.7)', marginTop: 8 }}>
                    {paymentSummary['scholarship']?.count || 0} transactions
                    </div>
                </Card>
                </Col>

                <Col xs={24} sm={12} md={8}>
                <Card 
                    bordered={false}
                    style={{ 
                    background: 'linear-gradient(135deg, #faad14 0%, #d48806 100%)',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                    }}
                >
                    <Statistic
                    title={<span style={{ color: 'rgba(255,255,255,0.85)', fontSize: '16px' }}>{t('paymentplan')}</span>}
                    value={paymentSummary['paymentplan']?.amount || 0}
                    precision={2}
                    suffix="฿"
                    prefix={<ClockCircleOutlined style={{ color: 'rgba(255,255,255,0.85)' }} />}
                    valueStyle={{ color: '#ffffff', fontSize: '24px', fontWeight: 'bold' }}
                    />
                    <div style={{ color: 'rgba(255,255,255,0.7)', marginTop: 8 }}>
                    {paymentSummary['paymentplan']?.count || 0} transactions
                    </div>
                </Card>
                </Col>
                </Row>
           {/* Sales Trend Bar Chart */}
            <Card title="Sales Trends">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={filteredTransactions}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tickFormatter={(date) => moment(date).format('DD/MM')} />
                  <YAxis />
                  <Tooltip formatter={(value) => `฿${value.toLocaleString()}`} />
                  <Legend />
                  <Bar dataKey="finalAmount" fill="#1890ff">
                    <LabelList dataKey="finalAmount" position="top" formatter={(value) => `฿${value}`} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Card>

            {/* Sales Transactions Table */}
            <Card title="ยอดขายรายวัน">
              <Table
                columns={summaryColumns}
                dataSource={filteredTransactions}
                pagination={{ pageSize: 10 }}
                rowKey="date"
              />
            </Card>
          </div>


        </Space>
      </div>
    </Spin>
  );
};

export default SalesTransactionDashboard;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Select, Input, Modal, Button, Form, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { createTask, updateTask, deleteTask } from './apiService';
import getUserIdFromToken from '../../Utils/authUtils';
import axios from 'axios';

const { Option } = Select;

const TaskPopup = ({ isOpen, task, onClose, onSave, date, users }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [recurrence, setRecurrence] = useState('none');
  const [recurrenceCount, setRecurrenceCount] = useState(1);
  const [groupId, setGroupId] = useState(task ? task.groupId : uuidv4());
  const [sharedWith, setSharedWith] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);


  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects`);
        setSubjectOptions(response.data);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    if (task) {
      setTitle(task.title || '');
      setDescription(task.description || '');
      setRecurrence(task.recurrence || 'none');
      setRecurrenceCount(task.recurrenceCount || 1);
      setGroupId(task.groupId || uuidv4());
      setSharedWith(task.sharedWith || []);
    } else {
      setTitle('');
      setDescription('');
      setRecurrence('none');
      setRecurrenceCount(1);
      setGroupId(uuidv4());
      setSharedWith([]);
    }
  }, [task, isOpen]);

  const openNotificationWithIcon = (type, messageText, descriptionText) => {
    notification[type]({
      message: messageText,
      description: descriptionText,
    });
  };

  const handleSaveTask = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      console.error('User ID not found, user might not be logged in');
      return;
    }

    const taskDetails = {
      userId,
      title,
      description,
      start: task ? moment(task.start).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.start).format('YYYY-MM-DDTHH:mm:ss') : null,
      end: task ? moment(task.end).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.end).format('YYYY-MM-DDTHH:mm:ss') : null,
      recurrence: task ? task.recurrence : recurrence,
      recurrenceCount,
      groupId: task ? task.groupId : groupId,
      sharedWith,
    };

    try {
      if (task) {
        await updateTask(task.id, taskDetails);
      } else {
        await createTask(taskDetails);
      }
      openNotificationWithIcon('success', t('Success'), t('Task added/updated successfully'));
      await onSave(); // Refetch everything (fetchCombinedEvents, fetchTeachers)
      onClose();
    } catch (error) {
      openNotificationWithIcon('error', t('Error'), t('Failed to save task'));
      console.error('Error saving task:', error);
    }
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTask(task.id);
      openNotificationWithIcon('success', t('Success'), t('Task deleted successfully'));
      await onSave(); // Refetch everything (fetchCombinedEvents, fetchTeachers)
      onClose();
    } catch (error) {
      openNotificationWithIcon('error', t('Error'), t('Failed to delete task'));
      console.error('Error deleting task:', error);
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirm(false);
  };

  if (!isOpen) return null;

  return (
    <Modal
      title={task ? t('editTask') : t('newTask')}
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button key="save" type="primary" onClick={handleSaveTask}>
          {t('save')}
        </Button>,
        task && (
          <Button key="delete" type="danger" onClick={() => setShowConfirm(true)}>
            {t('delete')}
          </Button>
        ),
      ]}
    >
      <Form layout="vertical">
        <Form.Item label={t('teacher')}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('Select users to share with')}
            value={sharedWith}
            onChange={(value) => setSharedWith(value)}
          >
            {Array.isArray(users) && users.map(user => (
              <Option key={user.userid} value={user.userid}>
                {user.firstname} {user.lastname}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('title')}>
          <Select
            value={title}
            onChange={(value) => setTitle(value)}
            placeholder={t('Select a subject title')}
          >
            {subjectOptions.map((subject) => (
              <Option key={subject.subjectgroup} value={subject.title}>
                {subject.subjectgroup} - {subject.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        <Form.Item label={t('description')}>
          <Input.TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>
        <Form.Item label={t('date')}>
          <Input readOnly value={task ? moment(task.start).format('YYYY-MM-DD') : date ? moment(date.start).format('YYYY-MM-DD') : ''} />
        </Form.Item>
        <Form.Item label={t('startTime')}>
          <Input readOnly value={task ? moment(task.start).format('HH:mm') : date ? moment(date.start).format('HH:mm') : ''} />
        </Form.Item>
        <Form.Item label={t('endTime')}>
          <Input readOnly value={task ? moment(task.end).format('HH:mm') : date ? moment(date.end).format('HH:mm') : ''} />
        </Form.Item>
        <Form.Item label={t('recurrence')}>
          <Select value={recurrence} onChange={(value) => setRecurrence(value)}>
            <Option value="none">{t('none')}</Option>
            <Option value="daily">{t('daily')}</Option>
            <Option value="weekly">{t('weekly')}</Option>
            <Option value="bi-weekly">{t('biWeekly')}</Option>
            <Option value="monthly">{t('monthly')}</Option>
          </Select>
        </Form.Item>
        {recurrence !== 'none' && (
          <Form.Item label={t('numberOfRecurrences')}>
            <Input
              type="number"
              value={recurrenceCount}
              onChange={(e) => setRecurrenceCount(e.target.value)}
              min="1"
            />
          </Form.Item>
        )}
      </Form>

      <Modal
        title={t('confirmDeleteTask')}
        visible={showConfirm}
        onOk={handleDeleteTask}
        onCancel={handleCancelConfirm}
        okText={t('yes')}
        cancelText={t('no')}
      >
        <p>{t('confirmDeleteTask')}</p>
      </Modal>
    </Modal>
  );
};

export default TaskPopup;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt, faChalkboardTeacher, faBook, faVideo, faUserClock, faSave, faLock, faPeopleGroup, faComment, faTrash, faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Classes.css';


const ClassDetails = () => {
  const { t, i18n } = useTranslation();
  const { groupid } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendance, setAttendance] = useState({});
  const [attendanceStatistics, setAttendanceStatistics] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [classComments, setClassComments] = useState({});
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [submittedSchedules, setSubmittedSchedules] = useState([]);
  const [isAttendanceSaved, setIsAttendanceSaved] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [newVideoMeetingLink, setNewVideoMeetingLink] = useState('');
  const [isCommentSaved, setIsCommentSaved] = useState({});
  const [videoMeetingLinks, setVideoMeetingLinks] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year:'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(':');
    return `${hour}:${minute}`;
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };
  
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/class-groups/${groupid}`);
        setClassDetails(response.data);

        const { classgroup_ids, classes } = response.data;

        const fetchMessages = async () => {
          const token = localStorage.getItem('token');
          const messageResponses = await Promise.all(classgroup_ids.map(id => 
            axios.get(`${API_URL}/messages/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const messages = messageResponses.flatMap(res => res.data);
          setMessages(messages);
        };

        const fetchAttendanceStatus = async () => {
          const token = localStorage.getItem('token');
          const attendanceResponses = await Promise.all(classes.map(cls => 
            axios.get(`${API_URL}/attendance/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const submittedClassIds = attendanceResponses
            .filter(res => res.data.length > 0)
            .map(res => res.config.url.split('/').pop());
          setSubmittedSchedules(submittedClassIds);
        };

        const fetchAttendanceStatistics = async () => {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/attendance/group/${groupid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAttendanceStatistics(response.data);
        };

        const fetchAllClassComments = async () => {
          const token = localStorage.getItem('token');
          const commentResponses = await Promise.all(classes.map(cls => 
            axios.get(`${API_URL}/class-comments/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const commentsData = commentResponses.flatMap(res => res.data);
          const formattedComments = commentsData.reduce((acc, comment) => {
            acc[comment.classid] = comment.comment;
            return acc;
          }, {});
          setClassComments(formattedComments);
          setIsCommentSaved(Object.keys(formattedComments).reduce((acc, classid) => {
            acc[classid] = true;
            return acc;
          }, {}));
        };

        const fetchAllVideoMeetingLinks = async () => {
          const token = localStorage.getItem('token');
          const videoLinkResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/video-meeting-links/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const videoLinksData = videoLinkResponses.flatMap(res => res.data);
          const formattedVideoLinks = videoLinksData.reduce((acc, link) => {
            acc[link.classid] = link.video_meeting_link;
            return acc;
          }, {});
          setVideoMeetingLinks(formattedVideoLinks);
        };

        fetchMessages();
        fetchAttendanceStatus();
        fetchAttendanceStatistics();
        fetchAllClassComments();
        fetchAllVideoMeetingLinks();
      } catch (error) {
        setError('Failed to fetch class details.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRegisteredStudents = async () => {
      try {
        const response = await axios.get(`${API_URL}/registrations/classgroup/${groupid}`);
        setRegisteredStudents(response.data);
      } catch (error) {
        console.error('Error fetching registered students:', error);
      }
    };

    fetchClassDetails();
    fetchRegisteredStudents();
  }, [groupid, API_URL]);

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/messages`,
        { groupid, message: newMessage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewMessage('');
      setMessages(prevMessages => [
        ...prevMessages,
        { firstname: 'You', lastname: '', message: newMessage, timestamp: new Date().toISOString() }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleClassCommentChange = (classId, value) => {
    setClassComments((prev) => ({
      ...prev,
      [classId]: value,
    }));
    setNewComment(value); // Update the newComment state as well
  };

  const handleAddClassComment = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/class-comments`,
        { classid, comment: newComment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassComments((prev) => ({
        ...prev,
        [classid]: newComment,
      }));
      setNewComment('');
      setIsCommentSaved((prev) => ({
        ...prev,
        [classid]: true
      }));
    } catch (error) {
      console.error('Error adding class comment:', error);
    }
  };

  const handleAddVideoMeetingLink = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/video-meeting-links`,
        { classid, video_meeting_link: newVideoMeetingLink },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVideoMeetingLinks((prev) => ({
        ...prev,
        [classid]: newVideoMeetingLink,
      }));
      setNewVideoMeetingLink('');
    } catch (error) {
      console.error('Error adding video meeting link:', error);
    }
  };
  const handleDeleteVideoMeetingLink = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/video-meeting-links/${classid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVideoMeetingLinks((prev) => ({
        ...prev,
        [classid]: null,
      }));
    } catch (error) {
      console.error('Error deleting video meeting link:', error);
    }
  };
  
  
  const handleAttendanceChange = (studentId, classId, value) => {
    setAttendance(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [classId]: value,
      },
    }));
  };

  const handleCommentChange = (studentId, classId, value) => {
    setAttendance(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [`${classId}_comment`]: value,
      },
    }));
  };

  const handleSaveAttendance = async () => {
    if (!selectedClass) return;
  
    const { classid, groupid } = selectedClass;
  
    try {
      const token = localStorage.getItem('token');
      const attendanceData = Object.keys(attendance).map((studentId) => ({
        student_id: studentId,
        status: attendance[studentId][classid],
        comment: attendance[studentId][`${classid}_comment`] || ''
      }));
  
      const payload = {
        classid,
        groupid,
        attendance: attendanceData,
      };
  
      await axios.post(
        `${API_URL}/attendance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setSubmittedSchedules((prev) => [...prev, classid]);
      setSelectedClass(null);
    } catch (error) {
      console.error('Error saving attendance:', error);
    }
  };

  const handleShowModal = async (cls) => {
    setSelectedClass(cls);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/attendance/${cls.classid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
  
      const savedAttendance = response.data.reduce((acc, record) => {
        if (!acc[record.student_id]) {
          acc[record.student_id] = {};
        }
        acc[record.student_id] = {
          ...acc[record.student_id],
          [cls.classid]: record.status,
          [`${cls.classid}_comment`]: record.comment || ''
        };
        return acc;
      }, {});
  
  
      setAttendance(savedAttendance);
      setIsAttendanceSaved(Object.keys(savedAttendance).length > 0);
    } catch (error) {
      console.error('Error fetching attendance:', error);
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!classDetails) return <p>No class details found.</p>;

  const attendanceData = [
    { name: 'Present', value: attendanceStatistics.present, color: '#36A2EB' },
    { name: 'Late', value: attendanceStatistics.late, color: '#FFCE56' },
    { name: 'Absent', value: attendanceStatistics.absent, color: '#FF6384' },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="class-details-container">
        <div className="class-details-header">
          <h1>{classDetails.class_code}</h1>
          <h2><FontAwesomeIcon icon={faBook} className="icon"/>{classDetails.subjects?.join(', ')}</h2>
          <h2><FontAwesomeIcon icon={faChalkboardTeacher} className="icon"/>{classDetails.teachers?.join(', ')}</h2>
        </div>

        <div className="info-attendance-row">
          <div className="class-info-container">
          <center><h3>{t('classDetails')}</h3></center>
            <p><strong><FontAwesomeIcon icon={faCalendar} className="icon"/>{t('startDate')}:</strong> {formatDate(classDetails.start_date)}</p>
            <p><strong><FontAwesomeIcon icon={faClock} className="icon"/>{t('totalHours')}:</strong> {formatMinutesToHours(classDetails.total_hours)}</p>
            <p><strong><FontAwesomeIcon icon={faBook} className="icon"/>{t('bookDetails')}:</strong> {classDetails.book_details_materials}</p>
            <p><strong><FontAwesomeIcon icon={faUser} className="icon"/>{t('type')}:</strong> {classDetails.isprivate ? t('private') : t('group')}</p>
          </div>

          <div className="class-students-container">
          <center><h3>{t('registeredStudents')}</h3></center>
            <table>
              <tbody>
                {registeredStudents.map((student) => (
                  <tr key={student.student_id}>
                    <center><td>{student.student_name} ({student.nickname})</td></center>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="info-attendance-row">
          <div className="class-messages-container">
            <center><h3>{t('messages')}</h3></center>
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={t('writeMessage')}
            />
            <button onClick={handleSendMessage}>{t('sendMessage')}</button>
            <div className="message-list">
              {messages.map((msg, index) => (
                <div key={index} className="message-item">
                  <p><strong>({msg.firstname} {msg.lastname}):</strong> {msg.message}</p>
                  <small className="message-time">{formatDate(msg.timestamp)} {t('at')} {formatDateTime(msg.timestamp)}</small>
                </div>
              ))}
            </div>
          </div>
          <div className="attendance-container">
            <h3>{t('classPerformance')}</h3>
            <div className="attendance-stats">
              <p>{t('present')}: {attendanceStatistics.presentPercentage}%</p>
              <p>{t('absent')}: {attendanceStatistics.absentPercentage}%</p>
              <p>{t('late')}: {attendanceStatistics.latePercentage}%</p>
            </div>
            <div className="attendance-chart" style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={attendanceData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        innerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                      >
                        {attendanceData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
          </div>
        </div>

        <div className="class-schedule-container">
          {classDetails.classes?.map((slot, index) => (
            <div key={index} className="class-schedule-card">
              <h3>{t('class')} {index + 1} ({slot.classid})</h3>
              <h3>{slot.subject_name}</h3>
              <h4><FontAwesomeIcon icon={faChalkboardTeacher} /> {slot.teacher_names}</h4>
              <div className="video-meeting-link">
                {videoMeetingLinks[slot.classid] ? (
                  <div>
                    <a href={videoMeetingLinks[slot.classid]} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faVideo} /> {t('joinVideoMeeting')}
                    </a>
                    <button className= "delete-video-button" onClick={() => handleDeleteVideoMeetingLink(slot.classid)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                ) : (
                  <div>
                    <input
                      type="text"
                      placeholder={t('addVideoMeetingLink')}
                      value={newVideoMeetingLink}
                      onChange={(e) => setNewVideoMeetingLink(e.target.value)}
                    />
                    <button className = "add-video-button"onClick={() => handleAddVideoMeetingLink(slot.classid)}>
                    <FontAwesomeIcon icon={faVideo} />
                    </button>
                  </div>
                )}
              </div>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> {formatDate(slot.date)}</p>
              <p><FontAwesomeIcon icon={faClock} /> {formatTime(slot.schedule_time)}</p>
              <p><FontAwesomeIcon icon={faUserClock} /> {formatMinutesToHours(slot.schedule_hour)}</p>
              <p><FontAwesomeIcon icon={faBook} /> {slot.classroom_number}</p>
              <div className="class-comment">
                <textarea
                  placeholder={t('addClassComment')}
                  value={classComments[slot.classid] || ''}
                  onChange={(e) => handleClassCommentChange(slot.classid, e.target.value)}
                  readOnly={isCommentSaved[slot.classid]}
                  disabled={isCommentSaved[slot.classid]}
                  style={{ resize: 'none' }}  // Add this line to prevent resizing
                />
              </div>
              {!isCommentSaved[slot.classid] && (
                <button onClick={() => handleAddClassComment(slot.classid)}>
                  <FontAwesomeIcon icon={faComment} /> {t('addComment')}
                </button>
              )}
              <button
                onClick={() => handleShowModal(slot)}
                disabled={submittedSchedules.includes(slot.classid)}
              >
                <FontAwesomeIcon icon={submittedSchedules.includes(slot.classid) ? faLock : faPeopleGroup} />
              </button>
            </div>
          ))}
        </div>
      </div>

      {selectedClass && (
  <div className="modal-backdrop">
    <div className="modal-content">
      <span className="modal-close-icon" onClick={() => setSelectedClass(null)}>&times;</span>
      <h2>{t('attendanceFor')} {selectedClass.subject_name}</h2>
      <table>
        <thead>
          <tr>
            <th>{t('studentName')}</th>
            <th>{t('status')}</th>
            <th>{t('comments')}</th>
          </tr>
        </thead>
        <tbody>
          {registeredStudents.map((student) => {
            const studentAttendance = attendance[student.student_id] || {};
            const status = studentAttendance[selectedClass.classid] || '';
            const comment = studentAttendance[`${selectedClass.classid}_comment`] || '';
            return (
              <tr key={student.student_id}>
                <td>{student.student_name} ({student.nickname})</td>
                <td>
                  <select
                    value={status}
                    onChange={(e) => handleAttendanceChange(student.student_id, selectedClass.classid, e.target.value)}
                    disabled={isAttendanceSaved}
                  >
                    <option value="">{t('selectStatus')}</option>
                    <option value="Present">{t('present')}</option>
                    <option value="Absent">{t('absent')}</option>
                    <option value="Late">{t('late')}</option>
                  </select>
                </td>
                <td>
                  <textarea className="textarea1"
                    value={comment}
                    onChange={(e) => handleCommentChange(student.student_id, selectedClass.classid, e.target.value)}
                    disabled={isAttendanceSaved}
                    style={{ resize: 'none' }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!isAttendanceSaved && (
        <button onClick={handleSaveAttendance}>
          <FontAwesomeIcon icon={faSave} /> {t('saveAttendance')}
        </button>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default withRoleAccess(ClassDetails, ['admin', 'superadmin', 'teacher']);

import React, { useState, useEffect } from 'react';
import { Table, Button, Spin, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import 'moment/locale/th'; 
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv'; 
import DatePicker from 'react-datepicker';
import { registerLocale } from  "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en-US';

registerLocale('th', th);
registerLocale('en', en);

const PaymentSummaryPage = () => {
  const { t, i18n } = useTranslation();
  const [fullyPaidStudents, setFullyPaidStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [confirmedPayments, setConfirmedPayments] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: '',
    searchTerm: '',
    documentNo: ''
  });
  const API_URL = process.env.REACT_APP_API_URL;

  

  const formatDateWithLocale = (dateString) => {
    const date = moment(dateString).toDate();
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };

  const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };

  useEffect(() => {
    fetchFullyPaidStudents();
    fetchConfirmedPayments();
  }, []);

  const fetchFullyPaidStudents = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/payment/summary`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFullyPaidStudents(response.data);
      setFilteredStudents(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching fully paid students:', error);
      setLoading(false);
    }
  };


  const fetchConfirmedPayments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/payment/confirmed`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConfirmedPayments(response.data.map(payment => payment.document_no));
    } catch (error) {
      console.error('Error fetching confirmed payments:', error);
    }
  };

  const handleRefresh = () => {
    fetchFullyPaidStudents();
    fetchConfirmedPayments();
  };


const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
};

const handleDateChange = (date, fieldName) => {
  setFilters(prevFilters => ({
    ...prevFilters,
    [fieldName]: date ? moment(date).toDate() : null
  }));
};

const applyFilters = () => {
  let filtered = fullyPaidStudents;

  // Filter by fromDate
  if (filters.fromDate) {
    const startDate = moment(filters.fromDate).startOf('day');
    filtered = filtered.filter(transaction => 
      moment(transaction.created_at).isSameOrAfter(startDate)
    );
  }

  // Filter by toDate
  if (filters.toDate) {
    const endDate = moment(filters.toDate).endOf('day');
    filtered = filtered.filter(transaction => 
      moment(transaction.created_at).isSameOrBefore(endDate)
    );
  }

  if (filters.searchTerm) {
    const searchTerm = filters.searchTerm.toLowerCase();
    filtered = filtered.filter(student =>
      student.student_name.toLowerCase().includes(searchTerm) ||
      (student.nationalid && student.nationalid.toString().toLowerCase().includes(searchTerm))
    );
  }
  if (filters.documentNo) {
    const documentNo = filters.documentNo.toLowerCase();
    filtered = filtered.filter(student =>
      student.document_no.toLowerCase().includes(documentNo)
    );
  }

  setFilteredStudents(filtered);
};

useEffect(() => {
  applyFilters();
}, [filters, fullyPaidStudents]);


const handleConfirmFullyPaid = async (record) => {
  if (confirmedPayments.includes(record.document_no)) {
    notification.warning({
      message: t('duplicate_confirmation'),
      description: t('already_confirmed'),
    });
    return; // Exit function if payment is already confirmed
  }

  try {
    const token = localStorage.getItem('token');
    await axios.post(`${API_URL}/payment/confirm-fully-paid`, {
      studentid: record.studentid, // Use the correct `student_id`
      total_paid_amount: record.total_paid,
      document_no: record.document_no
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    
    notification.success({
      message: t('success'),
      description: t('fully_paid_confirmed'),
    });

    // Fetch updated lists of fully paid students and confirmed payments
    fetchFullyPaidStudents();
    fetchConfirmedPayments();
  } catch (error) {
    console.error('Error confirming fully paid:', error);
    notification.error({
      message: t('error'),
      description: t('confirm_fully_paid_error'),
    });
  }
};


  
  const csvData = filteredStudents.map(student => ({
    document_no: student.document_no,
    student_id: student.schoolid,
    student_name: student.student_name,
    items: student.items,
    total_paid: `${Number(student.total_paid).toFixed(2)}`,
    last_payment_date: formatDateWithLocale(student.created_at),
    status: confirmedPayments.includes(student.document_no) ? t('confirmed') : t('unconfirmed')
  }));

  const sortedData = [...filteredStudents].sort((a, b) => {
    const isAConfirmed = confirmedPayments.includes(a.document_no);
    const isBConfirmed = confirmedPayments.includes(b.document_no);
    if (isAConfirmed && !isBConfirmed) return 1;
    if (!isAConfirmed && isBConfirmed) return -1;
    return moment(b.created_at).diff(moment(a.created_at));
  });

  const columns = [
    {
      title: t('transaction_id'),
      dataIndex: 'transaction_id',
      key: 'transaction_id',
    },
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('student_id'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student_name'),
      dataIndex: 'student_name',
      key: 'student_name',
      ellipsis: true,
    },
    {
      title: t('items'),
      dataIndex: 'items',
      key: 'items',
      render: (items) => {
        if (Array.isArray(items)) {
          return (
            <div className="wrap-text">
              {items.map(item => item.item_name).join(', ')}
            </div>
          );
        } else if (typeof items === 'string') {
          return <div className="wrap-text">{items}</div>;
        } else {
          return <div className="wrap-text">{t('no_items')}</div>;
        }
      },
    },
    {
      title: t('paid_amount'),
      dataIndex: 'total_paid',
      key: 'total_paid',
      render: (amount) => (
        <span style={{ color: 'green', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('payment_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (date) => formatDateWithLocale(date), 
    },
    {
      title: t('status'),
      key: 'status',
      render: (_, record) =>
        confirmedPayments.includes(record.document_no) ? (
          <span style={{ color: 'green' }}>{t('confirmed')}</span>
        ) : (
          <Button type="primary" onClick={() => handleConfirmFullyPaid(record)}>
            {t('confirm_fully_paid')}
          </Button>
        ),
    },
  ];

  return (
    <div>
      <h1>{t('studentFullypaid')}</h1>
      <div style={{ marginBottom: 20 }}>
      <DatePicker
          selected={filters.fromDate}
          onChange={(date) => handleDateChange(date, 'fromDate')}
          dateFormat="dd-MM-yyyy"
          locale={i18n.language === 'th' ? 'th' : 'en'}
          placeholderText={t('startDate')}
          style={{ marginRight: '10px' }}
        />
        <DatePicker
          selected={filters.toDate}
          onChange={(date) => handleDateChange(date, 'toDate')}
          dateFormat="dd-MM-yyyy"
          locale={i18n.language === 'th' ? 'th' : 'en'}
          placeholderText={t('endDate')}
          style={{ marginRight: '10px' }}
        />
        <input
          type="text"
          name="searchTerm"
          className='ant-input'
          value={filters.searchTerm}
          onChange={handleFilterChange}
          placeholder={t('searchByNameOrNationalID')}
          style={{ width: 250, marginRight: 10 }}
        />
        <input
          type="text"
          name="documentNo"
          className='ant-input'
          value={filters.documentNo}
          onChange={handleFilterChange}
          placeholder={t('searchByDocumentNo')}
          style={{ width: 250, marginRight: 10 }}
        />
        <Button type="default" onClick={handleRefresh} style={{ marginLeft: 20 }}>
          {t('โหลดข้อมูล')}
        </Button>

        <CSVLink data={csvData} filename="fully_paid_students_report.csv">
          <button className="ant-btn" style={{ marginLeft: 10 }}>
            <FontAwesomeIcon icon={faFileExcel}/>
            {t('exportToExcel')}
          </button>
        </CSVLink>
      </div>
      {loading ? (
        <Spin tip={t('loading')} />
      ) : (
        <Table columns={columns} 
        dataSource={sortedData} 
        rowKey="document_no" 
        scroll={{ x: true }}
        pagination={{
          pageSize: 100,
          showSizeChanger: false,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}/>
      )}
    </div>
  );
};

export default withRoleAccess(PaymentSummaryPage, ['superadmin']);

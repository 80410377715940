import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthContext'; // Adjust the path based on your file structure
import RequireAuth from './components/Auth/RequireAuth'; // Adjust the path
import 'react-tooltip/dist/react-tooltip.css';
import SignIn from './components/Auth/SignIn';
import Header from './components/Header/Header'; 
import Dashboard from './Pages/Dashboard';
import CreateStudentForm from './Pages/CreateUserform/CreateStudentForm.js';
import CreateTeacherForm from './Pages/CreateUserform/CreateTeacherForm.js';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import ClassDetails from './Pages/Class/ClassDetails';
import AllClasses from './Pages/Class/AllClasses.js';
import UserControlPage from './Pages/UserControlPage/UserControlPage';
import ChangePasswordPage from './Pages/UserControlPage/ChangePasswordPage';
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import TeacherDashboard from './Pages/Teacher/TeacherDashboard';
import TeacherDashboardAdmin from './Pages/Teacher/TeacherDashboardAdmin.js';
import StudentDashboardAdmin from './Pages/Student/StudentDashboardAdmin.js';
import AdminDashboard from './Pages/AdminDashboard';
import AdDashboard from './Pages/AdDashboard';
import ChatPage from './Pages/Chat/ChatPage.js';
import Footer from './components/Footer/Footer.js';
import CurrentInventory from './Pages/Inventory/CurrentInventory.js';
import Sales from './Pages/Sales/Sales.js';
import SalesReport from './Pages/Sales/SalesReport.js';
import PaymentPage from './Pages/Payment/PaymentPage.js';
import ExpenseReport from './Pages/Expense/ExpenseReport.js';
import MainTest from './Pages/Test/MainTest.js';
import LogAttendance from './Pages/Teacher/LogAttendance.js';
import GraduateGroup from './Pages/Graduate/GraduateGroup.js';
import CashRegisterPage from './Pages/Reports/CashRegisterPage.js';


function App() {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate(); 
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  // This function updates the userData state
  const handleSetUserData = (data) => {
    setUserData(data);
  };

  const handleSignOut = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/signin');
 };

  return (
    <AuthProvider>
      {isAuthenticated && <Header onSignOut={handleSignOut} />}
      {isAuthenticated && <Sidebar/>}
      {isAuthenticated && <Footer />}
      <Routes>
        <Route path="/signin" element={<SignIn setUserData={handleSetUserData} />} />
        <Route path="/dashboard" element={<RequireAuth><Dashboard userData={userData} /></RequireAuth>} />
        <Route path="/createstudentform" element={<RequireAuth><CreateStudentForm userData={userData} /></RequireAuth>} />
        <Route path="/createteacherform" element={<RequireAuth><CreateTeacherForm userData={userData} /></RequireAuth>} />
        <Route path="/classdetails/:groupid" element={<RequireAuth><ClassDetails userData={userData} /></RequireAuth>} />
        <Route path="/allclasses" element={<RequireAuth><AllClasses userData={userData} /></RequireAuth>} />
        <Route path="/usercontrolpage" element={<RequireAuth><UserControlPage userData={userData} /></RequireAuth>} />
        <Route path="/adminpanel" element={<RequireAuth><AdminPanel userData={userData}/></RequireAuth>} />
        <Route path="/teacher-dashboard/:id" element={<RequireAuth><TeacherDashboard userData={userData}/></RequireAuth>} />
        <Route path="/teacher-dashboard-admin/:id" element={<RequireAuth><TeacherDashboardAdmin userData={userData}/></RequireAuth>} />
        <Route path="/student-dashboard-admin/:id" element={<RequireAuth><StudentDashboardAdmin userData={userData}/></RequireAuth>} />
        <Route path="/admindashboard" element={<RequireAuth><AdminDashboard userData={userData} /></RequireAuth>} />
        <Route path="/addashboard" element={<RequireAuth><AdDashboard userData={userData} /></RequireAuth>} />
        <Route path="/chat" element={<RequireAuth><ChatPage userData={userData}/></RequireAuth>} />
        <Route path="/inventory" element={<RequireAuth><CurrentInventory userData={userData}/></RequireAuth>} />
        <Route path="/sales" element={<RequireAuth><Sales userData={userData}/></RequireAuth>} />
        <Route path="/salesreport" element={<RequireAuth><SalesReport userData={userData}/></RequireAuth>} />
        <Route path="/payment" element={<RequireAuth><PaymentPage userData={userData}/></RequireAuth>} />
        <Route path="/expense" element={<RequireAuth><ExpenseReport userData={userData}/></RequireAuth>} />
        <Route path="/cashregister" element={<RequireAuth><CashRegisterPage userData={userData}/></RequireAuth>} />
        <Route path="/test" element={<RequireAuth><MainTest userData={userData}/></RequireAuth>} />
        <Route path="/graduategroup" element={<RequireAuth><GraduateGroup userData={userData}/></RequireAuth>} />
        <Route path="/log-attendance/:eventid" element={<RequireAuth><LogAttendance/></RequireAuth>} />
        <Route path="/change-password" element={<RequireAuth><ChangePasswordPage /></RequireAuth>} />
        <Route path="/" element={<SignIn setUserData={handleSetUserData} />} />
      </Routes>
      
    </AuthProvider>
  );
}

export default App;

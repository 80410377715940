import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Card, Row, Col, Typography, Table, Statistic, Select, Spin, Button } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LabelList } from 'recharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const { Title } = Typography;
const { Option } = Select;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

// Set moment to use Thai locale globally
moment.locale('th');

const ExpenseAnalytics = () => {
  const { t, i18n } = useTranslation();
  const [reportData, setReportData] = useState(null);
  const [barGraphData, setBarGraphData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(moment().format('YYYY-MM'));
  const [loading, setLoading] = useState(false);
  const contentRef = useRef(); // Reference for the content to capture
  const API_URL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    fetchBarGraphData();
    fetchReportData(selectedMonthYear);
  }, [selectedMonthYear]);

  const fetchReportData = async (monthYear) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/expense/reportsummary`, {
        params: { month_year: monthYear },
      });
      setReportData(response.data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
    setLoading(false);
  };

  const fetchBarGraphData = async () => {
    try {
      const response = await axios.get(`${API_URL}/expense/bargraph`);
      setBarGraphData(response.data);
    } catch (error) {
      console.error('Error fetching bar graph data:', error);
    }
  };

  const generateMonthYearOptions = () => {
    const options = [];
    const currentMonth = moment();
    for (let i = 0; i < 12; i++) {
      const monthYear = currentMonth.clone().subtract(i, 'months').format('YYYY-MM');
      options.push(<Option key={monthYear} value={monthYear}>{monthYear}</Option>);
    }
    return options;
  };

  const handleMonthYearChange = (value) => {
    setSelectedMonthYear(value);
  };

  const downloadPDF = async () => {
    const content = contentRef.current;
    if (content) {
      const canvas = await html2canvas(content, {
        useCORS: true, 
        scale: 2,    
      });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('expense_analytics_report.pdf');
    }
  };

  if (loading) return <Spin tip={t("loading")} />;

  if (!reportData || !barGraphData) return <div>{t("no_data_available")}</div>;

  const { categoryBreakdown, topExpenses, detailedListingByMonth } = reportData;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };
// Function to format month and year in Thai with +543 year adjustment
const formatThaiMonthYear = (dateString) => {
  const date = new Date(dateString);

  // Adjust year for the Thai Buddhist calendar
  const thaiYear = date.getFullYear() + 543;

  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = date.toLocaleDateString(i18n.language, options).toUpperCase();

  // Replace the Gregorian year with the Thai year
  return formattedDate.replace(date.getFullYear(), thaiYear);
};


  const barGraphChartData = barGraphData.map(item => ({
    month: item.month_year,
    total: parseFloat(item.total),
  }));

  const categoryBreakdownChart = categoryBreakdown?.map(item => ({
    name: item.category_name,
    value: parseFloat(item.total),
  })) || [];

  const translateCategory = (category) => {
    if (!category) return t('unknown_category');  // Handle undefined or null categories
  
    const sanitizedCategory = category.trim().toLowerCase();  // Trim spaces and ensure lowercase
  
    switch (sanitizedCategory) {
      case 'postoffice': return t('postoffice');
      case 'transport': return t('transport');
      case 'gas': return t('gas');
      case 'laundry': return t('laundry');
      case 'utilities': return t('utilities');
      case 'return': return t('return');
      case 'facebook': return t('facebook');
      case 'tiktok': return t('tiktok');
      case 'others': return t('others');
      case 'salary': return t('salary');
      case 'commission': return t('commission');
      case 'maintenance': return t('maintenance');
      case 'renovation': return t('renovation');
      case 'blanket return': return t('blanket_return');
      case 'key return': return t('key_return');
      case 'refund': return t('refund');
      case 'travel expense': return t('travel_expense');
      case 'staff meals and drinks': return t('staff_meals_and_drinks');
      case 'stationery supplies': return t('stationery_supplies');
      case 'stationery equipment': return t('stationery_equipment');
      case 'teaching materials and equipment': return t('teaching_materials_and_equipment');
      case 'it equipment': return t('IT_equipment');
      case 'learning materials and equipment': return t('learning_materials_and_equipment');
      default: return category;
    }
  };  
  

  const topExpensesColumns = [
    { title: t('date'), dataIndex: 'date', key: 'date', render: (text) => moment(text).add(543, 'years').format('LL') },
    {
      title: t('category_label'),
      dataIndex: 'category_name',
      key: 'category_name',
      render: (category) => { 
        return translateCategory(category);  
      },
    },
    { title: t('details'), dataIndex: 'expense_type', key: 'expense_type' },
    { title: t('amount'), dataIndex: 'amount', key: 'amount', render: (text) => formatCurrency(text) },
  ];
  
  

  const detailedExpenseColumns = [
    { title: t('date'), dataIndex: 'date', key: 'date', render: (text) => moment(text).add(543, 'years').format('LL') },
    {
      title: t('category_label'),
      dataIndex: 'category_name',
      key: 'category_name',
      render: (category) => {
        switch (category.toLowerCase()) {
          case 'postoffice': 
            return t('postoffice');
          case 'transport': 
            return t('transport');
          case 'gas': 
            return t('gas');
          case 'laundry': 
            return t('laundry');
          case 'utilities': 
            return t('utilities');
          case 'return': 
            return t('return');
          case 'facebook': 
            return t('facebook');
          case 'tiktok': 
            return t('tiktok');
          case 'others': 
            return t('others');
          case 'salary': 
            return t('salary');
          case 'commission': 
            return t('commission');
          case 'maintenance': 
            return t('maintenance');
          case 'renovation': 
            return t('renovation');
          case 'blanket return': 
            return t('blanket_return');
          case 'key return': 
            return t('key_return');
          case 'refund': 
            return t('refund');
          case 'travel expense': 
            return t('travel_expense');
          case 'staff meals and drinks': 
            return t('staff_meals_and_drinks');
          case 'stationery supplies': 
            return t('stationery_supplies');
          case 'stationery equipment': 
            return t('stationery_equipment');
          case 'teaching materials and equipment': 
            return t('teaching_materials_and_equipment');
          case 'it equipment': 
            return t('IT_equipment');
          case 'learning materials and equipment': 
            return t('learning_materials_and_equipment');
          default: 
            return category;
        }        
      }
    },
    { title: t('details'), dataIndex: 'expense_type', key: 'expense_type' },
    { title: t('amount'), dataIndex: 'amount', key: 'amount', render: (text) => formatCurrency(text) },
    { title: t('memo'), dataIndex: 'description', key: 'description' },
  ];

  return (
    <div style={{ padding: '24px' }} ref={contentRef}>
      <Title level={2}>{t('expense_analytics')}</Title>

      <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Select
            value={selectedMonthYear}
            onChange={handleMonthYearChange}
            style={{ width: '100%' }}
          >
            {generateMonthYearOptions()}
          </Select>
        </Col>
        <Col span={8}>
          <Button onClick={downloadPDF} className="ant-btn ant-btn-default" style={{ marginLeft: 10 }}>
            Download as PDF
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '20px', marginBottom: '20px' }}>
  {categoryBreakdown?.map((category, index) => (
    <Col span={8} key={category.category_name}>
      <Card>
        <Statistic
          title={translateCategory(category.category_name)} // Use the translateCategory function
          value={parseFloat(category.total)}
          precision={2}
          valueStyle={{ color: COLORS[index % COLORS.length] }}
          prefix="฿"
        />
      </Card>
    </Col>
  ))}
</Row>


      <Row gutter={[16, 16]}>
      <Col span={24}>
          <Card title={t("total_expenses_by_month")}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={barGraphChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" tickFormatter={formatThaiMonthYear} /> {/* Format month/year */}
                <YAxis />
                <Tooltip formatter={(value) => formatCurrency(value)} />
                <Legend />
                <Bar dataKey="total" fill="#8884d8">
                  <LabelList dataKey="total" position="top" formatter={(value) => formatCurrency(value)} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col span={12}>
          <Card title={t("category_breakdown")}>
            <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={categoryBreakdownChart}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${translateCategory(name)} ${(percent * 100).toFixed(0)}%`}  // Use the translateCategory function here
              >
                {categoryBreakdownChart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => formatCurrency(value)} />
            </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col span={12}>
          <Card title={t("topexpenses")}>
          <Table dataSource={topExpenses} columns={topExpensesColumns} pagination={{ pageSize: 20 }} size="small" />

          </Card>
        </Col>

        <Col span={24}>
          <Card title={t("detailed_expenses")}>
            <Table dataSource={detailedListingByMonth} columns={detailedExpenseColumns} pagination={{ pageSize: 50 }} size="small" />
          </Card>
        </Col>
      </Row>


    </div>
  );
};

export default withRoleAccess(ExpenseAnalytics, ['superadmin', 'admin']);

import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Card, Row, Col, Table, notification, Space, Button } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import moment from 'moment';

const InventoryReportDashboard = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [pageSize, setPageSize] = useState(50);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchInventorySummary = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
  
      // Adjust dates for backend filtering
      const adjustedStartDate = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const adjustedEndDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  
      const response = await axios.get(`${API_URL}/report/date-filtered-inventory`, {
        params: { startDate: adjustedStartDate, endDate: adjustedEndDate },
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setInventoryData(response.data);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching inventory summary:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch inventory summary data.',
      });
    } finally {
      setLoading(false);
    }
  }, [API_URL, startDate, endDate]);  

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
  };
  
  

  useEffect(() => {
    fetchInventorySummary();
  }, [fetchInventorySummary]);

  const columns = [
    {
      title: t('Item Name'), // Translated column header
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: t('Category'), // Translated column header
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('Total Quantity Sold'), // Translated column header
      dataIndex: 'total_quantity',
      key: 'total_quantity',
      align: 'right',
      render: (val) => val.toLocaleString(),
    },
    {
      title: t('Total_sales'), // Translated column header
      dataIndex: 'total_price',
      key: 'total_price',
      align: 'right',
      render: (val) => `฿${Number(val).toLocaleString()}`,
    },
  ];

  const exportToPDF = async () => {
    try {
      const element = document.querySelector('.inventory-dashboard');
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        backgroundColor: '#fff',
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Inventory_Report_${startDate}_to_${endDate}.pdf`);

      notification.success({ message: 'Export Success', description: 'PDF has been downloaded successfully!' });
    } catch (error) {
      console.error('Error exporting PDF:', error);
      notification.error({ message: 'Export Failed', description: 'Failed to export PDF.' });
    }
  };

  const csvHeaders = [
    { label: 'Item Name', key: 'item_name' },
    { label: 'Category', key: 'category' },
    { label: 'Quantity Sold', key: 'total_quantity' },
    { label: 'Total Revenue', key: 'total_price' },
    { label: 'VAT', key: 'total_vat' },
  ];

  return (
    <div className="inventory-dashboard">
      <Card style={{ marginBottom: '24px' }}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Space>
            <span>From:</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Select start date"
              dateFormat="dd-MM-yyyy"
              className="ant-datepicker"
              style={{
                padding: "8px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                marginRight: "8px",
              }}
            />
            <span>To:</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="Select end date"
              dateFormat="dd-MM-yyyy"
              className="ant-datepicker"
              style={{
                padding: "8px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                marginLeft: "8px",
              }}
            />
            <Button icon={<DownloadOutlined />} onClick={exportToPDF}>
              Export to PDF
            </Button>
            <CSVLink data={inventoryData} headers={csvHeaders} filename={`Inventory_Report_${startDate}_to_${endDate}.csv`}>
              <Button icon={<DownloadOutlined />}>Export to CSV</Button>
            </CSVLink>
          </Space>
        </Space>
      </Card>

      <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
        <Col span={24}>
          <Card title="สินค้าขายดี">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={inventoryData.sort((a, b) => b.total_quantity - a.total_quantity).slice(0, 5)}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="item_name" />
                <YAxis />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
                <Bar
                  dataKey="total_quantity"
                  name="Quantity Sold"
                  fill="#1890ff"
                  label={{
                    position: 'top',
                    formatter: (value) => value.toLocaleString(),
                    fill: '#000', // Optional: Adjust the text color
                    fontSize: 12, // Optional: Adjust the font size
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>


      <Card title="สรุปสินค้าขาย (ตามวันที่กรอง)">
      <Table
  columns={columns}
  dataSource={inventoryData.filter((item) => {
    // Ensure you use the correct key from the backend response (e.g., 'date')
    const itemDate = moment(item.date); // Replace 'date' with the correct field name from your API response
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    return itemDate.isBetween(start, end, 'day', '[]');
  })}
  rowKey={(record) => `${record.inventory_id}-${record.date}`} // Ensure unique keys by combining inventory_id and date
  loading={loading}
  pagination={{
    current: currentPage,
    pageSize: pageSize,
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: ['50', '100', '150'],
    onChange: handleTableChange,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  }}
/>

      </Card>
    </div>
  );
};


export default withRoleAccess(InventoryReportDashboard, ['superadmin']);
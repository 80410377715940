import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './CreateUserForm.css'; // Assuming you have CSS for modal styling

function SuccessModal({ details, onClose, onConfirm }) {
  return (
    <div className="panel-overlay">
      <div className="right-panel-popup">
        <h2>Confirm User Details</h2>
        <div className="modal-close-icon" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="user-details-summary">
          <p><strong>First Name:</strong> {details.firstname}</p>
          <p><strong>Last Name:</strong> {details.lastname}</p>
          <p><strong>Email:</strong> {details.email}</p>
          <p><strong>Role:</strong> {details.role}</p>
          <p><strong>Phone:</strong> {details.phone}</p>
          <p><strong>Address:</strong> {details.address}</p>
          <p><strong>Nickname:</strong> {details.nickname}</p>
          <p><strong>National ID:</strong> {details.nationalid}</p>
          <p><strong>Date of Birth:</strong> {details.date_of_birth}</p>
          <p><strong>Temporary Password:</strong> {details.password}</p>

          {details.role === 'teacher' && (
            <>
              <p><strong>Bachelor's Degree:</strong> {details.bachelorDegree}</p>
              <p><strong>Part-Time:</strong> {details.isPartTime ? 'Yes' : 'No'}</p>
              <p><strong>Massage Therapy Background:</strong> {details.massageTherapyBackground}</p>
              <p><strong>Medical History:</strong> {details.medicalHistory}</p>
              <p><strong>Application Fee:</strong> {details.applicationFee}</p>
              <p><strong>Nationality:</strong> {details.nationality}</p>
              <p><strong>Race:</strong> {details.race}</p>
              <p><strong>Religion:</strong> {details.religion}</p>
              {details.religion === 'Others' && (
                <p><strong>Specify Other Religion:</strong> {details.otherReligion}</p>
              )}
              <p><strong>Emergency Phone Number:</strong> {details.emergencyPhoneNumber}</p>
              <p><strong>Height:</strong> {details.height} cm</p>
              <p><strong>Weight:</strong> {details.weight} kg</p>
              <p><strong>Branch:</strong> {details.branch}</p>
            </>
          )}
        </div>
        <button onClick={onConfirm}>Confirm</button>
      </div>
    </div>
  );
}

export default SuccessModal;

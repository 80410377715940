import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Spin, notification, Calendar, ConfigProvider } from 'antd'; // Added Badge, Calendar
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendarAlt, faBriefcase, faEnvelope, faPhone, faHome, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import './Teacher.css';
import thTH from 'antd/lib/locale/th_TH';
import { useTranslation } from 'react-i18next';
import moment from 'moment'; // Import moment


const TeacherDashboardAdmin = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sharedEvents, setSharedEvents] = useState([]); 
  const [modalVisible, setModalVisible] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };
 
  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTeacher(response.data);
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        notification.error({ message: t('Error fetching teacher data') });
      } finally {
        setLoading(false);
      }
    };

    const fetchSharedEvents = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/events/shared/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

    
        setSharedEvents(response.data);
      } catch (error) {
        console.error('Error fetching shared events:', error);
      }
    };
    fetchTeacherData();
    fetchSharedEvents();
  }, [id, API_URL, t]);


  const handleModalOpen = (event) => {
    setModalVisible(true);
  };

const getListData = (value) => {
  const date = value.format('YYYY-MM-DD');
  const eventsOnDate = sharedEvents.filter(event => 
    moment(event.start).format('YYYY-MM-DD') === date
  );

  return eventsOnDate.map(event => ({
    type: 'success', // Assuming all events are marked as 'success', you can change the type dynamically if needed
    title: event.title,
    description: event.description,
    time: `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`
  }));
};

const dateCellRender = (value) => {
  const listData = getListData(value);
  const hasEvents = listData.length > 0;

  return (
    <div
      style={{
        color: '#F2E8DC', // Set the font color
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: hasEvents ? '#7A8C7E' : 'transparent', // Light blue background if there are events
        height: '100%',
        overflow: 'auto'
      }}
    >
      <ul className="events" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {listData.map((item, index) => (
          <li key={index} style={{ marginBottom: '5px' }}>
            <strong>{item.title}</strong><br/>
            <span>{item.description}</span><br/>
            <span>{item.time}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const handleLogAttendance = (record) => {
  // Use navigate to go to the attendance logging page with the specific event ID
  navigate(`/log-attendance/${record.eventid}`);
};

  if (loading) {
    return <Spin tip={t('Loading')} />;
  }

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'start',
      key: 'date',
      render: (start) => moment(start).format('YYYY-MM-DD'), // Format the start date
    },
    {
      title: t('Start Time'),
      dataIndex: 'start',
      key: 'start_time',
      render: (start) => moment(start).format('HH:mm'), // Format the start time
    },
    {
      title: t('End Time'),
      dataIndex: 'end',
      key: 'end_time',
      render: (end) => moment(end).format('HH:mm'), // Format the end time
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleLogAttendance(record)}>
          {t('Log Attendance')}
        </Button>
      ),
    },
  ];

  if (loading) {
    return <Spin tip={t('Loading')} />;
  }

  if (!teacher) {
    return <p>{t('No teacher data available')}</p>;
  }


  return (
    <div className="teacher-dashboard-container">
      <Sidebar />
      <Header />
      <div className="teacher-header">
        <div className="teacher-header-info">
          <h1>
            <FontAwesomeIcon icon={faUser} /> {teacher.firstname} {teacher.lastname} ({teacher.nickname})
          </h1>
          <h6><FontAwesomeIcon icon={faCalendarAlt} /> {t('dateJoined')}: {formatDate(teacher.createdat)}</h6>
          <h6><FontAwesomeIcon icon={faBriefcase} /> {t('position')}: {teacher.role}</h6>
          <h6><FontAwesomeIcon icon={faEnvelope} /> {teacher.email}</h6>
          <h6><FontAwesomeIcon icon={faPhone} /> {teacher.phone}</h6>
          <h6><FontAwesomeIcon icon={faHome} /> {teacher.address}</h6>
          <h6><FontAwesomeIcon icon={faBirthdayCake} /> {formatDate(teacher.date_of_birth)}</h6>
        </div>
        <div className="teacher-right-header">
          <span className={`status ${teacher.online ? 'online' : 'offline'}`}>
            {teacher.online ? t('online') : t('offline')}
          </span>
          <p>{t('lastSignedIn')}: {teacher.last_signed_in ? formatDate(teacher.last_signed_in) : 'N/A'}</p>
        </div>
      </div>
      
      <ConfigProvider locale={thTH}>
          <Calendar
            dateCellRender={dateCellRender}
            mode="month" // Ensure it's in month view
            onSelect={handleModalOpen}
          />
        </ConfigProvider>

      <div className="all-classes">
        <h2>{t('Shared Events')}</h2>
        <Table columns={columns} dataSource={sharedEvents} rowKey="eventid" />
      </div>

    </div>
  );
};

export default withRoleAccess(TeacherDashboardAdmin, ['admin', 'superadmin']);

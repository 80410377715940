import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, Button, notification } from 'antd'; 
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useParams } from 'react-router-dom';

const LogAttendance = () => {
  const { eventid } = useParams();
  const [studentId, setStudentId] = useState('');
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [eventDetails, setEventDetails] = useState({ title: '', description: '' });

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchEventDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/events/${eventid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEventDetails(response.data);
    } catch (error) {
      console.error('Error fetching event details:', error);
      notification.error({ message: 'Error fetching event details' });
    }
  }, [eventid, API_URL]);
  
  const fetchAttendanceLogs = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/attendance/event/${eventid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAttendanceLogs(response.data);
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
    }
  }, [eventid, API_URL]);

  useEffect(() => {
    fetchEventDetails();
    fetchAttendanceLogs();
  }, [fetchEventDetails, fetchAttendanceLogs]);

  const handleLogAttendance = async () => {
    if (!studentId) {
      notification.warning({ message: 'Please enter a valid School ID' });
      return;
    }
  
    const isDuplicate = attendanceLogs.some((log) => log.schoolid === studentId);
    if (isDuplicate) {
      notification.error({ message: 'Duplicate entry', description: 'This student has already been logged for the event.' });
      return;
    }
  
    try {
      const payload = {
        eventid,
        schoolid: studentId,
        title: eventDetails.title,
        description: eventDetails.description,
        teacherid: eventDetails.userid,
      };
  
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/attendance/log-attendance`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      const loggedStudent = response.data;
      if (loggedStudent.blacklist) {
        notification.warning({
          message: 'Blacklisted Student!',
          description: `Student is blacklisted for the following reason: ${loggedStudent.blacklistreason}`,
        });
      }
  
      notification.success({ message: 'Attendance logged successfully' });
      setStudentId(''); 
      fetchAttendanceLogs(); 
    } catch (error) {
      console.error('Error logging attendance:', error.response?.data || error); 
      notification.error({ message: 'Error logging attendance' });
    }
  };

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: 'Student Name',
      dataIndex: 'firstnamethai',
      key: 'firstnamethai',
      render: (text, record) => `${record.firstnamethai} ${record.lastnamethai}`,
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => new Date(timestamp).toLocaleString(),
    },
    {
      title: 'Blacklist',
      key: 'blacklist',
      render: (text, record) => {
        if (record.blacklist) {
          return (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              {record.blacklistreason}
            </span>
          );
        }
        return 'No';
      },
    },
  ];

  return (
    <div className="teacher-dashboard-container">
      <Sidebar />
      <Header />

      <div style={{ padding: '20px' }}>
        <h2>{eventDetails.title}</h2>
        <h2>{eventDetails.description}</h2>

        <Input
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
          placeholder="Enter School ID"
          style={{ width: '300px', marginRight: '10px' }}
        />
        <Button type="primary" onClick={handleLogAttendance}>
          Log Attendance
        </Button>

        <Table
          columns={columns}
          dataSource={attendanceLogs}
          rowKey="log_id"
        />
      </div>
    </div>
  );
};

export default withRoleAccess(LogAttendance, ['admin', 'superadmin', 'teacher']);

import React, { useState, useEffect } from 'react';
import { Table, Spin, message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Graduate = () => {
  const { id } = useParams(); // Get the student ID from URL parameters
  const [data, setData] = useState([]);
  const [testScores, setTestScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

    // Function to format prices without the currency symbol
const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('th-TH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };
  

  const fetchFullyPaidInvoices = async () => {
    setLoading(true);
    try {
      // Fetch the fully paid invoices for the specific student ID
      const response = await axios.get(`${API_URL}/student-payment/student-fully-paid-invoices`, {
        params: {
          student_id: id, // Use student ID from URL params
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching fully paid invoices:', error);
      message.error(t('fetchDataError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFullyPaidInvoices();
  }, [id]); 
  
  useEffect(() => {
    const fetchTestScores = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test/book-test/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data.length === 0) {
          notification.info({ message: t('No test bookings found for this student') });
        } else {
          setTestScores(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          notification.info({ message: t('No test bookings found for this student') });
        } else {
          console.error('Error fetching test bookings:', error);
          notification.error({ message: t('Error fetching test bookings') });
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchTestScores();
  }, [API_URL, id, t]);

  const columns = [
    {
      title: t('studentid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('studentName'),
      key: 'studentName',
      render: (text, record) => `${record.firstnamethai || ''} ${record.lastnamethai || ''}`,
    },
    {
      title: t('items'),
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('Already Paid'),
      dataIndex: 'total_paid_amount',
      key: 'total_paid_amount',
      render: (amount) => new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount),
    },
    {
      title: t('payment_date'),
      dataIndex: 'date_paid',
      key: 'date_paid',
      render: (date) => moment(date).format('DD/MM/YYYY'),
    },
  ];

  const columnsTestScores = [
    { title: t('subject'), dataIndex: 'course_name', key: 'course_name' },
    { title: t('level'), dataIndex: 'subject', key: 'subject' },
    { 
      title: t('Date'), 
      dataIndex: 'test_date', 
      key: 'test_date',
      render: (text, record) => moment(record.test_date).format('LL'), // Formatted Date
    },
    { title: t('score'), dataIndex: 'score', key: 'score' },
    { title: t('Test Result'), dataIndex: 'pass_fail', key: 'pass_fail' },
    { title: t('price'), dataIndex: 'price', key: 'price', render: (amount) => formatPrice(amount),},
    { title: t('paidstatus'), dataIndex: 'paid_status', key: 'paid_status' },
    
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2>{t('fullypay')}</h2>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Spin>
      <h2>{t('Test Result')}</h2>
      <Table
      dataSource={testScores}
      columns={columnsTestScores}
      rowKey="id"
      loading={loading}
      className="tab-table"
    />
    </div>



  );
};

export default withRoleAccess(Graduate, ['admin', 'superadmin']);

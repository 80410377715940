import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, notification, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const thaiMonths = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

const CashRegisterPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() + 543; // Convert to Buddhist year
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // YYYY-MM format

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const currentMonthIndex = parseInt(selectedMonth.split('-')[1]) - 1;

  // Generate dates for the selected month
  const getDatesInMonth = (yearMonth) => {
    const [year, month] = yearMonth.split('-').map(Number);
    const daysInMonth = new Date(year, month, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      return `${yearMonth}-${String(day).padStart(2, '0')}`;
    });
  };

  // Fetch all required data
  useEffect(() => {
    const dates = getDatesInMonth(selectedMonth);
    fetchDataForDates(dates);
  }, [selectedMonth]);

  const fetchDataForDates = async (dates) => {
    setLoading(true);
    try {
        const token = localStorage.getItem('token');

        const datePromises = dates.map(async (date) => {
            try {
                const [incomeResponse, expenseResponse, cashRegisterResponse] = await Promise.all([
                    axios.get(`${API_URL}/cashregister/cash-income`, {
                        params: { date },
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${API_URL}/cashregister/cashregister-expenses`, {
                        params: { date },
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${API_URL}/cashregister`, {
                        params: { date },
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                const cashIncome = incomeResponse.data.totalAmount || 0;
                const cashExpenses = expenseResponse.data.totalAmount || 0;

                let cashRegisterEntry = cashRegisterResponse.data[0] || {
                    date,
                    opening_balance: 0,
                    id: null,
                };

                return {
                    ...cashRegisterEntry,
                    date,
                    cash_income: cashIncome,
                    cash_expenses: cashExpenses,
                    closing_balance:
                        Number(cashRegisterEntry.opening_balance || 0) +
                        cashIncome -
                        cashExpenses,
                };
            } catch (error) {
                if (error.response?.status === 404) {
                    // Return default data structure for empty rows
                    return {
                        date,
                        opening_balance: 0,
                        cash_income: 0,
                        cash_expenses: 0,
                        closing_balance: 0,
                        nickname: '-',
                        note: '-',
                    };
                } else {
                    throw error; // Rethrow other errors
                }
            }
        });

        const resolvedData = await Promise.all(datePromises);
        setData(resolvedData);
    } catch (error) {
        console.error('Error fetching data:', error);
        notification.error({ message: 'Error fetching cash register data' });
    } finally {
        setLoading(false);
    }
};


  const handleAddEdit = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const payload = {
        ...values,
        userId: localStorage.getItem('userId'),
        note: values.note || '', // Include note
      };
  
      if (editingRecord?.id) {
        await axios.put(`${API_URL}/cashregister/${editingRecord.id}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        notification.success({ message: t('successfullySaved') });
      } else {
        await axios.post(`${API_URL}/cashregister`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        notification.success({ message: t('successfullySaved') });
      }
  
      const dates = getDatesInMonth(selectedMonth);
      fetchDataForDates(dates);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error saving data:', error);
      notification.error({ message: t('error_saving_data') });
    }
  };
  

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num || 0);
  };

  // Calculate deposit amount
  const calculateDeposit = (closingBalance) => {
    const threshold = 3000;
    return Math.max(0, closingBalance - threshold);
  };

  const columns = [
    { 
      title: t('date'),  // assuming your translation key is 'date'
      dataIndex: 'date', 
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    {
      title: t('counted_by'),
      dataIndex: 'nickname',
      key: 'nickname',
      render: (text) => text || '-'
    },
    { 
      title: t('opening_balance'),
      dataIndex: 'opening_balance', 
      key: 'opening_balance',
      render: (value) => formatNumber(value)
    },
    { 
      title: t('cash_income'),
      dataIndex: 'cash_income', 
      key: 'cash_income',
      render: (value) => (
        <span style={{ color: 'green' }}>
          +{formatNumber(value)}
        </span>
      )
    },
    { 
      title: t('cash_expenses'),
      dataIndex: 'cash_expenses', 
      key: 'cash_expenses',
      render: (value) => (
        <span style={{ color: 'red' }}>
          -{formatNumber(value)}
        </span>
      )
    },
    { 
      title: t('closing_balance'),
      dataIndex: 'closing_balance', 
      key: 'closing_balance',
      render: (value) => {
        let color = value < 0 ? 'red' : value >= 3000 ? 'green' : 'blue';
        return (
          <Tag color={color} style={{ fontSize: '14px', padding: '4px 8px' }}>
            {formatNumber(value)}
          </Tag>
        );
      }
    },
    {
      title: t('deposit_amount'),
      dataIndex: 'closing_balance',
      key: 'deposit_amount',
      render: (value) => (
        <span style={{ 
          fontWeight: 'bold',
          fontSize: '16px'
        }}>
          {formatNumber(calculateDeposit(value))}
        </span>
      )
    },
    {
      title: t('note'),
      dataIndex: 'note',
      key: 'note',
      render: (value) => value || '-', // Display '-' if note is empty
    },    
    {
        title: t('actions'),
        key: 'actions',
        render: (_, record) => (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditingRecord(record);
              setIsModalVisible(true);
            }}
          />
        ),
      },
  ];

  const exportToCSV = () => {
    const headers = [
      t('date'),
      t('counted_by'),
      t('opening_balance'),
      t('cash_income'),
      t('cash_expenses'),
      t('closing_balance'),
      t('deposit_amount'),
      t('note'), // Add note
    ];    
    
    const csvData = data.map(record => [
      new Date(record.date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      record.nickname || '-',
      Number(record.opening_balance || 0).toFixed(2),
      Number(record.cash_income || 0).toFixed(2),
      Number(record.cash_expenses || 0).toFixed(2),
      Number(record.closing_balance || 0).toFixed(2),
      Number(calculateDeposit(record.closing_balance) || 0).toFixed(2),
      record.note || '-', // Include note
    ]);
    

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(field => `"${field}"`).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${t('cash_register')}_${selectedMonth}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ marginTop: '70px', marginLeft: '75px', padding: '20px' }}>
      <Sidebar />
      <Header />
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>{t('cash_register_management')}</h2>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <select
                value={currentMonthIndex}
                onChange={(e) => {
                    const month = String(Number(e.target.value) + 1).padStart(2, '0');
                    const year = selectedYear - 543; // Convert back to CE year for API
                    setSelectedMonth(`${year}-${month}`);
                }}
                style={{ 
                    padding: '8px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px'
                }}
                >
                {thaiMonths.map((month, index) => (
                    <option key={index} value={index}>
                    {month}
                    </option>
                ))}
                </select>
                <select
                value={selectedYear}
                onChange={(e) => {
                    const year = Number(e.target.value) - 543;
                    const month = selectedMonth.split('-')[1];
                    setSelectedYear(Number(e.target.value));
                    setSelectedMonth(`${year}-${month}`);
                }}
                style={{ 
                    padding: '8px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px'
                }}
                >
                {Array.from({ length: 5 }, (_, i) => selectedYear - 2 + i).map(year => (
                    <option key={year} value={year}>
                    {year}
                    </option>
                ))}
                </select>
          <Button onClick={exportToCSV}>
            {t('export_to_csv')}
          </Button>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        rowKey="date"
        loading={loading}
        pagination={false}
        summary={pageData => {
          const totals = pageData.reduce((acc, curr) => ({
            opening_balance: acc.opening_balance + Number(curr.opening_balance || 0),
            cash_income: acc.cash_income + Number(curr.cash_income || 0),
            cash_expenses: acc.cash_expenses + Number(curr.cash_expenses || 0),
            closing_balance: acc.closing_balance + Number(curr.closing_balance || 0),
            deposit_amount: acc.deposit_amount + calculateDeposit(curr.closing_balance)
          }), {
            opening_balance: 0,
            cash_income: 0,
            cash_expenses: 0,
            closing_balance: 0,
            deposit_amount: 0
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>{t('total')}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>-</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{formatNumber(totals.cash_income)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{formatNumber(totals.cash_expenses)}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{formatNumber(totals.closing_balance)}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{formatNumber(totals.deposit_amount)}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>-</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />

      <Modal
        title={editingRecord ? t('edit_opening_balance') : t('add_opening_balance')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          initialValues={editingRecord || {}}
          onFinish={handleAddEdit}
        >
          <Form.Item
            name="date"
            label={t('date')}
            rules={[{ required: true, message: t('please_select_date') }]}
          >
            <Input type="date" disabled={!!editingRecord} />
          </Form.Item>
          <Form.Item
            name="opening_balance"
            label={t('opening_balance')}
            rules={[{ required: true, message: t('please_enter_opening_balance') }]}
          >
            <Input step="0.01" />
          </Form.Item>
          <Form.Item
            name="note"
            label={t('note')}
            rules={[{ required: false }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('save')}
            </Button>
            <Button style={{ marginLeft: '10px' }} onClick={() => setIsModalVisible(false)}>
              {t('cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withRoleAccess(CashRegisterPage, ['superadmin', 'admin']);
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Button, Input, Select, Checkbox, notification } from 'antd';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import moment from 'moment';
import Header from '../../components/Header/Header';
import './Sales.css';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Sales = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState('fixed');
  const [vat, setVat] = useState(0);
  const [vatChecked, setVatChecked] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [error, setError] = useState(null);
  const [categories] = useState([
    { key: 'Course', label: t('Course') },
    { key: 'Book', label: t('Book') },
    { key: 'Uniform', label: t('Uniform') },
    { key: 'Exam Uniform', label: t('Exam Uniform') },
    { key: 'Certificate', label: t('Certificates') },
    { key: 'Others', label: t('Others') }
  ]);
  
  const [selectedCategory, setSelectedCategory] = useState('all');
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

 // Fetch students from the API
 const fetchStudents = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/test/students`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setStudents(response.data || []);
    setFilteredStudents(response.data || []); // Initially, show all students
  } catch (error) {
    console.error('Error fetching students:', error);
    setError('Failed to fetch students.');
  }
}, [API_URL]);

// Fetch courses along with inventory items
const fetchCourses = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/classes/courses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return fetched courses
  } catch (error) {
    console.error('Error fetching courses:', error);
    setError('Failed to fetch courses.');
    return [];
  }
}, [API_URL]);

// Update fetchItems to include courses
const fetchItems = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const [inventoryResponse, courses] = await Promise.all([
      axios.get(`${API_URL}/inventory`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
      fetchCourses(), // Fetch courses
    ]);

    const activeItems = inventoryResponse.data.filter((item) => item.status === 'active');

    setItems(activeItems);
  } catch (error) {
    console.error('Error fetching inventory and courses:', error);
    setError('Failed to fetch inventory and courses.');
  }
}, [API_URL, fetchCourses]);

// Handle student search
const handleSearch = (value) => {
  const searchTerm = value.toLowerCase();
  const filtered = students.filter(student =>
    (student.firstnamethai && student.firstnamethai.toLowerCase().includes(searchTerm)) ||
    (student.lastnamethai && student.lastnamethai.toLowerCase().includes(searchTerm)) ||
    (student.schoolid && student.schoolid.includes(searchTerm)) ||
    (student.nationalid && student.nationalid.includes(searchTerm))
  );
  setFilteredStudents(filtered); 
};

const handleStudentSelect = (value) => {
  setSelectedStudent(value);
  const selectedStudentDetails = students.find(student => student.studentid === value);
  setStudentDetails(selectedStudentDetails || {});
};


  // Calculate total and grand total using useCallback to avoid dependency warning
  const calculateTotals = useCallback(() => {
    const total = selectedItems.reduce((acc, item) => acc + item.price_per_unit * item.quantity, 0);

    // Calculate discount
    let discountValue = 0;
    if (discountType === 'percentage' && discount > 0) {
      discountValue = (total * discount) / 100;
    } else {
      discountValue = discount;
    }

    const vatAmount = vatChecked ? (total - discountValue) * 0.07 : 0; // Calculate VAT only if checked
    setVat(vatAmount);
    setTotalAmount(total);
    setGrandTotal(total - discountValue + vatAmount);
  }, [selectedItems, discount, discountType, vatChecked]);

  useEffect(() => {
    fetchStudents();
    fetchItems();
  }, [fetchStudents, fetchItems]);

  useEffect(() => {
    calculateTotals();
  }, [selectedItems, discount, discountType, vatChecked, calculateTotals]);

  
  
  // Handle VAT checkbox change
  const handleVatCheckboxChange = (e) => {
    setVatChecked(e.target.checked);
  };

  const handleAddItem = (item) => {
    const existingItem = selectedItems.find((selectedItem) => selectedItem.inventory_id === item.inventory_id);

    if (existingItem) {
      setSelectedItems(
        selectedItems.map((selectedItem) =>
          selectedItem.inventory_id === item.inventory_id
            ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
            : selectedItem
        )
      );
    } else {
      setSelectedItems([...selectedItems, { ...item, quantity: 1 }]);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredItems = selectedCategory === 'all'
  ? items
  : items.filter((item) => item.category === selectedCategory);

  const handleRemoveItem = (inventory_id) => {
    setSelectedItems(selectedItems.filter((item) => item.inventory_id !== inventory_id));
  };

  const handleDiscountChange = (e) => {
    const input = e.target.value;
  
    // Handle when input is empty or contains just "%"
    if (input === '' || input === '%') {
      setDiscount(0);
      setDiscountType('percentage');
      return;
    }
  
    // Handle percentage input
    if (input.endsWith('%')) {
      setDiscountType('percentage');
      const numericValue = parseFloat(input.slice(0, -1)); // Extract number before "%"
      setDiscount(isNaN(numericValue) ? 0 : numericValue); // Set 0 if input is invalid
    } else {
      // Handle fixed discount
      setDiscountType('fixed');
      const numericValue = parseFloat(input); // Parse value as a number
      setDiscount(isNaN(numericValue) ? 0 : numericValue); // Set 0 if input is invalid
    }
  };
  
  const handleConfirmPurchase = async () => {
    const saleData = {
      student_id: selectedStudent,
      items: selectedItems,
      discount: discount,
      vat: vat,
      payment_method: paymentMethod,
    };

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/sales/log-sale`, saleData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      notification.success({
        message: 'Success',
        description: 'Sale logged successfully!',
      });

      setSelectedStudent(null);
      setSelectedItems([]);
      setVat(0);
      setDiscount(0);
      setPaymentMethod('');
    } catch (error) {
      console.error('Error logging sale:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to log sale. Please try again.',
      });
    }
  };

  return (
    <div className="pos-system">
      <Sidebar />
      <Header />
      {error && <p className="error">{error}</p>}
      <div className="pos-layout">
      <div className="categories-section">
        <h2>{t('Category')}</h2>
        {categories.map((category) => (
          <Button
          className="sales-button"
            key={category.key}
            type={selectedCategory === category.key ? 'primary' : 'default'}
            onClick={() => handleCategorySelect(category.key)}
          >
            {category.label}
          </Button>
        ))}
      </div>

        <div className="menu-section">
          <div className="items-grid">
            {filteredItems.map((item) => (
              <div key={item.inventory_id} className="item-card">
                <h3>{item.item_name}</h3>
                <p>{formatPrice(item.price_per_unit)} {t('baht')}</p>
                <Button className="sales-button" onClick={() => handleAddItem(item)}>{t('add')}</Button>
              </div>
            ))}
          </div>
        </div>

        <div className="cart-section">
        <div className="student-select">
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder={t('searchByNameOrID')}
            optionFilterProp="children"
            onSearch={handleSearch} 
            onChange={handleStudentSelect} 
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredStudents.map(student => (
              <Option key={student.studentid} value={student.studentid}>
                {`${student.firstnamethai} ${student.lastnamethai} (${student.schoolid})`}
              </Option>
            ))}
          </Select>

          {selectedStudent && (
            <div className="student-details">
  <p>{`${studentDetails.firstnamethai} ${studentDetails.lastnamethai}`}</p>
  <p>{`${t('schoolid')}: ${studentDetails.schoolid}`}</p>
  <p>{`${t('nationalid')}: ${studentDetails.nationalid}`}</p>
  
  {/* Split the items string to format each item */}
  <p>
    {studentDetails.items.split(', ').map((item, index) => {
      // Extract the date, payment status, and payment method from each item
      const match = item.match(/\((\d{2}-\d{2}-\d{4}) - (Paid|Unpaid)(?:\s*-\s*(.+))?\)/);
      const date = match ? match[1] : null;
      const status = match ? match[2] : null;
      const paymentMethod = match ? match[3] : null;
      
      // Translate payment status and method
      const translatedStatus = status === 'Paid' ? 'จ่ายแล้ว' : status === 'Unpaid' ? 'ยังไม่ได้จ่าย' : '';
      const translatedPaymentMethod = paymentMethod === 'fullpay' ? 'จ่ายเต็ม' :
                                       paymentMethod === 'paymentplan' ? 'ผ่อน' :
                                       paymentMethod === 'scholarship' ? 'ขอทุน' : 'Unknown';
      
      return (
        <span key={index} style={{ display: 'block', marginBottom: '8px' }}>
          {item.replace(/\(\d{2}-\d{2}-\d{4} - (Paid|Unpaid)(?:\s*-\s*.+)?\)/, '')} {/* Remove date, status, and method from display */}
          {date && ` (${moment(date, 'DD-MM-YYYY').format('DD MMM YYYY')})`} {/* Format the date */}
          {status && (
            <span style={{ color: status === 'Paid' ? 'green' : 'red', fontWeight: 'bold' }}>
              {` - ${translatedStatus}`}
            </span>
          )}
          {paymentMethod && ` - ${translatedPaymentMethod}`} {/* Display translated payment method */}
        </span>
      );
    })}
  </p>
</div>

)}

        </div>
          <div className="cart-items">
            <Table
              dataSource={selectedItems}
              columns={[
                {
                  title: t('itemname'),
                  dataIndex: 'item_name',
                  key: 'item_name',
                },
                {
                  title: t('priceperunit'),
                  dataIndex: 'price_per_unit',
                  key: 'price_per_unit',
                  render: (price) => `${formatPrice(price)}`,
                },
                {
                  title: t('quantity'),
                  dataIndex: 'quantity',
                  key: 'quantity',
                },
                {
                  title: t('delete'),
                  key: 'action',
                  render: (_, item) => (
                    <Button onClick={() => handleRemoveItem(item.inventory_id)}>{t('delete')}</Button>
                  ),
                },
              ]}
              pagination={false}
              rowKey="inventory_id"
            />
          </div>

          <div className="cart-summary">
            <div className="subtotal">
              <span>{t('subtotal')}</span>
              <span>{formatPrice(totalAmount)} {t('baht')}</span>
            </div>
            <div className="discount">
            <Input
              addonBefore={t('discount')}
              value={discountType === 'percentage' ? `${discount || 0}%` : discount || 0} 
              onChange={handleDiscountChange}
              placeholder="Enter discount (e.g., 10 or 10%)"
            />
            </div>
            <div className="vat">
              <Checkbox checked={vatChecked} onChange={handleVatCheckboxChange}>
                VAT (7%)
              </Checkbox>
            </div>
            <div className="total">
              <span>{t('total')}</span>
              <span>{formatPrice(grandTotal)} {t('baht')}</span>
            </div>
            <div className="payment">
            <Select
              placeholder={t('select_payment_method')}
              onChange={setPaymentMethod}
              value={paymentMethod}
              style={{ width: '100%' }}
            >
              <Option value="" disabled>{t('select_payment_method')}</Option>
              <Option value="fullpay">{t('fullpay')}</Option>
              <Option value="paymentplan">{t('installment')}</Option>
              <Option value="scholarship">{t('scholarship')}</Option>
            </Select>
          </div>

            <Button type="primary" className="pay-button" onClick={handleConfirmPurchase}>
            {t('confirm')} {formatPrice(grandTotal)} {t('baht')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRoleAccess(Sales, ['superadmin', 'admin']);

import React, { useContext } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faLock, faCog, faBookOpen, faBookBookmark, faDollarSign, faMagnifyingGlassDollar, faMessage, faBoxOpen, faCartShopping, faReceipt, faMoneyBill, faMoneyCheckDollar, faClipboardCheck, faGraduationCap, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { permissions } = useContext(AuthContext);
  const { role } = permissions;

  const { t } = useTranslation();

  const menuItems = [
    { iconName: "dashboard", tooltip: t('dashboard'), path: "/dashboard", roles: ["admin", "teacher", "superadmin"] },
    { iconName: "cashregister", tooltip: t('cash_register'), path: "/cashregister", roles: ["admin", "superadmin"] },
    { iconName: "allclasses", tooltip: t('classes'), path: "/allclasses", roles: ["admin", "superadmin"] },
    { iconName: "chat", tooltip: t('chat'), path: "/chat", roles: ["admin", "teacher", "superadmin"] },
    { iconName: "sales", tooltip: t('sales'), path: "/sales", roles: ["admin", "superadmin"] },
    { iconName: "salesreport", tooltip: t('salesreport'), path: "/salesreport", roles: ["admin", "superadmin"] },
    { iconName: "payment", tooltip: t('payment'), path: "/payment", roles: ["admin", "superadmin"] },
    { iconName: "expense", tooltip: t('expense'), path: "/expense", roles: ["admin", "superadmin"] },
    { iconName: "inventory", tooltip: t('inventory'), path: "/inventory", roles: ["superadmin"] },
    { iconName: "test", tooltip: t('test'), path: "/test", roles: ["admin", "superadmin", "teacher"] },
    { iconName: "graduategroup", tooltip: t('graduategroup'), path: "/graduategroup", roles: ["admin", "superadmin"] },
    { iconName: "adminpanel", tooltip: t('admin'), path: "/adminpanel", roles: ["admin", "superadmin"] },
    { iconName: "usercontrolpage", tooltip: t('users'), path: "/usercontrolpage", roles: ["superadmin"] },
    
  ];

  const iconMapping = {
    "dashboard": faTachometerAlt,
    "cashregister": faCashRegister,
    "allclasses": faBookOpen,
    "chat": faMessage,
    "sales": faCartShopping,
    "salesreport": faReceipt,
    "payment": faMoneyBill,
    "expense": faMoneyCheckDollar,
    "inventory": faBoxOpen,
    "test": faClipboardCheck,
    "graduategroup": faGraduationCap,
    "class": faBookBookmark,
    "adminpanel": faCog,
    "teacherhoursadmin": faMagnifyingGlassDollar,
    "teacherhours": faDollarSign,
    "usercontrolpage": faLock,
  };

  const filteredMenuItems = menuItems.filter(item => item.roles.includes(role));

  return (
    <div className="left-panel">
      <ul className="menu-list">
        {filteredMenuItems.map((item, index) => (
          <li key={index} className={`menu-item ${item.isActive ? 'active' : ''}`}>
            <NavLink to={item.path} className="menu-link menu-icon-link">
              <FontAwesomeIcon icon={iconMapping[item.iconName]} size="lg" className="menu-icon" />
              <span className="menu-text">{item.tooltip}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import { Table, Form, Input, Button, DatePicker, Modal, Select, message, Tabs } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import GraduateList from './GraduateList';
import moment from 'moment';
import 'antd/dist/reset.css';
import './GraduateGroup.css';
import { useTranslation } from 'react-i18next';
import GroupList from './GroupList';

const { RangePicker } = DatePicker;
const { Option } = Select;

const GraduateGroup = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [pageSize, setPageSize] = useState(50); // Page size state
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredCourses = response.data
          .filter((course) => ['M001', 'M002', 'M003'].includes(course.course_code))
          .sort((a, b) => a.course_code.localeCompare(b.course_code));

        setCourses(filteredCourses);
      } catch (error) {
        message.error('Failed to fetch courses.');
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [API_URL]);

  const fetchGraduationRecords = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/graduate-group/graduation`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      message.error('Failed to fetch graduation records.');
      console.error('Error fetching graduation records:', error);
    }
  };

  useEffect(() => {
    fetchGraduationRecords();
  }, []);

  const handleAddOrUpdateRecord = async (values) => {
    const selectedCourse = courses.find((course) => course.course_name === values.courseName);
    const recordData = {
      course_id: selectedCourse.id,
      course_name: selectedCourse.course_name,
      group_name: values.group_name,
      date_range: [
        values.dateRange[0].format('YYYY-MM-DD'),
        values.dateRange[1].format('YYYY-MM-DD'),
      ],
      total_students: values.totalStudents,
    };

    try {
      const token = localStorage.getItem('token');
      if (isEditMode) {
        await axios.put(`${API_URL}/graduate-group/graduation/${editingRecord.id}`, recordData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        message.success('Graduation record updated successfully');
      } else {
        const response = await axios.post(`${API_URL}/graduate-group/graduation`, recordData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setData([...data, response.data]);
        message.success('Graduation record added successfully');
      }

      setIsModalVisible(false);
      form.resetFields();
      fetchGraduationRecords();
    } catch (error) {
      message.error(isEditMode ? 'Failed to update graduation record' : 'Failed to add graduation record');
      console.error(error);
    }
  };

  const handleEdit = (record) => {
    if (record.students_in_group > 0) {
      message.error('You cannot edit this record because students are already in the group.');
      return;
    }

    setEditingRecord(record);
    setIsEditMode(true);

    form.setFieldsValue({
      courseName: record.course_name,
      group_name: record.group_name,
      dateRange: [moment(record.start_date), moment(record.end_date)],
      totalStudents: record.total_students,
    });

    setIsModalVisible(true);
  };

  const handleCourseFilterChange = (value) => {
    setSelectedCourse(value);
    if (value) {
      const filtered = data.filter((record) => record.course_name === value);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
  };

  const columns = [
    { title: t('classCode'), dataIndex: 'course_id', key: 'course_id' },
    { title: t('Course'), dataIndex: 'course_name', key: 'course_name' },
    { title: t('groupno'), dataIndex: 'group_name', key: 'group_name' },
    {
      title: t('start_date'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : ''),
    },
    {
      title: t('end_date'),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : ''),
    },
    { title: t('totalStudents'), dataIndex: 'total_students', key: 'total_students' },
    { title: t('studentsInGroup'), dataIndex: 'students_in_group', key: 'students_in_group' },
    {
      title: t('actions'),
      key: 'actions',
      render: (record) => (
        <Button onClick={() => handleEdit(record)}>
          {t('edit')}
        </Button>
      ),
    },
  ];

  const openAddModal = () => {
    setIsModalVisible(true);
    setIsEditMode(false);
    form.resetFields();
  };

  return (
    <div className="graduategroup">
      <Sidebar />
      <Header />
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: t('Graduate Group'),
            children: (
              <div className="content">
                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                  <Button onClick={openAddModal}>{t('Add Graduate Group')}</Button>
                  <div>
                    <Select
                      placeholder={t('Filter by Course')}
                      value={selectedCourse}
                      onChange={handleCourseFilterChange}
                      style={{ width: 280, height: '40px', lineHeight: '40px' }}
                      allowClear
                    >
                      {courses.map((course) => (
                        <Option key={course.id} value={course.course_name}>
                          {course.course_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <Table
                  dataSource={filteredData}
                  columns={columns}
                  rowKey="id"
                  style={{ marginTop: 20 }}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: filteredData.length,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['50', '100', '150'],
                    onChange: handleTableChange,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />

                <Modal
                  title={isEditMode ? t('Edit Graduate Group') : t('Add Graduate Group')}
                  open={isModalVisible}
                  onCancel={() => setIsModalVisible(false)}
                  footer={null}
                >
                  <Form form={form} onFinish={handleAddOrUpdateRecord} layout="vertical">
                    <Form.Item
                      label={t('Courses Selection')}
                      name="courseName"
                      rules={[{ required: true, message: 'Please select the course name!' }]}
                    >
                      <Select placeholder={t('Courses Selection')}>
                        {courses.map((course) => (
                          <Option key={course.id} value={course.course_name}>
                            ({course.id}) {course.course_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={t('groupno')}
                      name="group_name"
                      rules={[{ required: true, message: 'Please input the group no.!' }]}
                    >
                      <Input placeholder={t('groupno')} />
                    </Form.Item>

                    <Form.Item
                      label={t('Select Date Range')}
                      name="dateRange"
                      rules={[{ required: true, message: 'Please select the date range!' }]}
                    >
                      <RangePicker format="DD-MM-YYYY" />
                    </Form.Item>

                    <Form.Item
                      label={t('totalStudents')}
                      name="totalStudents"
                      rules={[{ required: true, message: 'Please input the total students!' }]}
                    >
                      <Input type="number" placeholder={t('totalStudents')} />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {isEditMode ? t('Update Record') : t('Add Record')}
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            ),
          },
          {
            key: '2',
            label: t('Graduate List'),
            children: <GraduateList />,
          },
          {
            key: '3',
            label: t('Group List'),
            children: <GroupList />,
          },
        ]}
      />
    </div>
  );
};

export default withRoleAccess(GraduateGroup, ['admin', 'superadmin']);

import React, { useState, useCallback } from 'react';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChalkboardTeacher, 
  faUserGraduate, 
  faExclamationCircle, 
  faBookOpenReader, 
  faTablet, 
  faMoneyCheck, 
  faUserCheck 
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import EditDeleteTeacher from './EditDeleteTeacher';
import EditDeleteStudent from './EditDeleteStudent';
import EditDeleteSubject from './EditDeleteSubject';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CreateTeacherForm from '../CreateUserform/CreateTeacherForm';
import CreateStudentForm from '../CreateUserform/CreateStudentForm';
import StudentAttendanceLog from './StudentAttendanceLog';
import FullyPaidInvoices from './FullyPaidInvoices';
import PaidPassStudent from './PaidPassStudent';
import StudentMatch from './StudentMatch';

function AdminPanel() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('students');
  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Add this to force refreshes

  const toggleCreateTeacherForm = () => {
    setShowCreateTeacherForm(!showCreateTeacherForm);
  };

  const toggleCreateStudentForm = () => {
    setShowCreateStudentForm(!showCreateStudentForm);
  };

  // Function to handle tab changes
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    // Increment refreshKey to trigger a refresh when switching to the tab
    setRefreshKey(prev => prev + 1);
  };

  // Callback for successful student creation
  const handleStudentCreated = useCallback(() => {
    message.success(t('studentCreatedSuccessfully'));
    setRefreshKey(prev => prev + 1);
    setShowCreateStudentForm(false);
  }, [t]);

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="main">
        <div className="tabs-container">
          <button
            className={`tutor-tab-button ${activeTab === 'students' ? 'active' : ''}`}
            onClick={() => handleTabChange('students')}
          >
            <FontAwesomeIcon icon={faUserGraduate} size="1x" />
            <span>{t('students')}</span>
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'teachers' ? 'active' : ''}`}
            onClick={() => setActiveTab('teachers')}
          >
            <FontAwesomeIcon icon={faChalkboardTeacher} size="1x" />
            <span>{t('tutors')}</span>
          </button>

          <button className={`tutor-tab-button ${activeTab === 'subjects' ? 'active' : ''}`} onClick={() => setActiveTab('subjects')}>
          <FontAwesomeIcon icon={faTablet} size="1x" />
            <span>{t('subjects')}</span>
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'attendance' ? 'active' : ''}`}
            onClick={() => setActiveTab('attendance')}
          >
            <FontAwesomeIcon icon={faBookOpenReader} size="1x" />
            <span>{t('attendance')}</span>
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'fullypaid' ? 'active' : ''}`}
            onClick={() => setActiveTab('fullypaid')}
          >
            <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
            <span>{t('fullypaid')}</span>
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'studentmatch' ? 'active' : ''}`}
            onClick={() => setActiveTab('studentmatch')}
          >
            <FontAwesomeIcon icon={faUserCheck} size="1x" />
            <span>{t('studentmatch')}</span>
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'paidpassstudent' ? 'active' : ''}`}
            onClick={() => setActiveTab('paidpassstudent')}
          >
            <FontAwesomeIcon icon={faUserCheck} size="1x" />
            <span>{t('paidpassstudent')}</span>
          </button>
        </div>

        <div className="main-content">
          <div className="warning">
            <p>
              <FontAwesomeIcon icon={faExclamationCircle} /> 
              <strong>{t('warning')}</strong>: {t('warningMessage')}
            </p>
          </div>
          <div>
            {activeTab === 'teachers' && !showCreateTeacherForm && (
              <EditDeleteTeacher 
                onCreateNewTeacher={toggleCreateTeacherForm}
                key={`teacher-${refreshKey}`}
              />
            )}
            {activeTab === 'teachers' && showCreateTeacherForm && (
              <CreateTeacherForm onClose={toggleCreateTeacherForm} />
            )}
            {activeTab === 'students' && !showCreateStudentForm && (
              <EditDeleteStudent 
                onCreateNewStudent={toggleCreateStudentForm}
                key={`student-${refreshKey}`} // Add key to force remount
                refreshData={() => setRefreshKey(prev => prev + 1)}
              />
            )}
            {activeTab === 'students' && showCreateStudentForm && (
              <CreateStudentForm 
                onClose={toggleCreateStudentForm}
                onSuccess={handleStudentCreated}
              />
            )}
            {activeTab === 'subjects' && (
              <EditDeleteSubject key={`subject-${refreshKey}`} />
            )}
            {activeTab === 'attendance' && (
              <StudentAttendanceLog key={`attendance-${refreshKey}`} />
            )}
            {activeTab === 'fullypaid' && (
              <FullyPaidInvoices key={`fullypaid-${refreshKey}`} />
            )}
            {activeTab === 'studentmatch' && (
              <StudentMatch key={`studentmatch-${refreshKey}`} />
            )}
            {activeTab === 'paidpassstudent' && (
              <PaidPassStudent key={`paidpass-${refreshKey}`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRoleAccess(AdminPanel, ['admin', 'superadmin']);
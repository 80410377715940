import React, { useState, useEffect } from 'react';
import { Table, message, Tabs, Layout, Button, Select, Modal, Form, Input, InputNumber, Space, Popconfirm } from 'antd';
import axios from 'axios';
import { EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv'; // CSV export library
import AddExpense from './AddExpense';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ExpenseLog from './ExpenseLog';
import { DatePicker } from 'antd';

dayjs.extend(localizedFormat);
dayjs.locale('th');

const { Content, Sider, Header: AntHeader } = Layout;
const { Option } = Select;
const { Item: FormItem } = Form;

const API_URL = process.env.REACT_APP_API_URL;

const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredExpenses, setFilteredExpenses] = useState([]); 
  const getCurrentMonthYear = () => {
    return dayjs().format('MM-YYYY');
  };
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { t, i18n } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState('1'); // Track active tab
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [pageSize, setPageSize] = useState(50);
  const [wallet, setWallet] = useState(form.getFieldValue('wallet') || 'cashregister');
  const formatDate = (dateString) => {
    return dayjs(dateString).format('DD MMMM YYYY').toUpperCase();
  };
  // Fetch expenses
  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${API_URL}/expense`);
      setExpenses(response.data);
      setFilteredExpenses(response.data); // Initially set to all expenses
    } catch (error) {
      message.error(t('load_error'));
    } finally {
      setLoading(false);
    }
  };

  const formatMonthYear = (monthYear) => {
    return dayjs(monthYear + '-01').format('MM-YYYY');
  };
  const applyFilters = () => {
    let filtered = expenses;
  
    if (selectedMonthYear) {
      filtered = filtered.filter((expense) =>
        formatMonthYear(expense.month_year) === selectedMonthYear
      );
    }
  
    if (selectedCategory) {
      filtered = filtered.filter((expense) => 
        translateCategory(expense.category_name) === translateCategory(selectedCategory)
      );
    }
  
    setFilteredExpenses(filtered);
    setCurrentPage(1);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
  };
  

  const handleMonthYearChange = (value) => {
    setSelectedMonthYear(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleCurrentMonthClick = () => {
    setSelectedMonthYear(getCurrentMonthYear());
  };

  useEffect(() => {
    applyFilters();
  }, [selectedMonthYear, selectedCategory, expenses]);

  useEffect(() => {
    fetchCategories();
  }, []);
  
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/expense/expense-categories`);
      setCategories(response.data); // Assuming response.data is an array of { category_id, category_name }
    } catch (error) {
      message.error(t('Error fetching categories'));
    }
  };
  
  const handleUpdateExpense = async (values) => {
    if (!editingExpense) return;
  
    try {
      const token = localStorage.getItem('token');
  
      // Find the category_id based on the selected category_name
      const selectedCategory = categories.find(
        (category) => category.category_name === values.category_name
      );
  
      if (!selectedCategory) {
        message.error(t('Invalid category selected'));
        return;
      }
  
      const formattedValues = {
        ...values,
        wallet_description: values.wallet === 'other' ? values.wallet_description : null,
        category_id: selectedCategory.category_id, // Use the category_id
        date: dayjs(values.date).format('YYYY-MM-DD'),
        month_year: dayjs(values.month_year).format('YYYY-MM'),
      };
  
      await axios.put(
        `${API_URL}/expense/${editingExpense.expense_id}`,
        formattedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
          },
        }
      );
  
      message.success(t('Expense updated successfully'));
      fetchExpenses(); // Refresh expenses after update
      closeEditModal(); // Close the modal
  
    } catch (error) {
      console.error('Error updating expense:', error);
  
      if (error.response) {
        console.error('Response Data:', error.response.data);
        console.error('Response Status:', error.response.status);
      }
  
      message.error(t('Error updating expense'));
    }
  };

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const handleDeleteExpense = async (expenseId) => {
    try {
      const token = getToken();
      const response = await axios.delete(`${API_URL}/expense/${expenseId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (response.status === 200) {
        message.success(t('Delete Successfully'));
        fetchExpenses();
      }
    } catch (error) {
      console.error('Error deleting expense:', error);
      message.error(t('Error deleting expense'));
    }
  };
  
  const handleTabChange = async (activeKey) => {
    setActiveTabKey(activeKey);
    
    // Refetch data when switching to the expense report tab (key "2")
    if (activeKey === "2") {
      setLoading(true); // Show loading state
      await fetchExpenses();
    }
  };

  const openEditModal = (expense) => {
    setEditingExpense(expense);
    form.setFieldsValue({
      ...expense,
      date: dayjs(expense.date),
      month_year: dayjs(expense.month_year),
    });
    setIsEditModalVisible(true);
  };
  const closeEditModal = () => {
    setIsEditModalVisible(false);
    setEditingExpense(null);
    form.resetFields();
  };
  const translateCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'postoffice': return t('postoffice');
      case 'transport': return t('transport');
      case 'gas': return t('gas');
      case 'laundry': return t('laundry');
      case 'utilities': return t('utilities');
      case 'return': return t('return');
      case 'facebook': return t('Facebook');
      case 'tiktok': return t('TikTok');
      case 'others': return t('others');
      case 'commission': return t('commission');
      case 'maintenance': return t('maintenance');
          case 'renovation': 
            return t('renovation');
          case 'blanket return': 
            return t('blanket_return');
          case 'key return': 
            return t('key_return');
          case 'refund': 
            return t('refund');
          case 'travel expense': 
            return t('travel_expense');
          case 'staff meals and drinks': 
            return t('staff_meals_and_drinks');
          case 'stationery supplies': 
            return t('stationery_supplies');
          case 'stationery equipment': 
            return t('stationery_equipment');
          case 'teaching materials and equipment': 
            return t('teaching_materials_and_equipment');
          case 'it equipment': 
            return t('IT_equipment');
          case 'learning materials and equipment': 
            return t('learning_materials_and_equipment');
      default: return category;
    }
  };

  const csvHeaders = [
    { label: t('month_year'), key: 'month_year' },
    { label: t('date'), key: 'date' },
    { label: t('category_label'), key: 'category_name' },
    { label: t('details'), key: 'expense_type' },
    { label: t('amount'), key: 'amount' },
    { label: t('memo'), key: 'description' },
    { label: t('created_by'), key: 'nickname' },
  ];

  const columns = [
    {
      title: t('month_year'),
      dataIndex: 'month_year',
      key: 'month_year',
      render: (monthYear) => formatMonthYear(monthYear),
    },
    {
      title: t('วันที่จ่ายเงิน'),
      dataIndex: 'date',
      key: 'date',
      render: (dateString) => formatDate(dateString),
    },
    {
      title: t('category_label'),
      dataIndex: 'category_name',
      key: 'category_name',
      render: (category) => {
        const categoryMap = {
          'postoffice': 'postoffice',
          'transport': 'transport',
          'gas': 'gas',
          'laundry': 'laundry',
          'utilities': 'utilities',
          'return': 'return',
          'facebook': 'facebook',
          'tiktok': 'tiktok',
          'others': 'others',
          'salary': 'salary',
          'commission': 'commission',
          'maintenance': 'maintenance',
          'renovation': 'renovation',
          'blanket return': 'blanket_return',
          'key return': 'key_return',
          'refund': 'refund',
          'travel expense': 'travel_expense',
          'staff meals and drinks': 'staff_meals_and_drinks',
          'stationery supplies': 'stationery_supplies',
          'stationery equipment': 'stationery_equipment',
          'teaching materials and equipment': 'teaching_materials_and_equipment',
          'it equipment': 'IT_equipment',
          'learning materials and equipment': 'learning_materials_and_equipment',
        };
        
        return t(categoryMap[category?.toLowerCase()] || category);
      }
    },
    { 
      title: t('details'), 
      dataIndex: 'expense_type', 
      key: 'expense_type' 
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount),
    },
    { 
      title: t('memo'), 
      dataIndex: 'description', 
      key: 'description' 
    },
    {
      title: t('จ่ายออกจาก'),
      dataIndex: 'wallet',
      key: 'wallet',
      render: (wallet) => (wallet === 'cashregister' ? t('เงินในลิ้นชัก') : t('จากที่อื่น')),
    },
    {
      title: t('ระบุที่มาของเงินจ่ายออก'),
      dataIndex: 'wallet_description',
      key: 'wallet_description',
      render: (desc) => desc || '-',
    },
    {
      title: t('receipt'),
      dataIndex: 'receipt_path',
      key: 'receipt',
      render: (receipt_path) => {
        const handleFileDownload = async () => {
          try {
            if (!receipt_path) {
              message.error(t('no_receipt'));
              return;
            }
  
            const filename = receipt_path.split('/').pop();
            if (!filename) {
              message.error(t('invalid_receipt_path'));
              return;
            }
  
            const downloadUrl = `${API_URL}/expense/download/expenses/${encodeURIComponent(filename)}`;
            const response = await fetch(downloadUrl);
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
  
            window.open(downloadUrl, '_blank', 'noopener,noreferrer');
          } catch (error) {
            console.error('Error downloading file:', error);
            message.error(t('error_downloading_receipt'));
          }
        };
  
        if (!receipt_path) {
          return <span className="text-gray-500">{t('no_receipt')}</span>;
        }
  
        return (
          <Button
            type="default"
            icon={<DownloadOutlined />}
            onClick={handleFileDownload}
            className="download-button"
          >
            {t('download_receipt')}
          </Button>
        );
      },
    },
    { 
      title: t('created_by'), 
      dataIndex: 'nickname', 
      key: 'nickname' 
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (_, record) => (
        <Space size="small" className="action-buttons">
          <Button 
            type="primary"
            onClick={() => openEditModal(record)}
            icon={<EditOutlined />}
          >
          </Button>
          <Popconfirm
            title={t('คอนเฟิร์มการลบค่าใช้จ่าย')}
            description={t('กรุณาคอนเฟิร์มการลบค่าใช้จ่าย')}
            onConfirm={() => handleDeleteExpense(record.expense_id)}
            okText={t('yes')}
            cancelText={t('no')}
            okButtonProps={{ danger: true }}
            placement="topRight"
          >
            <Button 
              danger 
              icon={<DeleteOutlined />}
            >
            </Button>
          </Popconfirm>
        </Space>
      ),
    }
  ];

  // Unique month/year values for dropdown
  const uniqueMonthYears = Array.from(
    new Set(expenses.map((expense) => formatMonthYear(expense.month_year)))
  );

  // Unique category names for dropdown
  const uniqueCategories = Array.from(
    new Set(expenses.map((expense) => expense.category_name))
  );

  

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={60} className="site-layout-background">
        <Sidebar />
      </Sider>
      <Layout>
        <AntHeader className="site-layout-background" style={{ padding: 0 }}>
          <Header />
        </AntHeader>
        
        <Content style={{ margin: '16px', padding: '24px', background: '#fff', minHeight: 280 }}>
          <Tabs
            defaultActiveKey="1"
            onChange={handleTabChange} // Update the active tab key when the user switches tabs
            items={[
              {
                key: '1',
                label: t('add_expense'),  // "Add Expense" is now Tab 1
                children: <AddExpense />,
              },
              {
                key: '2',
                label: t('expense_report'),  // "Expense Report" is now Tab 2
                children: (
                  <>
                    <div style={{ marginBottom: '16px' }}>
                    <Button 
                      onClick={handleCurrentMonthClick}
                      style={{ marginRight: '16px' }}
                    >
                      {t('current_month')}
                    </Button>
                      <Select
                        placeholder={t('select_month_year')}
                        style={{ width: 250, height: '40px', marginRight: '16px' }}
                        onChange={handleMonthYearChange}
                        value={selectedMonthYear}
                        allowClear
                      >
                        {uniqueMonthYears.map((monthYear) => (
                          <Option key={monthYear} value={monthYear}>
                            {monthYear}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        placeholder={t('select_category')}
                        style={{ width: 250, height: '40px', marginRight: '16px' }}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                        allowClear
                      >
                        {uniqueCategories.map((category) => (
                          <Option key={category} value={category}>
                            {translateCategory(category)}
                          </Option>
                        ))}
                      </Select>
                      <CSVLink
                          data={filteredExpenses.map(expense => ({
                            ...expense,
                            month_year: formatMonthYear(expense.month_year),
                            date: formatDate(expense.date),
                            category_name: translateCategory(expense.category_name),
                            amount: new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(expense.amount),
                          }))}
                          headers={csvHeaders}
                          className="ant-btn ant-btn-default"
                          style={{ marginLeft: 10 }}
                          filename={`expenses_${selectedMonthYear || 'all'}.csv`}
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
                        </CSVLink>
                    </div>
                    
                    <Table
                      columns={columns}
                      dataSource={filteredExpenses}
                      rowKey="expense_id"
                      loading={loading}
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        showQuickJumper: false,
                        pageSizeOptions: ['50', '100', '150'],
                        onChange: handleTableChange,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </>
                ),
              },
              {
                key: '3',
                label: t('expense_summary'),
                children: <ExpenseLog />,
              },
            ]}
          />
        </Content>
        <Modal
            title={t('Edit Expense')}
            visible={isEditModalVisible}
            onCancel={closeEditModal}
            onOk={() => {
              form.validateFields().then(handleUpdateExpense);
            }}
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={{ wallet: wallet }}
            >
              <FormItem
                label={t('month_year')}
                name="month_year"
                rules={[{ required: true, message: t('Please select the month-year') }]}
              >
                <DatePicker
                  picker="month"
                  format="MM-YYYY"
                  style={{ width: '100%' }}
                />
              </FormItem>

              <FormItem
                label={t('วันที่จ่ายเงิน')}
                name="date"
                rules={[{ required: true, message: t('Please select the date') }]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: '100%' }}
                />
              </FormItem>

              <FormItem
                label={t('category_label')}
                name="category_name"
                rules={[{ required: true, message: t('Please select category') }]}
              >
                <Select>
                  {categories.map((category) => (
                    <Option key={category.category_id} value={category.category_name}>
                      {translateCategory(category.category_name)}  {/* Translate category */}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label={t('details')}
                name="expense_type"
                rules={[{ required: true, message: t('Please enter expense type') }]}
              >
                <Input />
              </FormItem>

              <FormItem
                label={t('amount')}
                name="amount"
                rules={[{ required: true, message: t('Please enter amount') }]}
              >
                <InputNumber min={0} precision={2} style={{ width: '100%' }} />
              </FormItem>

              <FormItem label={t('memo')} name="description">
                <Input.TextArea rows={3} />
              </FormItem>

              <FormItem
                label={t('จ่ายออกจาก')}
                name="wallet"
                rules={[{ required: true, message: t('กรุณาเลือก') }]}
              >
                <Select
                  onChange={(value) => {
                    setWallet(value); // Update the wallet state
                    form.setFieldsValue({ wallet_description: value === 'other' ? '' : null });
                  }}
                >
                  <Option value="cashregister">{t('เงินในลิ้นชัก')}</Option>
                  <Option value="other">{t('จากที่อื่น')}</Option>
                </Select>
              </FormItem>

              {wallet === 'other' && (
                <FormItem
                  label={t('ระบุที่มาของเงินจ่ายออก')}
                  name="wallet_description"
                  rules={[{ required: true, message: t('Please provide description') }]}
                >
                  <Input />
                </FormItem>
              )}
            </Form>
          </Modal>

      </Layout>
    </Layout>
  );
};

export default withRoleAccess(ExpenseReport, ['superadmin', 'admin']);

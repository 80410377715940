import React, { useState } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker, message, ConfigProvider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import th_TH from 'antd/lib/locale/th_TH';

dayjs.locale('th');

const { Option } = Select;

const AddExpense = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletType, setWalletType] = useState('cashregister'); // Default to 'cashregister'
  const [form] = Form.useForm();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleUpload = (file) => {
    setFile(file);
    setFileList([
      {
        uid: '-1',
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);
    return false;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('expense_type', values.expense_type);
    formData.append('category_id', values.category_id);
    formData.append('amount', parseFloat(values.amount.replace(/[^0-9.]/g, '')));
    formData.append('description', values.description || ''); // Optional description
    formData.append('wallet', walletType); // Add wallet type
    if (walletType === 'other') {
      formData.append('wallet_description', values.wallet_description); // Add wallet description if 'other'
    }
    formData.append('date', dayjs(values.date).format('YYYY-MM-DD'));
    formData.append('month_year', dayjs(values.month_year).format('YYYY-MM'));
    if (file) {
      formData.append('receipt', file);
    }

    const token = localStorage.getItem('token');

    try {
      setLoading(true);

      await axios.post(`${API_URL}/expense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      message.success(t('expense_added_successfully'));

      // Reset all form fields to their initial values
      form.resetFields();
      setWalletType('cashregister'); // Reset wallet type to default
      setFile(null);
      setFileList([]);
    } catch (error) {
      console.error('Error:', error);
      message.error(t('add_expense_failed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfigProvider locale={th_TH}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="month_year"
          label={t('month_year_label')}
          rules={[{ required: true, message: t('month_year_required') }]}
        >
          <DatePicker picker="month" format="MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="date"
          label={t('date_expense')}
          rules={[{ required: true, message: t('date_required') }]}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="category_id"
          label={t('category_label')}
          rules={[{ required: true, message: t('category_required') }]}
        >
          <Select placeholder={t('select_category')}>
            <Option value="1">{t('postoffice')}</Option>
            <Option value="2">{t('transport')}</Option> 
            <Option value="3">{t('gas')}</Option>
            <Option value="4">{t('laundry')}</Option>
            <Option value="5">{t('utilities')}</Option>
            <Option value="6">{t('return')}</Option>
            <Option value="7">{t('others')}</Option>
            <Option value="8">{t('facebook')}</Option> 
            <Option value="9">{t('tiktok')}</Option>
            <Option value="10">{t('salary')}</Option>
            <Option value="11">{t('commission')}</Option>
            <Option value="12">{t('maintenance')}</Option>
            <Option value="13">{t('renovation')}</Option>
            <Option value="14">{t('blanket_return')}</Option>
            <Option value="15">{t('key_return')}</Option>
            <Option value="16">{t('refund')}</Option>
            <Option value="17">{t('travel_expense')}</Option>
            <Option value="18">{t('staff_meals_and_drinks')}</Option>
            <Option value="19">{t('stationery_supplies')}</Option>
            <Option value="20">{t('stationery_equipment')}</Option>
            <Option value="21">{t('teaching_materials_and_equipment')}</Option>
            <Option value="22">{t('IT_equipment')}</Option>
            <Option value="23">{t('learning_materials_and_equipment')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="wallet"
          label={t('จ่ายออกจาก')}
          rules={[{ required: true, message: t('wallet_required') }]}
        >
          <Select
            value={walletType}
            onChange={(value) => setWalletType(value)}
          >
            <Option value="cashregister">{t('เงินในลิ้นชัก')}</Option>
            <Option value="other">{t('จากที่อื่น')}</Option>
          </Select>
        </Form.Item>

        {walletType === 'other' && (
          <Form.Item
            name="wallet_description"
            label={t('ระบุที่มาของเงินจ่ายออก')}
            rules={[{ required: true, message: t('โปรดระบุ') }]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          name="expense_type"
          label={t('details')}
          rules={[{ required: true, message: t('type_required') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label={t('amount_label')}
          rules={[{ required: true, message: t('amount_required') }]}
        >
          <Input
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9.]/g, '');
              form.setFieldsValue({ amount: numericValue });
            }}
            onBlur={(e) => {
              const numericValue = parseFloat(e.target.value);
              if (!isNaN(numericValue)) {
                const formattedValue = formatCurrency(numericValue);
                form.setFieldsValue({ amount: formattedValue });
              }
            }}
          />
        </Form.Item>

        <Form.Item name="description" label={t('memo')}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label={t('upload_receipt_label')}>
          <Upload
            beforeUpload={handleUpload}
            maxCount={1}
            accept=".pdf"
            fileList={fileList}
            onRemove={() => {
              setFile(null);
              setFileList([]);
            }}
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}>{t('upload_button')}</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit_button')}
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default withRoleAccess(AddExpense, ['superadmin', 'admin']);

import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import SuccessModal from './SuccessModal';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th'; // Import the Thai locale from date-fns
import moment from 'moment';
import 'moment/locale/th'; // Import Thai locale for moment
import 'react-datepicker/dist/react-datepicker.css';
import './CreateUserForm.css';

moment.locale('th'); // Set locale to Thai
registerLocale('th', th); 
setDefaultLocale('th');

const initialFormData = {
  prefix: '',
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'teacher',
  phone: '',
  address: '',
  bachelorDegree: '',
  massageTherapyBackground: '',
  medicalHistory: '',
  applicationFee: '',
  nationality: '',
  race: '',
  religion: '',
  otherReligion: '',
  emergencyPhoneNumber: '',
  height: '',
  weight: '',
  branch: '',
};

function CreateTeacherForm({ onClose }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatThaiDate = (date) => {
    if (!date) return '';
    const thaiYear = moment(date).year() + 543;
    return moment(date).format(`DD/MM/${thaiYear}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prevData => ({
      ...prevData,
      password: password
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const dataToSend = {
        ...formData,
        active: true,
      };
      await axios.post(`${API_URL}/users/create`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setFormData(initialFormData);
      setShowConfirmModal(false);
      setError(null);
      onClose();
    } catch (error) {
      console.error('Error creating user:', error.response.data);
      setError(error.response.data.message);
    }
  };
  

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <h2>{t('form.add_new_teacher')}</h2>
        <form onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }} className="create-user-form">
          <div className="section">
            <h3>{t('form.teacher_details')}</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="prefix">{t('name_prefix')}</label>
                <select name="prefix" value={formData.prefix} onChange={handleChange} required>
                  <option value="">{t('select_prefix')}</option>
                  <option value="Miss">{t('miss')}</option>
                  <option value="Mrs">{t('mrs')}</option>
                  <option value="Mr">{t('mr')}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="firstname">{t('form.first_name')}</label>
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  placeholder={t('form.first_name')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">{t('form.last_name')}</label>
                <input
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder={t('form.last_name')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nickname">{t('form.nickname')}</label>
                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} placeholder={t('form.nickname')} />
              </div>
              <div className="form-group">
                <label htmlFor="nationalid">{t('form.national_id')}</label>
                <input type="text" name="nationalid" value={formData.nationalid} onChange={handleChange} placeholder={t('form.national_id')} />
              </div>
            </div>
            <div className="form-row">
            <div className="form-group">
              <label htmlFor="date_of_birth">{t('dob')}</label>
              <DatePicker
      selected={formData.date_of_birth ? moment(formData.date_of_birth, 'YYYY-MM-DD').toDate() : null}
      onChange={(date) => {
        setFormData({ ...formData, date_of_birth: moment(date).format('YYYY-MM-DD') });
      }}
      dateFormat="dd/MM/yyyy"
      locale="th"
      calendarStartDay={1}
      showYearDropdown
      yearDropdownItemNumber={100}
      scrollableYearDropdown
      customInput={
        <input
          type="text"
          name="date_of_birth"
          placeholder={t('dob_placeholder')}
          value={formData.date_of_birth ? moment(formData.date_of_birth, 'YYYY-MM-DD').add(543, 'years').format('DD/MM/YYYY') : ''}
          readOnly
        />
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div style={{ margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button
            className="date-picker-nav-button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {"<"}
          </button>
          <select
            value={date.getMonth()}
            onChange={({ target: { value } }) => changeMonth(parseInt(value, 10))}
            className="date-picker-select"
          >
            {moment.months().map((month, index) => (
              <option key={month} value={index}>
                {month}
              </option>
            ))}
          </select>
          <select
            value={date.getFullYear() + 543}
            onChange={({ target: { value } }) => changeYear(parseInt(value, 10) - 543)}
            className="date-picker-select"
          >
            {Array.from({ length: 100 }, (_, i) => date.getFullYear() - 50 + i).map((year) => (
              <option key={year} value={year + 543}>
                {year + 543}
              </option>
            ))}
          </select>
          <button
            className="date-picker-nav-button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {">"}
          </button>
        </div>
      )}
    />
            </div>
              <div className="form-group">
                <label htmlFor="email">{t('form.email')}</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder={t('form.email')} required />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phone">{t('form.phone')}</label>
                <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder={t('form.phone')} />
              </div>
              <div className="form-group">
                <label htmlFor="address">{t('form.address')}</label>
                <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder={t('form.address')} />
              </div>
            </div>
 
            <div className="form-row">
            <div className="form-group">
                <label htmlFor="bachelorDegree">{t('education')}</label>
                <select
                  name="bachelorDegree"  // Correct property name
                  value={formData.bachelorDegree}
                  onChange={handleChange}
                  placeholder={t('form.bachelor_degree')}
                >
                  <option value="">{t('select')}</option>
                  <option value="below_highschool">{t('below_highschool')}</option>
                  <option value="highschool">{t('highschool')}</option>
                  <option value="bachelor_degree">{t('bachelor_degree')}</option>
                  <option value="above_bachelor_degree">{t('above_bachelor_degree')}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="branch">{t('branch')}</label>
                <select name="branch" value={formData.branch} onChange={handleChange}>
                  <option value="">{t('select_branch')}</option>
                  <option value="Bangkok">{t('bangkok')}</option>
                  <option value="Pattaya">{t('pattaya')}</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nationality">{t('nationality')}</label>
                <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} placeholder={t('nationality')} />
              </div>
              <div className="form-group">
                <label htmlFor="race">{t('race')}</label>
                <input type="text" name="race" value={formData.race} onChange={handleChange} placeholder={t('race')} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="religion">{t('religion')}</label>
                <select name="religion" value={formData.religion} onChange={handleChange}>
                  <option value="">{t('select_religion')}</option>
                  <option value="Buddhist">{t('Buddhist')}</option>
                  <option value="Christian">{t('Christian')}</option>
                  <option value="Catholic">{t('Catholic')}</option>
                  <option value="Islam">{t('Islam')}</option>
                  <option value="Others">{t('Others')}</option>
                </select>
                {formData.religion === 'Others' && (
                  <input
                    type="text"
                    name="otherReligion"
                    value={formData.otherReligion}
                    onChange={handleChange}
                    placeholder={t('specify_other_religion')}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="emergencyPhoneNumber">{t('emergency_phone_number')}</label>
                <input type="text" name="emergencyPhoneNumber" value={formData.emergencyPhoneNumber} onChange={handleChange} placeholder={t('emergency_phone_number')} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="height">{t('height')}</label>
                <input type="number" name="height" value={formData.height} onChange={handleChange} placeholder={t('height')} />
              </div>
              <div className="form-group">
                <label htmlFor="weight">{t('weight')}</label>
                <input type="number" name="weight" value={formData.weight} onChange={handleChange} placeholder={t('weight')} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="massageTherapyBackground">{t('massageTherapyBackground')}</label>
                <textarea name="massageTherapyBackground" value={formData.massageTherapyBackground} onChange={handleChange} placeholder={t('massageTherapyBackground')} />
              </div>
              <div className="form-group">
                <label htmlFor="medicalHistory">{t('medical_history')}</label>
                <textarea name="medicalHistory" value={formData.medicalHistory} onChange={handleChange} placeholder={t('medical_history')} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="password">{t('form.temporary_password')}</label>
                <input type="text" name="password" value={formData.password} onChange={handleChange} placeholder={t('form.temporary_password')} />
                <button type="button" onClick={generateRandomPassword}>{t('form.generate_password')}</button>
              </div>
            </div>
          </div>

          <button type="submit" className="submit-button">{t('form.create_teacher')}</button>
          {error && <div className="error-message">{t(error)}</div>}
        </form>
        {showConfirmModal && (
          <SuccessModal details={formData} onClose={() => setShowConfirmModal(false)} onConfirm={handleSubmit} />
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateTeacherForm, ['superadmin', 'admin']);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUser, faUserGraduate, faExclamationCircle, faFileExcel, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PasswordModal from './PasswordModal';
import EditStudentModal from './EditStudentModal';
import CreateStudentFastForm from '../CreateUserform/CreateStudentFastForm';
import '../Dashboard.css';
import moment from 'moment';
import { Modal, Button, message } from 'antd';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from "react-csv";

function EditDeleteStudent({ onCreateNewStudent, refreshData }) {
    const { t } = useTranslation();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFastFormVisible, setIsFastFormVisible] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [studentIdToDelete, setStudentIdToDelete] = useState(null);
    const [studentIdToEdit, setStudentIdToEdit] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [editBlacklist, setEditBlacklist] = useState(null);
    const [selectedBlacklist, setSelectedBlacklist] = useState('');
    const [selectedBlacklistReason, setSelectedBlacklistReason] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    const ITEMS_PER_PAGE = 100;
    const pageGroupSize = 10;
    const currentGroupIndex = Math.floor((currentPage - 1) / pageGroupSize);
    const totalGroups = Math.ceil(totalPages / pageGroupSize);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
    };

   // Move fetchStudents outside of useEffect
   const fetchStudents = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(response.data);
        setFilteredStudents(response.data);
        setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
        setLoading(false);
    } catch (error) {
        console.error('Error fetching students:', error);
        if (isTokenExpiredError(error)) {
            refreshToken()
                .then(() => {
                    fetchStudents();
                })
                .catch((err) => {
                    console.error('Token refresh failed:', err);
                    setError(t('sessionExpired'));
                });
        } else {
            setError(t('fetchStudentsError'));
            setLoading(false);
        }
    }
};

useEffect(() => {
    fetchStudents();
  }, [API_URL, t, refreshData]);


    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
    
        const filtered = students.filter(student => {
            // Safely access and convert properties to lowercase, using empty string if null/undefined
            const firstNameThai = student.firstnamethai?.toLowerCase() ?? '';
            const lastNameThai = student.lastnamethai?.toLowerCase() ?? '';
            const nationalId = student.nationalid?.toLowerCase() ?? '';
    
            return firstNameThai.includes(value) ||
                   lastNameThai.includes(value) ||
                   nationalId.includes(value);
        });
    
        setFilteredStudents(filtered);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
    };

    const handleDateFilter = () => {
        const filtered = students.filter(student => {
            const studentDate = moment(student.createdat);
            const isAfterStartDate = startDate ? studentDate.isSameOrAfter(moment(startDate), 'day') : true;
            const isBeforeEndDate = endDate ? studentDate.isSameOrBefore(moment(endDate), 'day') : true;
            return isAfterStartDate && isBeforeEndDate;
        });
        setFilteredStudents(filtered);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
    };

    const prefixTranslations = {
        'Miss': 'นางสาว',
        'Mr': 'นาย',
        'Mrs': 'นาง'
    };
    
    const educationLevels = {
        below_highschool: t('below_highschool'),
        highschool: t('highschool'),
        below_bachelor_degree: t('below_bachelor_degree'),
        bachelor_degree: t('bachelor_degree'),
        above_bachelor_degree: t('above_bachelor_degree'),
    };
    
    const headers = [
        { label: "Student ID", key: "schoolid" },
        { label: "Thai Name", key: "thaiName" },
        { label: "English Name", key: "englishName" },
        { label: "Nickname", key: "nickname" },
        { label: "National ID", key: "nationalid" },
        { label: "Phone", key: "phone" },
        { label: "Address", key: "address" },
        { label: "Email", key: "email" },
        { label: "Date of Birth", key: "dob" },
        { label: "Height", key: "height" },
        { label: "Weight", key: "weight" },
        { label: "Race", key: "race" },
        { label: "Religion", key: "religion" },
        { label: "Nationality", key: "nationality" },
        { label: "Emergency Phone", key: "emergency_phone" },
        { label: "Education", key: "education" },
        { label: "Massage Education", key: "massage_education" },
        { label: "Medical History", key: "medicalhistory" },
        { label: "Application Fee", key: "application_fee" },
        { label: "Created At", key: "createdat" },
        { label: "Updated At", key: "updatedat" },
        { label: "Status", key: "status" },
        { label: "Role", key: "role" },
        { label: "Branch", key: "branch" },
        { label: "School ID", key: "schoolid" },
        { label: "Blacklist", key: "blacklist" },
        { label: "Blacklist Reason", key: "blacklistreason" },
        { label: "Created By", key: "created_by" }
    ];
    
    const csvData = filteredStudents.map(item => ({
        studentid: item.studentid,
        thaiName: `${prefixTranslations[item.prefix] || item.prefix} ${item.firstnamethai} ${item.lastnamethai}`,
        englishName: `${item.prefix} ${item.firstnameenglish} ${item.lastnameenglish}`,
        nickname: item.nickname,
        nationalid: item.nationalid,
        phone: item.phone,
        address: item.address,
        email: item.email,
        dob: formatDate(item.date_of_birth),
        height: item.height,
        weight: item.weight,
        race: item.race,
        religion: item.religion,
        nationality: item.nationality,
        emergency_phone: item.emergency_phone_number,
        education: educationLevels[item.education] || t('unknown'),
        massage_education: item.massage_education,
        medicalhistory: item.medicalhistory,
        application_fee: item.application_fee,
        createdat: formatDate(item.createdat),
        updatedat: formatDate(item.updatedat),
        status: item.status,
        role: item.role,
        branch: item.branch,
        schoolid: item.schoolid,
        blacklist: item.blacklist,
        blacklistreason: t(item.blacklistreason || 'No Record'),
        created_by: item.created_by_nickname
    }));

    const sortedStudents = [...filteredStudents].sort((b, a) => a.schoolid.localeCompare(b.schoolid));
    const currentStudents = sortedStudents.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    const getPageRange = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);
        return { startPage, endPage };
      };

    const goToPreviousGroup = () => {
        const { startPage } = getPageRange();
        if (startPage > 1) {
            setCurrentPage(startPage - pageGroupSize);
        }
        };

        const goToNextGroup = () => {
        const { startPage } = getPageRange();
        if (startPage + pageGroupSize <= totalPages) {
            setCurrentPage(startPage + pageGroupSize);
        }
        };

        const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        };

        const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
        };

        const goToPage = (number) => {
        setCurrentPage(number);
        };

    const handleBlacklistUpdate = async (studentId) => {
        try {
            const token = localStorage.getItem('token');
            const data = {
                blacklist: selectedBlacklist === 'blacklist',
                blacklistreason: selectedBlacklistReason,
            };
            await axios.put(`${API_URL}/students/${studentId}/blacklist`, data, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setStudents(prev => prev.map(student =>
                student.studentid === studentId ? { ...student, ...data } : student
            ));

            setEditBlacklist(null); // Close the edit state after saving

            confirmAlert({
                title: t('success'),
                message: t('blacklistUpdated'),
                buttons: [{ label: 'OK', onClick: () => {} }]
            });
        } catch (error) {
            console.error('Error updating blacklist:', error);
            setError(t('updateBlacklistError'));
        }
    };


    const handleEdit = (studentId) => {
        setStudentIdToEdit(studentId);
        setIsEditModalOpen(true);
    };

    const handleDelete = (studentId) => {
        setStudentIdToDelete(studentId);
        setPasswordError('');
        setIsPasswordModalOpen(true);
    };

    const confirmDelete = async (password) => {
        setIsPasswordModalOpen(false);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError(t('noTokenFound'));
            return;
          }
      
          await axios.delete(`${API_URL}/students/${studentIdToDelete}`, {
            headers: { Authorization: `Bearer ${token}` },
            data: { password },
          });
      
          // Fetch the updated student list
          await fetchStudents();
      
          confirmAlert({
            title: t('success'),
            message: t('studentDeleted'),
            buttons: [{ label: 'OK', onClick: () => {} }],
          });
        } catch (error) {
          console.error('Error deleting student:', error);
          setError(t('deleteStudentError'));
        }
      };
      

      const saveStudent = async (updatedStudent) => {
        try {
          const token = localStorage.getItem('token');
          await axios.put(`${API_URL}/students/${updatedStudent.studentid}`, updatedStudent, {
            headers: { Authorization: `Bearer ${token}` },
          });
      
          // Fetch the updated student list
          await fetchStudents();
      
          setIsEditModalOpen(false);
      
          confirmAlert({
            title: t('success'),
            message: t('studentUpdated'),
            buttons: [{ label: 'OK', onClick: () => {} }],
          });
        } catch (error) {
          console.error('Error updating student:', error);
          setError(t('updateStudentError'));
        }
      };
      

    const viewStudentDashboard = (studentId) => {
        navigate(`/student-dashboard-admin/${studentId}`);
    };

    function calculateAge(dateOfBirth) {
        if (!dateOfBirth) return ''; // Return empty if no dateOfBirth is provided
        const birthDate = moment(dateOfBirth, 'YYYY-MM-DD'); // Assuming dateOfBirth is in 'YYYY-MM-DD' format
        const age = moment().diff(birthDate, 'years'); // Calculate age in years
        return age;
      }
      const handleNewStudent = async (newStudent) => {
        try {
          await fetchStudents();
          
          setIsFastFormVisible(false);
          
          message.success(t('studentAddedSuccessfully'));
        } catch (error) {
          console.error('Error refreshing students:', error);
          message.error(t('errorRefreshingStudents'));
        }
      };

      const showFastForm = () => {
        setIsFastFormVisible(true);
    };

    const hideFastForm = () => {
        setIsFastFormVisible(false);
      };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="adminstudent-container">
            <div className="admincontainer">
            <div className="button-group">
                <Button 
                    type="primary" 
                    onClick={onCreateNewStudent} 
                    icon={<FontAwesomeIcon icon={faUserGraduate} />}
                >
                    {t('createNewStudent')}
                </Button>
                <Button 
                        onClick={showFastForm} 
                        icon={<FontAwesomeIcon icon={faUserPlus} />}
                    >
                        {t('createStudentFast')}
                    </Button>
                    <Modal
                        title={t('createStudentFast')}
                        visible={isFastFormVisible}
                        onCancel={hideFastForm}
                        footer={null}
                        width={800}
                        destroyOnClose={true}
                    >
                        <CreateStudentFastForm 
                        onClose={hideFastForm}
                        onStudentCreated={handleNewStudent}
                        />
                    </Modal>
                </div>
                <div className="ant-filter-container">
                    <div className="ant-filter-row">
                        <span className="ant-filter-label">{t('filterByDate')}:</span>
                        <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        placeholderText={t('startDate')}
                        dateFormat="dd/MM/yyyy"
                        className="ant-datepicker"
                        />
                        <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        placeholderText={t('endDate')}
                        dateFormat="dd/MM/yyyy"
                        className="ant-datepicker"
                        />
                        <button onClick={handleDateFilter} className="ant-btn ant-btn-primary">
                        {t('applyFilters')}
                        </button> 
                        
                        <CSVLink 
                            data={csvData}
                            headers={headers}
                            filename={"students.csv"}
                            className="ant-btn ant-btn-default"
                        >
                            <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
                        </CSVLink>
                    </div>
      
                        <div className="ant-filter-row">
                            <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t('searchByNameOrNationalID')}
                            className="ant-input ant-input-search"
                            />
                        </div>
                        </div>
                        <div className="pagination-container">
                            <button 
                                onClick={goToPreviousGroup} 
                                disabled={getPageRange().startPage <= 1}
                                className="page-button"
                            >
                                {"<<"}
                            </button>

                            <button 
                                onClick={goToPreviousPage} 
                                disabled={currentPage === 1}
                                className="page-button"
                            >
                                {t('previous')}
                            </button>

                            {/* Page Numbers */}
                            {Array.from(
                                { length: getPageRange().endPage - getPageRange().startPage + 1 },
                                (_, i) => getPageRange().startPage + i
                            ).map((pageNumber) => (
                                <button
                                key={pageNumber}
                                onClick={() => goToPage(pageNumber)}
                                className={`page-button ${currentPage === pageNumber ? 'active' : ''}`}
                                >
                                {pageNumber}
                                </button>
                            ))}

                            <button 
                                onClick={goToNextPage} 
                                disabled={currentPage === totalPages}
                                className="page-button"
                            >
                                {t('next')}
                            </button>

                            <button 
                                onClick={goToNextGroup} 
                                disabled={getPageRange().endPage >= totalPages}
                                className="page-button"
                            >
                                {">>"}
                            </button>
                            </div>       
 <div className="task-list-container">
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('dashboard')}</th>
                                <th>{t('schoolid')}</th>
                                <th>{t('applydate')}</th>
                                <th>{t('name')}</th>
                                <th>{t('nickname')}</th>
                                <th>{t('nationalid')}</th>
                                <th>{t('dob')}</th>
                                <th>{t('age')}</th>
                                <th>{t('phone')}</th>
                                <th>{t('branch')}</th>
                                <th>{t('applicationFee')}</th>
                                <th>{t('createby')}</th>
                                <th>{t('blacklist')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentStudents.map((student) => (
                                <tr key={student.studentid} className={student.blacklist ? 'blacklist-row' : ''}>
                                    <td>
                                        <button onClick={() => viewStudentDashboard(student.studentid)} className="dashboard-button">
                                            <FontAwesomeIcon icon={faUser} />
                                        </button>
                                    </td>
                                    <td>{student.schoolid}</td>
                                    <td>{formatDate(student.createdat)}</td>
                                    <td>{student.prefix ? t(student.prefix.toLowerCase()) : ''} {student.firstnamethai} {student.lastnamethai}</td>
                                    <td>{student.nickname}</td>
                                    <td>{student.nationalid}</td>
                                    <td>{formatDate(student.date_of_birth)}</td>
                                    <td>{calculateAge(student.date_of_birth)} {t('year')}</td>
                                    <td>{student.phone}</td>
                                    <td>{student.branch}</td>
                                    <td>{student.application_fee} {t('baht')}</td>
                                    <td>{student.created_by_nickname}</td>
                                    <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    {student.blacklistreason ? t(student.blacklistreason) : t('No Record')}
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            <button onClick={() => handleEdit(student.studentid)} className="edit-button">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={() => handleDelete(student.studentid)} className="delete-button">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            {editBlacklist === student.studentid ? (
                                                <>
                                                    <select
                                                        value={selectedBlacklist}
                                                        onChange={(e) => setSelectedBlacklist(e.target.value)}
                                                    >
                                                        <option value="">{t('Select option')}</option>
                                                        <option value="norecord">{t('No Record')}</option>
                                                        <option value="blacklist">{t('Blacklist')}</option>
                                                        <option value="notPassed">{t('Not Passed')}</option>
                                                    </select>
                                                    {selectedBlacklist && (
                                                        <select
                                                            value={selectedBlacklistReason}
                                                            onChange={(e) => setSelectedBlacklistReason(e.target.value)}
                                                        >
                                                            <option value="">{t('Select reason')}</option>
                                                            {selectedBlacklist === 'blacklist' ? (
                                                                <>
                                                                    <option value="fighting">{t('Involved in fighting')}</option>
                                                                    <option value="drugUse">{t('Drug use or gambling')}</option>
                                                                    <option value="theft">{t('Theft')}</option>
                                                                    <option value="examFraud">{t('Exam fraud')}</option>
                                                                    <option value="longAbsence">{t('Absence for over a month')}</option>
                                                                    <option value="drop3month">{t('Drop for over 3 month')}</option>
                                                                    <option value="copyrightViolation">{t('Copyright violation')}</option>
                                                                    <option value="others">{t('Others (please specify)')}</option>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <option value="sickLeave">{t('On sick leave')}</option>
                                                                    <option value="failedAssessment">{t('Failed assessment')}</option>
                                                                </>
                                                            )}
                                                        </select>
                                                    )}
                                                    <button onClick={() => handleBlacklistUpdate(student.studentid)} className="save-blacklist-button">
                                                        {t('Save')}
                                                    </button>
                                                </>
                                            ) : (
                                                <button onClick={() => { setEditBlacklist(student.studentid); setSelectedBlacklist(''); setSelectedBlacklistReason(''); }} className="blacklist-button">
                                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onConfirm={confirmDelete}
                errorMessage={passwordError}
            />
            <EditStudentModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={saveStudent}
                studentId={studentIdToEdit}
            />
             <Modal
                title={t('createStudentFast')}
                visible={isFastFormVisible}
                onCancel={hideFastForm}
                footer={null}
                width={800}
            >
                <CreateStudentFastForm onClose={hideFastForm} />
            </Modal>
        </div>
    );
}

export default withRoleAccess(EditDeleteStudent, ['admin', 'superadmin']);

import React, { useState, useRef } from 'react';
import { Card, Avatar, Row, Col, Button, Input, message, Modal } from 'antd';
import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons'; 
import QRCode from 'qrcode.react';
import axios from 'axios';
import { toPng } from 'html-to-image'; // Import the toPng function
import './Student.css'; // Add necessary styles here

const StudentIDCard = ({ student }) => {
  const [profileImage, setProfileImage] = useState(student.photourl || 'https://via.placeholder.com/128');
  const [imageUrl, setImageUrl] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const cardRef = useRef(); // Ref to the card component

  // Handle updating the URL for the image
  const handleImageUrlSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${API_URL}/students/update-photo-url/${student.studentid}`, { photourl: imageUrl }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setProfileImage(imageUrl);
        message.success('Profile image updated successfully!');
        setIsModalVisible(false); 
      } else {
        message.error('Failed to update profile image');
      }
    } catch (error) {
      console.error('Error updating image URL:', error);
      message.error('Error updating profile image');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownload = async () => {
    if (cardRef.current) {
      try {
        const dataUrl = await toPng(cardRef.current, { cacheBust: true });
        const link = document.createElement('a');
        link.download = `${student.firstnamethai}_${student.lastnamethai}_ID_Card.png`;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Error generating image:', error);
        message.error('Failed to download student ID card.');
      }
    }
  };
  

  return (
    <div>
      <Card className="student-id-card" bordered={false} ref={cardRef}>
        <Row>
          <Col span={24} style={{ textAlign: 'center', marginBottom: '10px' }}>
            <img src="./images/logonew.jpg" alt="School Logo" className="school-logo-300" style={{ borderRadius: '50%' }} />
          </Col>

          {/* Student Image and Info */}
          <Col span={12} className="student-image-container">
            <Avatar
              size={128}
              src={profileImage} 
              className="student-avatar"
            />
            <Button 
              className="image-button"
              size="small" 
              icon={<PaperClipOutlined />} 
              style={{ position: 'absolute', top: 0, left: 0 }} 
              onClick={showModal}
            />
          </Col>

          <Col span={12} className="student-info">
          <p className="student-name-card">
              {student.firstnamethai} {student.lastnamethai}
            </p>
            <p className="student-name-card">
              ({student.nickname})
            </p>
            <p className="student-id-label">ID: {student.schoolid}</p>
          </Col>

          {/* QR Code Section */}
          <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
            <QRCode value={student.schoolid} size={100} className="qr-code" />
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button 
              onClick={handleDownload} 
              style={{ marginTop: '10px' }} 
              icon={<DownloadOutlined />} 
            />
          </Col>
        </Row>
      </Card>

      {/* Modal for image URL input */}
      <Modal
        title="Update Profile Image"
        visible={isModalVisible}
        onOk={handleImageUrlSubmit}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Cancel"
      >
        <Input 
          placeholder="Enter image URL" 
          value={imageUrl} 
          onChange={(e) => setImageUrl(e.target.value)} 
        />
      </Modal>
    </div>
  );
};

export default StudentIDCard;

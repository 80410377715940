import React, { useState, useEffect } from 'react';
import './Header.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from '../LanguageSwitcher'; 
import { useTranslation } from 'react-i18next';

const Header = ({ onSignOut }) => {
  const [username, setUsername] = useState('');
  const [showProfile, setShowProfile] = useState(false);
  const [profile, setProfile] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsername(response.data.firstname);
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    
    fetchUserProfile();
  }, [API_URL]);

  const handleSignOut = async () => {
    try {
      await axios.get(`${API_URL}/auth/signout`);
      localStorage.removeItem('token');
      if (typeof onSignOut === 'function') {
        onSignOut();
      }
      window.location.href = '/signin';
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    }
  };


  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const toggleChangePassword = () => {
    setShowChangePassword(!showChangePassword);
  };


  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match");
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_URL}/users/change-password`, {
        password,
        newPassword
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert(response.data.message);
      setShowChangePassword(false);
      handleSignOut(); // Force sign out after successful password change
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Failed to change password. Please try again.');
    }
  };
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="logo-container">
        <img src='/images/logonew.jpg' alt="Company Logo" width="50px" style={{ borderRadius: '50%' }} />
      </div>
      <div className="user-info-and-signout">
        
        <div className="user-info" onClick={toggleProfile} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faUser} className="user-icon" />
          {t('hello')}  <strong>{username}</strong>!
        </div>
        

        <button onClick={handleSignOut} className="sign-out-btn">{t('sign out')}</button>
        <LanguageSwitcher /> {/* Add the LanguageSwitcher component here */}
      </div>
      
      {showProfile && profile && (
        <div className={`profile-popup ${showProfile ? 'show' : ''}`}>
          <div className="profile-content">
            <h2>User Profile</h2>
            <img src={profile.profileImage || 'https://via.placeholder.com/100'} alt="User" className="profile-image" />
            <p><strong> {profile.firstname} {profile.lastname}</strong></p>
            <button onClick={toggleChangePassword} className="change-password-btn">Change My Password</button>
            {showChangePassword && (
              <form onSubmit={handleChangePassword} className="change-password-form">
                <label>
                  Current Password:
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </label>
                <label>
                  New Password:
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </label>
                <label>
                  Confirm New Password:
                  <input
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                  />
                </label>
                <div className="change-password-buttons">
                  <button type="submit" className="change-password-btn">Change Password</button>
                  <button type="button" className="cancel-btn" onClick={toggleChangePassword}>Cancel</button>
                </div>

              </form>
            )}
            <button onClick={toggleProfile} className="close-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Card, Row, Col, Statistic, notification, Table, Typography, Space, Button } from 'antd';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Legend } from 'recharts';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import html2canvas from 'html2canvas';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const StudentReportDashboard = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [studentsData, setStudentsData] = useState([]);
  const [certificatesData, setCertificatesData] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [testBookingLogData, setTestBookingLogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const [studentsRes, certificatesRes, testBookingLogRes, totalStudentsRes] = await Promise.all([
        axios.get(`${API_URL}/students`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/graduate-student/issued-certificates`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/report/certificate-issued-details`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/students/count`, { // Fetch total students
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
  
      setStudentsData(
        studentsRes.data.filter((student) =>
          moment(student.createdat).isBetween(startDate, endDate, 'day', '[]')
        )
      );
  
      setCertificatesData(
        certificatesRes.data.certificates.filter((cert) =>
          moment(cert.timestamp).isBetween(startDate, endDate, 'day', '[]')
        )
      );
  
      setTestBookingLogData(
        testBookingLogRes.data.filter((log) =>
          moment(log.document_issued_date).isBetween(startDate, endDate, 'day', '[]')
        )
      );
  
      // Set total students from the response
      setTotalStudents(Number(totalStudentsRes.data.total_students));
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({ message: 'Error', description: 'Failed to fetch reports data' });
    } finally {
      setLoading(false);
    }
  }, [API_URL, startDate, endDate]);
  
  

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const newStudentsCount = studentsData.length;

  const downloadPDF = async () => {
    const element = document.getElementById('testBookingTable');
    if (!element) {
      notification.error({ message: 'Error', description: 'Table not found for PDF export.' });
      return;
    }

    try {
      const canvas = await html2canvas(element, { scale: 2 });
      const imageData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('test_booking_logs.pdf');
      notification.success({ message: 'Success', description: 'PDF downloaded successfully!' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      notification.error({ message: 'Error', description: 'Failed to generate PDF.' });
    }
  };

  return (
    <div>
      {/* Date Range Filters */}
      <Card style={{ marginBottom: '24px' }}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Col span={24}>
          <Space>
            <span>From:</span>
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select start date"
                dateFormat="dd-MM-yyyy"
                className="ant-datepicker"
                style={{
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                }}
              />
            <span>To:</span>
            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Select end date"
                dateFormat="dd-MM-yyyy"
                className="ant-datepicker"
                style={{
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                }}
              />
                    <Button
              icon={<DownloadOutlined />}
              onClick={downloadPDF}
            >
              {t('Export to PDF')}
            </Button>
          </Space>
        </Col>

        </Space>
        </Card>

      {/* Student Analytics */}
      <section>
        <Title level={3}>ข้อมูลนักเรียน</Title>
        <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <Statistic
              title="จำนวนนักเรียนทั้งหมด"
              value={totalStudents}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="นักเรียนเข้าใหม่"
                value={newStudentsCount}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Card style={{ marginTop: 16, marginBottom: 16 }}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={studentsData.reduce((acc, student) => {
                const date = moment(student.createdat).format('DD/MM');
                const existing = acc.find((item) => item.date === date);
                if (existing) {
                  existing.count += 1;
                } else {
                  acc.push({ date, count: 1 });
                }
                return acc;
              }, []).sort((a, b) => moment(a.date, 'DD/MM').valueOf() - moment(b.date, 'DD/MM').valueOf())}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" name="New Students" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </section>

      {/* Certificates Analytics */}
      <section style={{ marginTop: 32 }}>
        <Title level={3}>ออกใบประกาศ</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card title="ออกใบประกาศตามคอร์สเรียน">
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={certificatesData.reduce((acc, cert) => {
                      const existing = acc.find((item) => item.name === cert.course_name);
                      if (existing) {
                        existing.value += 1;
                      } else {
                        acc.push({ name: cert.course_name, value: 1 });
                      }
                      return acc;
                    }, [])}
                    dataKey="value"
                    nameKey="name"
                    outerRadius={100}
                  >
                    {certificatesData.map((_, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="รายชื่อออกใบประกาศ">
              <Table
                dataSource={certificatesData}
                columns={[
                  {
                    title: 'Student',
                    render: (_, record) =>
                      `${record.student_firstname} ${record.student_lastname}`,
                  },
                  {
                    title: 'Course',
                    dataIndex: 'course_name',
                  },
                  {
                    title: 'Issue Date',
                    dataIndex: 'timestamp',
                    render: (date) =>
                      date
                        ? moment(date)
                            .locale('th') // Change 'th' to your desired locale
                            .format('DD MMMM YYYY')
                        : 'N/A',
                  },
                ]}
                pagination={{ pageSize: 5 }}
              />
            </Card>
          </Col>
        </Row>
      </section>

      {/* Test Booking Logs */}
      <section style={{ marginTop: 32 }}>
  <Title level={3}>ออกใบรับรอง</Title>
  <Card title="รายชื่อออกใบรับรอง">
      <Table
        dataSource={testBookingLogData}
        columns={[
            {
                title: 'Date',
                dataIndex: 'document_issued_date',
                render: (date) =>
                  date
                    ? moment(date)
                        .locale('th') // Change 'th' to your desired locale
                        .format('DD MMMM YYYY')
                    : 'N/A',
              },

          {
            title: 'Student Name',
            render: (_, record) =>
              `${record.firstnamethai || 'N/A'} ${record.lastnamethai || 'N/A'}`,
          },
          {
            title: 'Course Name',
            dataIndex: 'course_name',
          },
          {
            title: 'Unique ID',
            dataIndex: 'unique_id',
          },
        ]}
        rowKey="test_booking_id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </Card>
</section>

    </div>
  );
};

export default withRoleAccess(StudentReportDashboard, ['superadmin']);
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Form, Input, Select, DatePicker, Button, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Text } = Typography;

const TestBookingPage = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [courseSubjects, setCourseSubjects] = useState([]);
  const [subjectsForSelectedCourse, setSubjectsForSelectedCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [studentCourseInfo, setStudentCourseInfo] = useState(''); // For displaying student's course info
  const [formData, setFormData] = useState({
    student_id: '',
    course_name: '',
    subject: '',
    test_date: null,
    test_details: '',
    price: '',
    paid_status: 'unpaid',
  });
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch students and their course items
const fetchStudents = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/test/students`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setStudents(response.data || []); // Ensure payment_method is included in the response
  } catch (error) {
    message.error(t('Failed to fetch students'));
  }
}, [API_URL, t]);



// Fetch course subjects and sort by course_id on the frontend
const fetchCourseSubjects = useCallback(async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/test/course-subjects`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    
    // Ensure the data is sorted by course_id (numeric sort)
    const sortedCourses = (response.data || []).sort((a, b) => {
      return parseInt(a.course_id, 10) - parseInt(b.course_id, 10);
    });
    
    setCourseSubjects(sortedCourses); // Set the sorted data to state
  } catch (error) {
    message.error(t('Failed to fetch course subjects'));
  }
}, [API_URL, t]);


  useEffect(() => {
    fetchStudents();
    fetchCourseSubjects();
  }, [fetchStudents, fetchCourseSubjects]);

  const handleStudentChange = (value) => {
    const selectedStudent = students.find(student => student.studentid === value);
  
    if (selectedStudent && selectedStudent.items && typeof selectedStudent.items === 'string') {
  
      if (selectedStudent.items !== 'No items' && selectedStudent.items.trim() !== '') {
        const studentCourses = selectedStudent.items.split(', ').map(item => {
  
          if (typeof item !== 'string') return '';
          
          // Adjust the regex to be more flexible
          const match = item.match(/(.+) \((\d{2}-\d{2}-\d{4}) - (Paid|Unpaid)(?:\s*-\s*(.+))?\)/);
  
          if (!match) return item;
          
          const [, courseName, date, paymentStatus, paymentMethod] = match;
          
          // Map payment methods to their Thai translation
          const translatedPaymentMethod = paymentMethod === 'fullpay' ? 'จ่ายเต็ม' :
                                          paymentMethod === 'paymentplan' ? 'ผ่อน' :
                                          paymentMethod === 'scholarship' ? 'ขอทุน' : 'Unknown';
          
          // Translate and color payment status
          const translatedStatus = paymentStatus === 'Paid' ? 'จ่ายแล้ว' : 'ยังไม่ได้จ่าย';
          const statusColor = paymentStatus === 'Paid' ? 'green' : 'red';
          
          // Format the item string with color-coded payment status
          const formattedItem = `${courseName}\nDate: ${date}\nStatus: ${translatedStatus}${paymentMethod ? `\nPayment Method: ${translatedPaymentMethod}` : ''}`;
          return formattedItem;
        }).join('\n\n');
        
        // Set the course information in the state, with translated and colored status
        setStudentCourseInfo(studentCourses);
      } else {
        setStudentCourseInfo('No courses available');
      }
    } else {
      setStudentCourseInfo('No courses available');
    }
    setFormData({ ...formData, student_id: value, course_name: '' });
};



  const handleCourseChange = (value) => {
    setSelectedCourse(value);  // value will now be the course_id
    const selectedCourse = courseSubjects.find(cs => cs.course_id === value); // Find course by course_id
    const subjectsForCourse = courseSubjects.filter(cs => cs.course_id === value);
    setSubjectsForSelectedCourse(subjectsForCourse);
    setFormData({ ...formData, course_name: selectedCourse.course_name }); // Set the correct course_name in formData
  };
  

 // Handle form submission
const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const dataToSubmit = {
        ...formData,
        subject: formData.subject_id ? subjectsForSelectedCourse.find(subject => subject.subject_id === formData.subject_id).subject_title : '',
        paid_status: formData.paid_status || 'unpaid',
      };
  
      await axios.post(`${API_URL}/test/book-test`, dataToSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      message.success(t('Test booking submitted successfully'));
  
      // Clear form and reset the form data
      setFormData({
        student_id: '',
        course_name: '',
        subject: '',
        test_date: null,
        test_details: '',
        price: '',
        paid_status: 'unpaid',
      });
  
      setSelectedCourse(null);  // Reset the selected course
      setStudentCourseInfo(''); // Clear student course info
  
      // Refetch students and courses
      await fetchStudents();
      await fetchCourseSubjects();
  
    } catch (error) {
      message.error(t('Error booking test'));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      // Clear all form data
      setFormData({
        student_id: '',
        course_name: '',
        subject: '',
        test_date: null,
        test_details: '',
        price: '',
        paid_status: 'unpaid',
      });
      
      // Reset other states
      setSelectedCourse(null);
      setStudentCourseInfo('');
      setSubjectsForSelectedCourse([]);
      
      // Refetch data
      await Promise.all([
        fetchStudents(),
        fetchCourseSubjects()
      ]);
      
      message.success(t('Data refreshed successfully'));
    } catch (error) {
      message.error(t('Failed to refresh data'));
    } finally {
      setRefreshing(false);
    }
  };

  

  return (
    <div className="test-booking-container">
       <Button 
  onClick={handleRefresh}
  style={{ marginBottom: '20px' }}
  loading={refreshing}
  className="ml-2"
>
  {t('Refresh')}
</Button>

      <Form layout="vertical">
        {/* Student Selection */}
        <Form.Item label={t('student')} required>
          <Select
            showSearch
            value={formData.student_id}
            onChange={handleStudentChange}
            placeholder={t('Search by National ID or Name')}
            optionLabelProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase()) ||
              option.key.toLowerCase().includes(input.toLowerCase())
            }
          >
            {students.map((student) => (
              <Option
                key={student.nationalid}
                value={student.studentid}
                label={`${student.firstnamethai} ${student.lastnamethai}`}
              >
                {`${student.nationalid} - ${student.firstnamethai} ${student.lastnamethai}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('student_courses')}>
      <div
        style={{
          width: '100%',
          height: '300px',
          overflowY: 'auto',
          backgroundColor: '#f5f5f5',
          border: '1px solid #d9d9d9',
          borderRadius: '2px',
          padding: '4px 11px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        {studentCourseInfo ? (
          studentCourseInfo  // Will display each course item with payment_method
        ) : (
          <Text type="secondary">{t('No courses available')}</Text>
        )}
      </div>
    </Form.Item>


          <Form.Item label={t('course_test_request')} required>
  <Select
    value={selectedCourse}
    onChange={handleCourseChange}
    placeholder={t('Select Course')}
  >
    {courseSubjects.map(course => (
      <Option key={course.course_id} value={course.course_id}>  {/* Use course_id as the value */}
        ({course.course_id}) {course.course_name}
      </Option>
    ))}
  </Select>
</Form.Item>


        {/* Subjects for the selected course */}
        <Form.Item label={t('subject')} required>
          <Select
            value={formData.subject_id}
            onChange={(value) => setFormData({ ...formData, subject_id: value })}
            placeholder={t('Select Subject')}
          >
            {subjectsForSelectedCourse.map((subject) => (
              <Option key={subject.subject_id} value={subject.subject_id}>
                {subject.subject_title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Test Date */}
        <Form.Item label={t('Test Date')} required>
          <DatePicker
            onChange={(date) => setFormData({ ...formData, test_date: date })}
            format="YYYY-MM-DD"
          />
        </Form.Item>

        {/* Test Details */}
        <Form.Item label={t('Test Details')}>
          <Input.TextArea
            value={formData.test_details}
            onChange={(e) => setFormData({ ...formData, test_details: e.target.value })}
            rows={4}
          />
        </Form.Item>

        {/* Price */}
        <Form.Item label={t('price')} required>
          <Input
            type="text" 
            value={formData.price}
            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
          />
        </Form.Item>


        {/* Paid Status */}
        <Form.Item label={t('Paid Status')} required>
          <Select
            value={formData.paid_status || 'unpaid'}
            onChange={(value) => setFormData({ ...formData, paid_status: value })}
          >
            <Option value="unpaid">{t('Unpaid')}</Option>
            <Option value="paid">{t('Paid')}</Option>
          </Select>
        </Form.Item>

        <Button type="primary" onClick={handleSubmit} loading={loading}>
          {t('Book Test')}
        </Button>
      </Form>
    </div>
  );
};

export default withRoleAccess(TestBookingPage, ['admin', 'superadmin']);

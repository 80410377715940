import React, { useState, useEffect } from 'react';
import { Table, Input, Space, message, Button, Spin, notification, Tooltip, Card, Row, Col, Statistic, Select, Modal } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/th';
import ReceiptModal from './ReceiptModal'; 
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const { Option } = Select;

const PaymentReportPage = () => {
  const { t, i18n } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filters, setFilters] = useState({
    dateRange: [null, null],
    searchTerm: '',
    documentNo: '',
    invoiceNo: '',
    paymentMethod: ''
  });
  const [summaryStats, setSummaryStats] = useState({
    yearToDate: 0,
    monthToDate: 0,
    today: 0
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDateTimeWithLocale = (dateString) => {
    const date = moment(dateString).toDate(); 
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; 
    }
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, 
    };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };
  const calculateSummaryStats = (payments) => {
    const now = moment();
    const startOfYear = moment().startOf('year');
    const startOfMonth = moment().startOf('month');
    const startOfDay = moment().startOf('day');
  
    const stats = payments.reduce((acc, payment) => {
      const paymentDate = moment(payment.created_at);
      const amount = Number(payment.payment_amount);
  
      if (paymentDate.isSameOrAfter(startOfYear)) {
        acc.yearToDate += amount;
      }
      if (paymentDate.isSameOrAfter(startOfMonth)) {
        acc.monthToDate += amount;
      }
      if (paymentDate.isSameOrAfter(startOfDay)) {
        acc.today += amount;
      }
  
      return acc;
    }, { yearToDate: 0, monthToDate: 0, today: 0 });
  
    setSummaryStats(stats);
  };

  const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${API_URL}/payment/income-reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setPayments(response.data);
      setFilteredPayments(response.data);
      calculateSummaryStats(response.data); // Calculate summary stats
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment report:', error);
      notification.error({
        message: t('error'),
        description: t('fetch_payment_report_error'),
      });
      setLoading(false);
    }
  };

  const handleTabClick = () => {
    fetchPayments(); 
  };

  const applyFilters = () => {
    let filtered = [...payments];
  
    // Date Range Filter
    if (filters.dateRange[0] && filters.dateRange[1]) {
      filtered = filtered.filter(payment => {
        const paymentDate = moment(payment.created_at);
        return paymentDate.isBetween(
          moment(filters.dateRange[0]).startOf('day'),
          moment(filters.dateRange[1]).endOf('day'),
          null,
          '[]'
        );
      });
    }
  
    // Search Term Filter (Name or National ID)
    if (filters.searchTerm) {
      filtered = filtered.filter(payment => 
        (payment.student_name && payment.student_name.toLowerCase().includes(filters.searchTerm.toLowerCase())) ||
        (payment.nationalid && payment.nationalid.toString().includes(filters.searchTerm))
      );
    }
  
    // Document Number Filter
    if (filters.documentNo) {
      filtered = filtered.filter(payment => 
        payment.document_no.toLowerCase().includes(filters.documentNo.toLowerCase())
      );
    }
  
    // Invoice Number Filter
    if (filters.invoiceNo) {
      filtered = filtered.filter(payment => 
        payment.invoice_no.toLowerCase().includes(filters.invoiceNo.toLowerCase())
      );
    }
  
    // Payment Method Filter
    if (filters.paymentMethod) {
      filtered = filtered.filter(payment => 
        payment.payment_method === filters.paymentMethod
      );
    }
  
    setFilteredPayments(filtered);
  };
  
  // Update handlers for each filter
  const handleDateRangeChange = (dates) => {
    setFilters(prev => ({ ...prev, dateRange: dates }));
  };

  const handleSearch = (value, type) => {
    switch(type) {
      case 'nameOrId':
        setFilters(prev => ({ ...prev, searchTerm: value }));
        break;
      case 'documentNo':
        setFilters(prev => ({ ...prev, documentNo: value }));
        break;
      case 'invoiceNo':
        setFilters(prev => ({ ...prev, invoiceNo: value }));
        break;
      default:
        break;
    }
  };
  
  const handlePaymentMethodFilter = (method) => {
    setFilters(prev => ({ ...prev, paymentMethod: method }));
  };
  
  // Add useEffect to apply filters whenever any filter changes
  useEffect(() => {
    applyFilters();
  }, [filters, payments]); 


  const handleReceiptModalClose = () => {
    setIsReceiptModalOpen(false);
    setSelectedTransaction(null);
  };

  const openReceiptModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsReceiptModalOpen(true);
  };

  const showDeleteModal = (paymentId) => {
    Modal.confirm({
      title: t('Delete Confirmation'),
      content: t('คุณต้องการลบรายการนี้หรือไม่'),
      okText: t('delete'),
      cancelText: t('cancel'),
      okButtonProps: { danger: true },
      onOk: () => handleDeletePayment(paymentId)
    });
  };  
  

  const handleDeletePayment = async (paymentId) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/payment/${paymentId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    
    notification.success({
      message: t('success'),
      description: t('ลบการชำระเงินแล้ว ไม่สามารถย้อนกลับได้')
    });
    
    fetchPayments(); // Refresh the payments list
  } catch (error) {
    console.error('Error deleting payment:', error);
    notification.error({
      message: t('error'),
      description: t('delete_payment_error')
    });
  }
};


const csvHeaders = [
  { label: t('Student ID'), key: 'student_id' },
  { label: t('National ID'), key: 'nationalid' },
  { label: t('Student Name'), key: 'student_name' },
  { label: t('Document No.'), key: 'document_no' },
  { label: t('Items'), key: 'items' },
  { label: t('Final Amount'), key: 'final_amount' },
  { label: t('Paid Amount'), key: 'paid_amount' },
  { label: t('Credit Card Fee'), key: 'credit_card_fee' },
  { label: t('Remaining Balance'), key: 'remaining_balance' },
  { label: t('Invoice No.'), key: 'invoice_no' },
  { label: t('Payment Method'), key: 'payment_method' },
  { label: t('Memo'), key: 'memo' },
  { label: t('Created By'), key: 'created_by' },
  { label: t('Created At'), key: 'created_at' },
];

const csvData = filteredPayments.map(payment => ({
  student_id: payment.schoolid,
  nationalid: payment.nationalid,
  student_name: payment.student_name,
  document_no: payment.document_no,
  items: payment.items 
    ? payment.items.map(item => item.item_name).join(', ') 
    : t('No items'),
  final_amount: payment.final_amount,
  paid_amount: payment.payment_amount,
  credit_card_fee: payment.credit_card_fee,
  remaining_balance: payment.remaining_amount,
  invoice_no: payment.invoice_no,
  payment_method: payment.payment_method,
  memo: payment.memo,
  created_by: payment.nickname,
  created_at: formatDateTimeWithLocale(payment.created_at),
}));



  const columns = [
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('student_id'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student_name'),
      dataIndex: 'student_name',
      key: 'student_name',
      ellipsis: true,
    },
  
    {
      title: t('items'),
      key: 'items',
      render: (record) => {
        if (!record.items || record.items.length === 0) {
          return t('No items');
        }
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {record.items.map((item, index) => (
              <Tooltip key={index} title={item.item_name}>
                <div
                  style={{
                    padding: '4px 0',
                    borderBottom: index < record.items.length - 1 ? '1px solid #f0f0f0' : 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {item.item_name}
                </div>
              </Tooltip>
            ))}
          </div>
        );
      },
    },
    {
      title: t('full_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      render: (amount) => (
        <span style={{ color: 'grey', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('paid_amount'),
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (amount) => (
        <span style={{ color: 'green', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('credit_card_fee'),
      dataIndex: 'credit_card_fee',
      key: 'credit_card_fee',
      render: (amount) => (
        <span style={{ color: 'blue', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('remaining_balance'),
      dataIndex: 'remaining_amount',
      key: 'remaining_amount',
      render: (amount) => (
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('receiptNo'),
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      ellipsis: true,
    },
    {
      title: t('payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      ellipsis: true,
      render: (text) => {
        switch (text) {
          case 'cash':
            return t('cash');
          case 'qr code':
            return t('qr_code');
          case 'credit card':
            return t('credit_card');
          case 'transfer':
            return t('transfer');
          default:
            return text; 
        }
      },
    },
    {
      title: t('memo'),
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: t('created_by'),
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: t('date'),
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (date) => formatDateTimeWithLocale(date),
    },
    {
      title: t('receipt'),
      key: 'actions',
      render: (_, record) => {
        const handleFileDownload = (filename) => {
          try {
            if (!filename) {
              message.error(t('no_file_available'));
              return;
            }
        
            // Extract filename and encode it for URL safety
            const extractedFilename = filename.split('/').pop();
            if (!extractedFilename) {
              message.error(t('invalid_file_path'));
              return;
            }
        
            const downloadUrl = `${API_URL}/payment/download/${encodeURIComponent(extractedFilename)}`;
            
            // Open in new tab with security attributes
            window.open(downloadUrl, '_blank', 'noopener,noreferrer');
          } catch (error) {
            console.error('Download error:', error);
            message.error(t('download_error'));
          }
        };        
    
        return (
          <Space size="small">
            <Button 
              type="primary" 
              onClick={() => openReceiptModal(record)}
            >
              {t('receipt')}
            </Button>
            
            {record.payment_file && (
              <Button 
                type="default"
                onClick={() => handleFileDownload(record.payment_file)}
                className="ml-2"
              >
                {t('bank_slip')}
              </Button>
            )}
          </Space>
        );
      },
    },  
    {
      title: t('delete'),
      key: 'delete',
      render: (_, record) => (
        <Button
          danger
          onClick={() => showDeleteModal(record.payment_id)}
        >
          {t('delete')}
        </Button>
      ),
    },
  ];
  
  
  return (
    <div>
      <h1>{t('รายรับ')}</h1>
      <div style={{ marginBottom: 20 }}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#E6F7FF', borderColor: '#1890FF' }}>
            <Statistic
              title={t('year_to_date_income')}
              value={summaryStats.yearToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#0050B3' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#F6FFED', borderColor: '#52C41A' }}>
            <Statistic
              title={t('month_to_date_income')}
              value={summaryStats.monthToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#389E0D' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#FFF7E6', borderColor: '#FA8C16' }}>
            <Statistic
              title={t('today_income')}
              value={summaryStats.today}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#D46B08' }}
            />
          </Card>
        </Col>
      </Row>
      <DatePicker
    selectsRange={true}
    startDate={filters.dateRange[0]}
    endDate={filters.dateRange[1]}
    onChange={handleDateRangeChange}
    dateFormat="dd-MM-yyyy"
    placeholderText={t('Select Date Range')}
  />
  <Input.Search
    placeholder={t('searchByNameOrNationalID')}
    enterButton
    onSearch={(value) => handleSearch(value, 'nameOrId')}
    style={{ width: 250, marginLeft: '10px', marginRight: '10px' }}
  />
  <Input.Search
    placeholder={t('searchByDocumentNo')}
    enterButton
    onSearch={(value) => handleSearch(value, 'documentNo')}
    style={{ width: 250, marginRight: 10 }}
  />
  <Input.Search
    placeholder={t('searchByRecieptNo')}
    enterButton
    onSearch={(value) => handleSearch(value, 'invoiceNo')}
    style={{ width: 250, marginRight: 10 }}
  />
  <Select
    className='ant-input'
    onChange={handlePaymentMethodFilter}
    value={filters.paymentMethod}
  >
    <Option value="">{t('paymentMethod')}</Option>
    <Option value="cash">{t('cash')}</Option>
    <Option value="qr code">{t('qr_code')}</Option>
    <Option value="credit card">{t('credit_card')}</Option>
    <Option value="transfer">{t('transfer')}</Option>
  </Select>
          <Button onClick={handleTabClick} style={{ marginTop: 20 }}>
        {t('โหลดข้อมูล')}
      </Button>

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="payments.csv"
        >
          <Button style={{ marginLeft: 10 }}>
            <FontAwesomeIcon icon={faFileExcel}/>
            {t('exportToExcel')}
          </Button>
        </CSVLink>
      </div>

      {loading ? (
        <Spin tip={t('loading')} />
      ) : (
        <Table columns={columns} dataSource={filteredPayments} rowKey="payment_id" 
        scroll={{ x: true }}
        pagination={{
          pageSize: 100,
          showSizeChanger: false,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        />
      )}

    <ReceiptModal
      show={isReceiptModalOpen}
      onClose={handleReceiptModalClose}
      registration={selectedTransaction} 
    />


    </div>

  );
};

export default withRoleAccess(PaymentReportPage, ['superadmin', 'admin']);

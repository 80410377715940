import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Table, Card, Statistic, Row, Col, Typography, Spin, Alert, Tag } from 'antd';
import { DollarOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './Student.css';

const { Text } = Typography;

function StudentPayment() {
  const { id } = useParams(); 
  const { t } = useTranslation();
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => {
    return new Intl.NumberFormat('th-TH', {
      style: 'currency',
      currency: 'THB',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  useEffect(() => {
    const fetchBillingData = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-payment/sales-payment-reports/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBillingData(response.data);
      } catch (error) {
        console.error('Error fetching billing data:', error);
        setError(t('Error fetching billing data'));
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, [id, API_URL, t]);

  const groupByDocumentNo = (data) => {
    return data.reduce((acc, curr) => {
      const docNo = curr.document_no;
      if (!acc[docNo]) acc[docNo] = [];
      acc[docNo].push(curr);
      return acc;
    }, {});
  };

  const groupedBillingData = groupByDocumentNo(billingData);

  const rows = Object.keys(groupedBillingData).map((docNo) => {
    const rowsForDoc = groupedBillingData[docNo];
    const sortedRows = rowsForDoc.sort((a, b) => moment(b.created_at) - moment(a.created_at));
    return {
      ...sortedRows[0],
      allRows: sortedRows,
    };
  });

  const totalAmount = rows.reduce((sum, row) => sum + parseFloat(row.final_amount), 0);
  const totalPaid = billingData.reduce((sum, row) => sum + parseFloat(row.payment_amount), 0);
  const totalRemaining = rows.reduce((sum, row) => sum + parseFloat(row.remaining_amount), 0);

  const billingColumns = [
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
      width: '15%',
    },
    {
      title: t('item'),
      dataIndex: 'item_names',
      key: 'item_names',
      width: '40%',
      render: (text, record) => {
        const uniqueItemNames = [...new Set(record.allRows.map(row => row.item_names))];
        return uniqueItemNames.join(', ');
      },
    },
    {
      title: t('final_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      width: '15%',
      render: (amount) => formatPrice(amount),
    },
    {
      title: t('payment_status'),
      key: 'payment_status',
      width: '15%',
      render: (_, record) => {
        const remaining = parseFloat(record.remaining_amount);
        if (remaining <= 0) {
          return <Tag color="green">{t('Paid')}</Tag>;
        } else if (remaining < parseFloat(record.final_amount)) {
          return <Tag color="orange">{t('Partial')}</Tag>;
        } else {
          return <Tag color="red">{t('Unpaid')}</Tag>;
        }
      },
    },
    {
      title: t('remaining_balance'),
      dataIndex: 'remaining_amount',
      key: 'remaining_amount',
      width: '15%',
      render: (balance) => (
        <Text type={parseFloat(balance) > 0 ? 'danger' : 'success'} strong>
          {formatPrice(balance)}
        </Text>
      ),
    },
  ];

  const expandableConfig = {
    expandedRowRender: (record) => {
      const columns = [
        {
          title: t('receiptNo'),
          dataIndex: 'invoice_no',
          key: 'invoice_no',
          width: '20%'
        },
        {
          title: t('payment_date'),
          dataIndex: 'created_at',
          key: 'created_at',
          width: '25%',
          render: (date) => moment(date).format('DD/MM/YYYY'),
        },
        {
          title: t('payment_amount'),
          dataIndex: 'payment_amount',
          key: 'payment_amount',
          width: '25%',
          render: (amount) => formatPrice(amount),
        },
        {
          title: t('remaining_balance'),
          dataIndex: 'remaining_amount',
          key: 'remaining_amount',
          width: '30%',
          render: (balance) => (
            <Text type={parseFloat(balance) > 0 ? 'danger' : 'success'} strong>
              {formatPrice(balance)}
            </Text>
          ),
        },
      ];

      return (
        <Table
          columns={columns}
          dataSource={record.allRows}
          pagination={false}
          rowKey="payment_id"
          className="payment-details-table"
        />
      );
    },
    rowExpandable: (record) => record.allRows.length > 0,
  };

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message={error} type="error" />;

  return (
    <div className="payment-container">
      <Card>
        <Row gutter={16} style={{ marginBottom: 32 }}>
          <Col span={8}>
            <Statistic
              title={t('subtotal')}
              value={totalAmount}
              precision={2}
              prefix={<DollarOutlined />}
              suffix="THB"
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('Already Paid')}
              value={totalPaid}
              precision={2}
              prefix={<CheckCircleOutlined />}
              suffix="THB"
              valueStyle={{ color: '#3f8600' }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title={t('remaining_balance')}
              value={totalRemaining}
              precision={2}
              prefix={<ExclamationCircleOutlined />}
              suffix="THB"
              valueStyle={{ color: totalRemaining > 0 ? '#cf1322' : '#3f8600' }}
            />
          </Col>
        </Row>

        <Table
          dataSource={rows}
          columns={billingColumns}
          expandable={expandableConfig}
          rowKey="document_no"
          pagination={{ pageSize: 10, showSizeChanger: true }}
          className="payment-table"
        />
      </Card>
    </div>
  );
}

export default withRoleAccess(StudentPayment, ['admin', 'superadmin']);
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Table, DatePicker, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

const TestBooking = () => {
  const { t, i18n } = useTranslation();
  const [bookings, setBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch bookings
  const fetchBookings = useCallback(async (filterDate = null) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test/book-test`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          test_date: filterDate ? filterDate.format('YYYY-MM-DD') : null,
        },
      });
      setBookings(response.data || []);
      setCurrentPage(1); // Reset to the first page
    } catch (error) {
      message.error(t('Failed to fetch bookings'));
    } finally {
      setLoading(false);
    }
  }, [API_URL, t]);
  

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  // Update Paid Status
  const updatePaidStatus = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/test/update-paid-status/${id}`, { paid_status: 'paid' }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success(t('Paid status updated successfully'));
      fetchBookings(); // Refresh the bookings list
    } catch (error) {
      console.error('Error updating paid status:', error);
      message.error(t('Error updating paid status'));
    }
  };

  // CSV Export Setup
  const csvHeaders = [
    { label: t('Test Date'), key: 'test_date' },
    { label: t('student'), key: 'student_namethai' },
    { label: t('nickname'), key: 'student_nickname' },
    { label: t('course'), key: 'course_name' },
    { label: t('subject'), key: 'subject' },
    { label: t('price'), key: 'price' },
    { label: t('details'), key: 'test_details' },
    { label: t('Paid Status'), key: 'paid_status' },
    { label: t('created_by'), key: 'created_by' },
  ];

  const csvData = bookings.map((booking) => ({
    test_date: moment(booking.test_date).format('YYYY-MM-DD'),
    student_namethai: `${booking.student_firstnamethai} ${booking.student_lastnamethai}`,
    student_nickname: booking.student_nickname,
    course_name: booking.course_name,
    subject: booking.subject,
    price: booking.price,
    test_details: booking.test_details,
    paid_status: booking.paid_status,
    created_by: `${booking.created_by_firstname}`,
  }));

  // Table Columns
  const columns = [
    {
      title: t('Test Date'),
      dataIndex: 'test_date',
      key: 'test_date',
      render: (date) => {
        if (!date) return 'N/A';
        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(date));
      },
    },
    {
      title: t('student'),
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text, record) => `${record.student_firstnamethai} ${record.student_lastnamethai}`,
    },
    {
      title: t('nickname'),
      dataIndex: 'student_nickname',
      key: 'nickname',
    },
    {
      title: t('course'),
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: t('details'),
      dataIndex: 'test_details',
      key: 'test_details',
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
    },

    {
      title: t('Paid Status'),
      key: 'update',
      render: (_, record) => (
        record.paid_status === 'unpaid' ? (
          <Button onClick={() => updatePaidStatus(record.id)}>
            {t('Mark as Paid')}
          </Button>
        ) : (
          <span>{t('Already Paid')}</span>
        )
      ),
    },
    {
      title: t('created_by'),
      dataIndex: 'created_by_nickname',
      key: 'created_by_nickname',
    },
  ];

  return (
    <div className="test-booking-container">
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* DatePicker */}
        <DatePicker
          value={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
            fetchBookings(date);
          }}
          format="YYYY-MM-DD"
          placeholder={t('Select Date')}
          style={{ height: '40px' }}
        />
        {/* Button for Today's Bookings */}
        <Button onClick={() => fetchBookings(moment())} style={{ height: '40px' }}>
          {t('Today')}
        </Button>
        {/* Export CSV Button */}
        <Button>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename="test_bookings.csv"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faFileExcel} />
            {t('exportToExcel')}
          </CSVLink>
        </Button>
      </div>
      {/* Test Bookings Table */}
      <Table
          dataSource={bookings}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: bookings.length,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '150'],
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
    </div>
  );
};

export default withRoleAccess(TestBooking, ['admin', 'superadmin', 'teacher']);

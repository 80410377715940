import axios from 'axios';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL;

export const getTasks = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    const tasks = response.data.map(task => ({
      ...task,
      start: moment.utc(task.start).local().format(),
      end: moment.utc(task.end).local().format(),
    }));
    return tasks;
  } catch (error) {
    console.error('Error in getTasks:', error);
    throw error;
  }
};

export const createTask = async (taskDetails) => {
    try {
      const token = localStorage.getItem('token');
      const taskDetailsUTC = {
        ...taskDetails,
        start: moment.tz(taskDetails.start, 'Asia/Bangkok').utc().format(),
        end: moment.tz(taskDetails.end, 'Asia/Bangkok').utc().format(),
      };
      const response = await axios.post(`${API_URL}/events`, taskDetailsUTC, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error in createTask:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
      throw error;
    }
  };

export const updateTask = async (taskId, updatedTaskDetails) => {
  try {
    const token = localStorage.getItem('token');

    // Ensure start and end are in Bangkok timezone
    const { start, end, ...restDetails } = updatedTaskDetails;
    const startBangkok = moment(start).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const endBangkok = moment(end).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');

    // Include the Bangkok timezone times in the update details
    const taskUpdateData = {
      ...restDetails,
      start: startBangkok,
      end: endBangkok,
    };


    const response = await axios.put(`${API_URL}/events/${taskId}`, taskUpdateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in updateTask:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error.response?.data?.error || error;
  }
};

export const deleteTask = async (taskId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${API_URL}/events/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in deleteTask:', error);
    console.error('Error response:', error.response?.data);
    throw error.response?.data?.error || error;
  }
};
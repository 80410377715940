import React, { useState, useEffect } from 'react';
import { Table, Button, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

function StudentAttendanceLog() {
  const { t } = useTranslation();
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [pageSize, setPageSize] = useState(50); // State for page size
  const [startDate, endDate] = dateRange;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAttendanceLog = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedData = response.data.map((record) => ({
          ...record,
          timestamp: moment(record.timestamp),
        }));
        setAttendanceData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching attendance log:', error);
        setError('Failed to fetch attendance log');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceLog();
  }, [API_URL]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      const [startDate, endDate] = dates;
      const filtered = attendanceData.filter((record) =>
        record.timestamp.isBetween(
          moment(startDate).startOf('day'),
          moment(endDate).endOf('day'),
          null,
          '[]'
        )
      );
      setFilteredData(filtered);
      setCurrentPage(1); // Reset to the first page after filtering
    } else {
      setFilteredData(attendanceData);
    }
  };

  const handleReset = () => {
    setDateRange([null, null]);
    setFilteredData(attendanceData);
    setCurrentPage(1); // Reset to the first page
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
  };

  const csvHeaders = [
    { label: t('classid'), key: 'eventid' },
    { label: t('date'), key: 'date' },
    { label: t('time'), key: 'time' },
    { label: t('studentid'), key: 'schoolid' },
    { label: t('student_name'), key: 'student_name' },
    { label: t('nationalid'), key: 'nationalid' },
    { label: t('subject'), key: 'attendance_title' },
    { label: t('description'), key: 'attendance_description' },
    { label: t('teacher'), key: 'teacher' },
  ];

  const csvData = filteredData.map((record) => ({
    eventid: record.eventid,
    date: record.timestamp.format('YYYY-MM-DD'),
    time: record.timestamp.format('HH:mm'),
    schoolid: record.schoolid,
    student_name: `${record.firstnamethai} ${record.lastnamethai}`,
    nationalid: record.nationalid,
    attendance_title: record.attendance_title,
    attendance_description: record.attendance_description,
    teacher: `${record.firstname} ${record.lastname}`,
  }));

  const columns = [
    {
      title: t('classid'),
      dataIndex: 'eventid',
      key: 'eventid',
    },
    {
      title: t('date'),
      dataIndex: 'timestamp',
      key: 'date',
      render: (timestamp) => timestamp.format('YYYY-MM-DD'),
    },
    {
      title: t('time'),
      dataIndex: 'timestamp',
      key: 'time',
      render: (timestamp) => timestamp.format('HH:mm'),
    },
    {
      title: t('studentid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student_name'),
      key: 'student_name',
      render: (record) => `${record.firstnamethai} ${record.lastnamethai}`,
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('subject'),
      dataIndex: 'attendance_title',
      key: 'attendance_title',
    },
    {
      title: t('description'),
      dataIndex: 'attendance_description',
      key: 'attendance_description',
    },
    {
      title: t('teacher'),
      key: 'teacher',
      render: (record) => `${record.firstname} ${record.lastname}`,
    },
  ];

  if (loading) return <Spin tip={t('loading')} />;
  if (error) return <p>{error}</p>;

  return (
    <div className="student-attendance-log">
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
        <DatePicker
          className="ant-datepicker"
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => handleDateRangeChange(update)}
          isClearable={true}
          placeholderText={t('Select date range')}
          dateFormat="yyyy-MM-dd"
        />
        <Button type="primary" onClick={handleReset} style={{ marginLeft: 10 }}>
          {t('reset')}
        </Button>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="student_attendance_log.csv"
          className="ant-btn ant-btn-default"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
        </CSVLink>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="eventid"
        scroll={{ x: true }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: filteredData.length,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['50', '100', '150'],
          onChange: handleTableChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  );
}

export default withRoleAccess(StudentAttendanceLog, ['superadmin', 'admin']);

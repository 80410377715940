import React from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, Button, message } from 'antd';
import './CreateUserForm.css';

const { Option } = Select;

function CreateStudentFastForm({ onClose, onStudentCreated }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (values) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/students`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      message.success(t('studentCreated'));
      
      // Ensure we have data before calling the callback
      if (response.data && onStudentCreated) {
        await onStudentCreated(response.data);
      }
      
      form.resetFields();
      onClose();
    } catch (error) {
      console.error('Error creating student:', error.response?.data || error.message);
      message.error(t('errorCreatingStudent'));
    }
  };

  return (
    <Form 
      form={form} 
      onFinish={handleSubmit} 
      layout="vertical"
      validateTrigger="onBlur"
    >
      <Form.Item 
        name="prefix" 
        label={t('name_prefix')} 
        rules={[{ required: true, message: t('required_field') }]}
      >
        <Select placeholder={t('select_prefix')}>
          <Option value="Miss">{t('miss')}</Option>
          <Option value="Mrs">{t('mrs')}</Option>
          <Option value="Mr">{t('mr')}</Option>
        </Select>
      </Form.Item>
      
      <Form.Item
        name="firstnamethai"
        label={t('first_name_thai')}
        rules={[{ required: true, message: t('required_field') }]}
      >
        <Input placeholder={t('first_name_thai')} />
      </Form.Item>
      
      <Form.Item
        name="lastnamethai"
        label={t('last_name_thai')}
        rules={[{ required: true, message: t('required_field') }]}
      >
        <Input placeholder={t('last_name_thai')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('create_student')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRoleAccess(CreateStudentFastForm, ['admin', 'superadmin']);
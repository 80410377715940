import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import '../Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const UserControlPage = () => {
    const { t, i18n } = useTranslation();
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [activeCounts, setActiveCounts] = useState({
        superadmin: { total: 0, active: 0 },
        admin: { total: 0, active: 0 },
        teacher: { total: 0, active: 0 },
        student: { total: 0, active: 0 },
        total: { total: 0, active: 0 }
    }); 
    const API_URL = process.env.REACT_APP_API_URL;

    const MAX_ACTIVE_USERS = {
        superadmin: 4,
        admin: 7,
        teacher: 10,
        student: 0,
    };

    const handleShowAlert = (message) => {
        confirmAlert({
            title: t('alert'),
            message: message,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {},
                },
            ],
        });
    };

    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) {
            return t('neversignin');
        }

        const date = new Date(dateTimeString);
        if (isNaN(date.getTime())) {
            return t('neversignin');
        }

        const options = { 
            day: '2-digit', 
            month: 'short', 
            year: 'numeric'
        };

        const formattedDate = new Intl.DateTimeFormat(i18n.language || 'en-UK', options).format(date).toUpperCase();

        // Calculate the difference in days
        const today = new Date();
        const diffTime = Math.abs(today - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        return `${formattedDate} (${diffDays} ${t('daysAgo')})`;
    };

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const [usersResponse, lastSigninResponse] = await Promise.all([
                axios.get(`${API_URL}/users`, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${API_URL}/users/last-signin`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
            ]);
    
            const lastSigninMap = lastSigninResponse.data.reduce((acc, curr) => {
                acc[curr.user_id] = curr.last_signin;
                return acc;
            }, {});
    
            const usersWithLastSignin = usersResponse.data.map(user => ({
                ...user,
                lastSignin: lastSigninMap[user.userid] || 'Never'
            }));
    
            const sortedUsers = usersWithLastSignin.sort((a, b) => a.userid - b.userid);
            setUsers(sortedUsers);
            setFilteredUsers(sortedUsers);
    
            calculateCounts(sortedUsers); // Calculate total and active counts
        } catch (error) {
            console.error('Error fetching users:', error);
            handleTokenError(error);
        }
    };

    const calculateCounts = (users) => {
        const counts = {
            superadmin: { total: 0, active: 0 },
            admin: { total: 0, active: 0 },
            teacher: { total: 0, active: 0 },
            student: { total: 0, active: 0 },
            total: { total: 0, active: 0 }
        };
    
        users.forEach(user => {
            if (counts.hasOwnProperty(user.role)) {
                counts[user.role].total += 1;
                if (user.status === 'active') {
                    counts[user.role].active += 1;
                }
            }
            counts.total.total += 1;
            if (user.status === 'active') {
                counts.total.active += 1;
            }
        });
    
        setActiveCounts(counts);
    };
    

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const results = users.filter(user =>
            `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(results);
    }, [searchTerm, users]);

    const updateUserStatus = async (userId, status, role) => {
        if (status === 'active' && activeCounts[role].active >= MAX_ACTIVE_USERS[role]) {
            handleShowAlert(t('maxLimitReached', { role: t(role), limit: MAX_ACTIVE_USERS[role] }));
            return;
        }
        
        confirmAlert({
            title: t('confirmStatusChange'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            await axios.put(`${API_URL}/users/${userId}/status`, { status }, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            fetchUsers();
                        } catch (error) {
                            console.error('Error updating user status:', error);
                            handleTokenError(error);
                        }
                    }
                },
                {
                    label: t('no')
                }
            ]
        });
    };

    const resetPassword = async (userId) => {
        confirmAlert({
            title: t('confirmResetPassword'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            const response = await axios.put(`${API_URL}/users/${userId}/reset-password`, {}, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            confirmAlert({
                                title: t('temporaryPasswordTitle'),
                                message: t('temporaryPassword', { userId, tempPassword: response.data.tempPassword }),
                                buttons: [
                                    {
                                        label: 'OK',
                                        onClick: () => {}
                                    }
                                ]
                            });
                        } catch (error) {
                            console.error('Error resetting password:', error);
                            handleTokenError(error);
                        }
                    }
                },
                {
                    label: t('no')
                }
            ]
        });
    };
    
    const handleTokenError = (error) => {
        if (isTokenExpiredError(error)) {
            refreshToken().then(() => {
                fetchUsers();
            }).catch(err => {
                console.error('Token refresh failed:', err);
            });
        }
    };

    const isTokenExpiredError = (error) => {
        return error.response && error.response.status === 401;
    };

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
        const newAccessToken = response.data.accessToken;
        localStorage.setItem('token', newAccessToken);
    };

    const getRoleClassName = (role) => {
        switch(role) {
            case 'superadmin':
                return 'role-superadmin';
            case 'admin':
                return 'role-admin';
            case 'manager':
                return 'role-manager';
            case 'teacher':
                return 'role-teacher';
            case 'student':
                return 'role-student';
            case 'sales':
                return 'role-sales';
            case 'user':
                return 'role-user';
            default:
                return '';
        }
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div className="dashboard-content">
                <h1>{t('userControlPage')}</h1>
            
            <div className="">
                <div className="warning">
                    <p><FontAwesomeIcon icon={faExclamationCircle} /> <strong>{t('warning')}:</strong> {t('warningMessage')}</p>
                </div>
                <div className="role-counts">
                    {Object.keys(MAX_ACTIVE_USERS).map(role => (
                        <div className="role-count-box" key={role}>
                            <strong>{t(role)}:</strong> <br/>
                            {t('maxUser')}: {MAX_ACTIVE_USERS[role]} <br/>
                            {t('activeUser')}: {activeCounts[role].active} <br/>
                            {t('inactiveUser')}: {activeCounts[role].total - activeCounts[role].active}
                        </div>
                    ))}
                </div>

                <div className="search-container">
                    <input
                        type="text"
                        placeholder={t('searchPlaceholder')}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
                <div className="task-list-container">
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('userId')}</th>
                                <th>{t('username')}</th>
                                <th>{t('email')}</th>
                                <th>{t('userRole')}</th>
                                <th>{t('lastSignin')}</th>
                                <th>{t('status')}</th>
                                <th>{t('resetPassword')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user.userid}>
                                    <td>{user.userid}</td>
                                    <td>{user.firstname} {user.lastname}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <span className={getRoleClassName(user.role)}>{user.role}</span>
                                    </td>
                                    <td>{formatDateTime(user.lastSignin)}</td>
                                    <td>
                                        <select
                                            value={user.status || ''}  // Ensure value is never null or undefined
                                            onChange={e => updateUserStatus(user.userid, e.target.value, user.role)}
                                            className={user.status === 'active' ? 'select-active' : 'select-inactive'}
                                        >
                                            <option value="active">{t('active')}</option>
                                            <option value="inactive">{t('inactive')}</option>
                                        </select>
                                    </td>
                                    <td>
                                        {user.userid !== 1 && (
                                            <button className="reset-button" onClick={() => resetPassword(user.userid)}>
                                                <FontAwesomeIcon icon={faExclamationCircle} /> {t('resetPassword')}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div></div>
    );
};

export default withRoleAccess(UserControlPage, ['superadmin', 'admin']);

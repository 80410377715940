import React, { useState, useEffect, useCallback } from 'react';
import withRoleAccess from '../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import DailySalesDashboard from './Reports/DailySalesDashboard';
import NetProfitDashboard from './Reports/NetProfitDashboard';
import InventoryReportDashboard from './Reports/InventoryReportDashboard';
import SalesTransactionDashboard from './Reports/SalesTransactionDashboard';
import StudentReportDashboard from './Reports/StudentReportDashboard';
import { Card, Row, Col, Spin, notification, Tabs, Statistic, Table, Select, Button, Typography } from 'antd';
import { DownloadOutlined, DollarCircleOutlined, ShoppingCartOutlined, CreditCardOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';


const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [salesSummary, setSalesSummary] = useState([]);
  const [incomeSummary, setIncomeSummary] = useState([]);
  const [inventorySummary, setInventorySummary] = useState([]);
  const [loadingSalesIncome, setLoadingSalesIncome] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [expenseSummary, setExpenseSummary] = useState([]);
  const [loadingExpense, setLoadingExpense] = useState(false);
  const [selectedInventoryYear, setSelectedInventoryYear] = useState(moment().year());
  const [selectedInventoryMonth, setSelectedInventoryMonth] = useState(moment().month() + 1);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchSalesIncomeData = useCallback(async () => {
    setLoadingSalesIncome(true);
    try {
      const token = localStorage.getItem('token');
      const [responseSales, responseIncome] = await Promise.all([
        axios.get(`${API_URL}/report/sales-summary`, {
          params: { year: moment().year() },
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`${API_URL}/report/income-summary`, {
          params: { year: moment().year() },
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      setSalesSummary(processMonthlySummary(responseSales.data));
      setIncomeSummary(processMonthlySummary(responseIncome.data));
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({
        message: 'Error fetching data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingSalesIncome(false);
    }
  }, [API_URL]);

  const fetchInventoryData = useCallback(async () => {
    setLoadingInventory(true);
    try {
      const token = localStorage.getItem('token');
      const responseInventory = await axios.get(`${API_URL}/report/inventory-summary`, {
        params: { year: selectedInventoryYear },
        headers: { Authorization: `Bearer ${token}` }
      });
      const filteredData = responseInventory.data.filter(item => 
        parseInt(item.month, 10) === selectedInventoryMonth
      );
      setInventorySummary(processInventoryData(filteredData));
    } catch (error) {
      console.error('Error fetching inventory data:', error);
      notification.error({
        message: 'Error fetching inventory data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingInventory(false);
    }
  }, [API_URL, selectedInventoryYear, selectedInventoryMonth]);

  useEffect(() => {
    fetchSalesIncomeData();
  }, [fetchSalesIncomeData]);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  const processMonthlySummary = (data) => {
    const monthlySummary = data.reduce((acc, item) => {
      const monthKey = `${item.year}-${item.month}`;
      if (!acc[monthKey]) {
        acc[monthKey] = {
          year: item.year,
          month: item.month,
          total: 0,
          count: 0,
        };
      }
      acc[monthKey].total += parseFloat(item.total_sales || item.total_income);
      acc[monthKey].count += parseInt(item.transaction_count || item.payment_count);
      return acc;
    }, {});
    return Object.values(monthlySummary).sort((a, b) => (a.year === b.year ? a.month - b.month : a.year - b.year));
  };

  const processInventoryData = (data) => {
    const grouped = data.reduce((acc, item) => {
      if (!acc[item.inventory_id]) {
        acc[item.inventory_id] = {
          item_name: item.item_name,
          category: item.category,
          total_quantity: 0,
          total_sales: 0,
        };
      }
      const quantity = parseInt(item.total_quantity, 10);  // Use total_quantity from the API
      acc[item.inventory_id].total_quantity += !isNaN(quantity) ? quantity : 0;
      acc[item.inventory_id].total_sales += parseFloat(item.total_price || 0);  // Use total_price from the API
      return acc;
    }, {});
    return Object.values(grouped);
  };
  

  const calculateTotals = (data) => {
    const currentMonth = moment().month() + 1;
    const currentDate = moment().date();
    const currentYear = moment().year();

    const ytd = data.reduce((sum, item) => sum + item.total, 0);
    const mtd = data
      .filter(item => item.month === currentMonth && item.year === currentYear)
      .reduce((sum, item) => sum + item.total, 0);
    const today = data
      .filter(item => item.year === currentYear && item.month === currentMonth && item.day === currentDate)
      .reduce((sum, item) => sum + item.total, 0);
    return { ytd, mtd, today };
  };
  const fetchExpenseData = useCallback(async () => {
    setLoadingExpense(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/report/expense-summary`, {
        params: { year: moment().year() },
        headers: { Authorization: `Bearer ${token}` }
      });
      setExpenseSummary(response.data);
    } catch (error) {
      console.error('Error fetching expense data:', error);
      notification.error({
        message: 'Error fetching expense data',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoadingExpense(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchExpenseData();
  }, [fetchExpenseData]);



  const formatCurrency = (value) => {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value);
  };

  const handleYearChange = (value) => {
    setSelectedInventoryYear(value);
  };

  const handleMonthChange = (value) => {
    setSelectedInventoryMonth(value);
  };

  const salesColumns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
      render: (month) => moment().month(month - 1).format('MMMM'),
    },
    {
      title: t('Total_sales'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => formatCurrency(value),
    },
    {
      title: t('Transaction Count'),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  const incomeColumns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
      render: (month) => moment().month(month - 1).format('MMMM'),
    },
    {
      title: t('income'),
      dataIndex: 'total',
      key: 'total',
      render: (value) => formatCurrency(value),
    },
    {
      title: t('Transaction Count'),
      dataIndex: 'count',
      key: 'count',
    },
  ];

  const inventoryColumns = [
    {
      title: t('Item Name'),
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('Total Quantity Sold'),
      dataIndex: 'total_quantity',
      key: 'total_quantity',
    },
    {
      title: t('Total_sales'),
      dataIndex: 'total_sales',
      key: 'total_sales',
      render: (value) => formatCurrency(value),
    },
  ];

  const expenseColumns = [
    {
      title: t('month'),
      dataIndex: 'month_year',
      key: 'month_year',
      render: (monthYear) => moment(monthYear).format('MMMM YYYY'),
    },
    {
      title: t('total_expenses_by_month'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value) => formatCurrency(value),
    },
  ];

  const salesTotals = calculateTotals(salesSummary);
  const incomeTotals = calculateTotals(incomeSummary);

  const ExportButton = ({ data, filename }) => (
    <CSVLink data={data} filename={filename}>
      <Button icon={<DownloadOutlined />} style={{ marginBottom: 16 }}>
      {t('export_to_csv')}
      </Button>
    </CSVLink>
  );


  return (
    <div className="reports-container">
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('summary_report')} key="1">
          <Row gutter={[16, 24]}>
              <Col span={8}>
                <Card>
                  <Statistic 
                    title={t('year_to_date_sales')}
                    value={formatCurrency(salesTotals.ytd)}
                    prefix={<ShoppingCartOutlined />}
                    valueStyle={{ color: '#52c41a' }}  // Ant Design's green color
                  />
                  <div className="icon-wrapper" style={{ 
                    position: 'absolute', 
                    right: 24, 
                    top: 24,
                    fontSize: 24,
                    padding: 16,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(82, 196, 26, 0.1)'  // Light green background
                  }}>
                    <ShoppingCartOutlined style={{ color: '#52c41a' }} />
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic 
                    title={t('year_to_date_income')}
                    value={formatCurrency(incomeTotals.ytd)}
                    prefix={<DollarCircleOutlined />}
                    valueStyle={{ color: '#1890ff' }}  // Ant Design's blue color
                  />
                  <div className="icon-wrapper" style={{ 
                    position: 'absolute', 
                    right: 24, 
                    top: 24,
                    fontSize: 24,
                    padding: 16,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(24, 144, 255, 0.1)'  // Light blue background
                  }}>
                    <DollarCircleOutlined style={{ color: '#1890ff' }} />
                  </div>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic 
                    title={t('year_to_date_expenses')}
                    value={formatCurrency(expenseSummary.reduce((sum, item) => sum + Number(item.total_amount), 0))}
                    prefix={<CreditCardOutlined />}
                    valueStyle={{ color: '#f5222d' }}  // Ant Design's red color
                  />
                  <div className="icon-wrapper" style={{ 
                    position: 'absolute', 
                    right: 24, 
                    top: 24,
                    fontSize: 24,
                    padding: 16,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(245, 34, 45, 0.1)'  // Light red background
                  }}>
                    <CreditCardOutlined style={{ color: '#f5222d' }} />
                  </div>
                </Card>
              </Col>
      </Row>

      {/* Sales Report Section */}
      <div style={{ marginTop: '24px' }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Title level={4}>{t('sales_report')}</Title>
          </Col>
          <Col>
            <ExportButton 
              data={salesSummary.map(item => ({
                Month: moment().month(item.month - 1).format('MMMM'),
                'Total Sales': item.total,
                'Transaction Count': item.count
              }))} 
              filename="sales_summary.csv" 
            />
          </Col>
        </Row>
        <Spin spinning={loadingSalesIncome}>
          <Table 
            dataSource={salesSummary} 
            columns={salesColumns}
            pagination={false}
            summary={(pageData) => {
              const totalSales = pageData.reduce((sum, item) => sum + Number(item.total), 0);
              const totalTransactions = pageData.reduce((sum, item) => sum + Number(item.count), 0);
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatCurrency(totalSales)}</Table.Summary.Cell>
                  <Table.Summary.Cell>{totalTransactions}</Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Spin>
      </div>

      {/* Income Summary Section */}
      <div style={{ marginTop: '24px' }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Title level={4}>{t('Income Summary')}</Title>
          </Col>
          <Col>
            <ExportButton 
              data={incomeSummary.map(item => ({
                Month: moment().month(item.month - 1).format('MMMM'),
                'Total Income': item.total,
                'Payment Count': item.count
              }))} 
              filename="income_summary.csv" 
            />
          </Col>
        </Row>
        <Spin spinning={loadingSalesIncome}>
          <Table 
            dataSource={incomeSummary} 
            columns={incomeColumns}
            pagination={false}
            summary={(pageData) => {
              const totalIncome = pageData.reduce((sum, item) => sum + Number(item.total), 0);
              const totalPayments = pageData.reduce((sum, item) => sum + Number(item.count), 0);
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatCurrency(totalIncome)}</Table.Summary.Cell>
                  <Table.Summary.Cell>{totalPayments}</Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Spin>
      </div>

      {/* Inventory Summary Section */}
      <div style={{ marginTop: '24px' }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Title level={4}>{t('Inventory Summary')}</Title>
          </Col>
          <Col>
            <Row gutter={16}>
              <Col>
                <Select value={selectedInventoryYear} onChange={handleYearChange} style={{ width: 120 }}>
                  {[...Array(5)].map((_, i) => {
                    const year = moment().subtract(i, 'years').year();
                    return <Option key={year} value={year}>{year}</Option>;
                  })}
                </Select>
              </Col>
              <Col>
                <Select value={selectedInventoryMonth} onChange={handleMonthChange} style={{ width: 120 }}>
                  {[...Array(12)].map((_, i) => (
                    <Option key={i + 1} value={i + 1}>
                      {moment().month(i).format('MMMM')}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <ExportButton 
                  data={inventorySummary.map(item => ({
                    'Item Name': item.item_name,
                    Category: item.category,
                    'Total Quantity Sold': item.total_quantity,
                    'Total Sales': item.total_sales
                  }))} 
                  filename="inventory_summary.csv" 
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Spin spinning={loadingInventory}>
          <Table 
            dataSource={inventorySummary} 
            columns={inventoryColumns}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 400 }}
            summary={(pageData) => {
              const totalQuantity = pageData.reduce((sum, item) => sum + Number(item.total_quantity), 0);
              const totalSales = pageData.reduce((sum, item) => sum + Number(item.total_sales), 0);
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>{totalQuantity}</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatCurrency(totalSales)}</Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Spin>
      </div>

      {/* Expense Report Section */}
      <div style={{ marginTop: '24px' }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Title level={4}>{t('expense_report')}</Title>
          </Col>
          <Col>
            <ExportButton 
              data={expenseSummary.map(item => ({
                Month: moment(item.month_year).format('MMMM YYYY'),
                'Total Expense': item.total_amount,
                'Transaction Count': item.transaction_count
              }))} 
              filename="expense_summary.csv" 
            />
          </Col>
        </Row>
        <Spin spinning={loadingExpense}>
          <Table 
            dataSource={expenseSummary} 
            columns={expenseColumns}
            pagination={false}
            summary={(pageData) => {
              const totalExpense = pageData.reduce((sum, item) => sum + Number(item.total_amount), 0);
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>{t('total')}</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatCurrency(totalExpense)}</Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Spin>
      </div>

      </TabPane>
          <TabPane tab={t('salesreportsummary')} key="2">
          <SalesTransactionDashboard />
        </TabPane>

          <TabPane tab={t('incomereportsummary')} key="3">
          <DailySalesDashboard />
        </TabPane>

        <TabPane tab={t('netprofitreportsummary')} key="4">
          <NetProfitDashboard />
        </TabPane>

        <TabPane tab={t('inventorysummary')} key="5">
          <InventoryReportDashboard />
        </TabPane>

        <TabPane tab={t('studentsummary')} key="6">
          <StudentReportDashboard />
        </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default withRoleAccess(AdminDashboard, ['superadmin']);
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditTeacherModal = ({ isOpen, onClose, onSave, teacherId }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    email: '',
    nickname: '',
    date_of_birth: '',
    nationalid: '',
    bachelor_degree: '',
    branch: '',
    massageTherapyBackground: '',
    medicalHistory: '',
    applicationFee: '',
    nationality: '',
    race: '',
    religion: '',
    otherReligion: '',
    emergency_phone_number: '',
    color: '',
    height: '',
    weight: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeacherData = async () => {
      if (!teacherId) return;
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        setError(t('fetchTeacherError'));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTeacherData();
    }
  }, [teacherId, isOpen, API_URL, t]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const dataToSend = {
        ...formData,
        massage_therapy_background: formData.massageTherapyBackground,
        medical_history: formData.medicalHistory,
        application_fee: formData.applicationFee,
        emergency_phone_number: formData.emergency_phone_number  
      };
      await axios.put(`${API_URL}/teachers/${teacherId}`, dataToSend, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
      });
      onSave(dataToSend); // Call onSave to update the state and close the modal
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error updating teacher:', error);
      setError(t('updateTeacherError'));
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="panel-overlay">
      <div className="modal-content">
        <span className="modal-close-icon" onClick={onClose}>&times;</span>
        <div className="modal-header">
          <h2>{t('editTeacher')}</h2>
        </div>
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>{t('firstName')}:</label>
                <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('lastName')}:</label>
                <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('email')}:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('nickname')}:</label>
                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('dateOfBirth')}:</label>
                <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('nationalID')}:</label>
                <input type="text" name="nationalid" value={formData.nationalid} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('phone')}:</label>
                <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('address')}:</label>
                <input type="text" name="address" value={formData.address} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
            <div className="form-group">
                <label>{t('education')}:</label>
                <select
                    name="bachelor_degree"  // Match this to the formData property
                    value={formData.bachelor_degree}
                    onChange={handleChange}
                >
                    <option value="">{t('select')}</option>
                    <option value="below_highschool">{t('below_highschool')}</option>
                    <option value="highschool">{t('highschool')}</option>
                    <option value="bachelor_degree">{t('bachelor_degree')}</option>
                    <option value="above_bachelor_degree">{t('above_bachelor_degree')}</option>
                </select>
              </div>
              <div className="form-group">
                <label>{t('color')}:</label>
                {/* Display color in a non-editable input field */}
                <input 
                  type="text" 
                  name="color" 
                  value={formData.color} 
                  readOnly 
                  style={{ backgroundColor: formData.color, color: '#fff' }} // Display the color with a text color contrast
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('branch')}:</label>
                <select name="branch" value={formData.branch} onChange={handleChange}>
                  <option value="">{t('select_branch')}</option>
                  <option value="Bangkok">Bangkok</option>
                  <option value="Pattaya">Pattaya</option>
                </select>
              </div>
              <div className="form-group">
                <label>{t('massageTherapyBackground')}:</label>
                <textarea name="massageTherapyBackground" value={formData.massageTherapyBackground} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('medicalHistory')}:</label>
                <textarea name="medicalHistory" value={formData.medicalHistory} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('applicationFee')}:</label>
                <input type="text" name="applicationFee" value={formData.applicationFee} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('nationality')}:</label>
                <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('race')}:</label>
                <input type="text" name="race" value={formData.race} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('religion')}:</label>
                <select name="religion" value={formData.religion} onChange={handleChange}>
                  <option value="">{t('select_religion')}</option>
                  <option value="Buddhist">Buddhist</option>
                  <option value="Christian">Christian</option>
                  <option value="Catholic">Catholic</option>
                  <option value="Islam">Islam</option>
                  <option value="Others">Others</option>
                </select>
                {formData.religion === 'Others' && (
                  <input
                    type="text"
                    name="otherReligion"
                    value={formData.otherReligion}
                    onChange={handleChange}
                    placeholder={t('specify_other_religion')}
                  />
                )}
              </div>
              <div className="form-group">
                <label>{t('emergencyPhoneNumber')}:</label>
                <input type="text" name="emergencyPhoneNumber" value={formData.emergency_phone_number} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('height')} (cm):</label>
                <input type="number" name="height" value={formData.height} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label>{t('weight')} (kg):</label>
                <input type="number" name="weight" value={formData.weight} onChange={handleChange} />
              </div>
            </div>
            <div className="modal-actions">
              <button type="button" className="cancel-button" onClick={onClose}>{t('cancel')}</button>
              <button type="submit" className="submit-button">{t('save')}</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default withRoleAccess(EditTeacherModal, ['admin', 'superadmin']);

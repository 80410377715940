import React, { useState, useEffect } from 'react';
import { Table, Button, message, Spin, Select, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import moment from 'moment';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;

const FullyPaidInvoices = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [itemNames, setItemNames] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(50);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/payment/fully-paid-invoices`, {
          params: {
            month: moment(selectedDate).month() + 1,
            year: moment(selectedDate).year(),
          },
        });

        const filteredData = response.data.filter(invoice => {
          const category = invoice.category ? invoice.category.split(', ') : [];
          return category.includes('Course');
        });

        setData(filteredData);
        setFilteredData(filteredData);

        const uniqueItemNames = [...new Set(filteredData.map((invoice) => invoice.item_name))];
        setItemNames(uniqueItemNames);

      } catch (error) {
        console.error('Error fetching data:', error);
        message.error(t('fetchDataError'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate, API_URL, t]);

  const handleItemNameChange = (value) => {
    setSelectedItemName(value);
    if (value === '') {
      handleSearch(searchTerm);
    } else {
      const filtered = data.filter((invoice) =>
        invoice.item_name === value &&
        (invoice.firstnamethai.toLowerCase().includes(searchTerm.toLowerCase()) || 
        invoice.nationalid.includes(searchTerm))
      );
      setFilteredData(filtered);
    }
  };

  const handleSearch = (value) => {
    const searchValue = value ? value.toLowerCase() : '';
    setSearchTerm(searchValue);
  
    const filtered = data.filter((invoice) =>
      ((invoice.firstnamethai && invoice.firstnamethai.toLowerCase().includes(searchValue)) ||
       (invoice.lastnamethai && invoice.lastnamethai.toLowerCase().includes(searchValue)) ||
       (invoice.nationalid && invoice.nationalid.includes(searchValue)))
    );
  
    if (selectedItemName) {
      const filteredByItem = filtered.filter(
        (invoice) => invoice.item_name === selectedItemName
      );
      setFilteredData(filteredByItem);
    } else {
      setFilteredData(filtered);
    }
    setCurrentPage(1); // Reset to the first page
  };
  
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
  };

  const columns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('student'),
      key: 'studentNameThai',
      render: (text, record) => `${record.firstnamethai || ''} ${record.lastnamethai || ''}`,
      ellipsis: true,
    },
    {
      title: t('student (English)'),
      key: 'studentNameEnglish',
      render: (text, record) => `${record.firstnameenglish || ''} ${record.lastnameenglish || ''}`,
      ellipsis: true,
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('dateOfBirth'),
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      render: (date) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t('items'),
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity', // New column for quantity
      key: 'quantity',
    },
    {
      title: t('Already Paid'),
      dataIndex: 'total_paid_amount',
      key: 'total_paid_amount',
      render: (amount) => formatPrice(amount), // Use formatPrice function
    },
    {
      title: t('payment_date'),
      dataIndex: 'date_paid',
      key: 'date_paid',
      ellipsis: true,
      render: (date) => {
        if (!date) return 'N/A'; // Handle missing dates

        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',  
          day: '2-digit',
        }).format(new Date(date));
      }
      
    },
  ];

  const csvData = filteredData.map((invoice) => ({
    schoolid: invoice.schoolid,
    document_no: invoice.document_no,
    studentNameThai: `${invoice.firstnamethai} ${invoice.lastnamethai}`,
    studentNameEnglish: `${invoice.firstnameenglish} ${invoice.lastnameenglish}`,
    nickname: invoice.nickname,
    nationalid: invoice.nationalid,
    date_of_birth: moment(invoice.date_of_birth).format('DD/MM/YYYY'),
    category: invoice.category,
    items: invoice.item_name,
    quantity: invoice.quantity,
    total_paid_amount: formatPrice(invoice.total_paid_amount),
    date_paid: moment(invoice.date_paid).format('DD/MM/YYYY'),
  }));

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          showFourColumnMonthYearPicker
          className="ant-input" 
        />

          <Select
            value={selectedItemName}
            prefix={<SearchOutlined />}
            onChange={handleItemNameChange}
            style={{ width: 280, height: '40px', lineHeight: '40px' }} 
            placeholder={t('searchBySubject')}
            allowClear
            dropdownStyle={{ lineHeight: '40px' }}
          >
            {itemNames.map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>

        <Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={handleSearch}
          enterButton
          style={{ maxWidth: '300px' }}
        />

        <Button>
          <CSVLink data={csvData} filename="fully_paid_invoices.csv">
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
          </CSVLink>
        </Button>          
      </div>

      <Spin spinning={loading}>
        <Table 
          columns={columns} 
          dataSource={filteredData} 
          rowKey="id"
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredData.length,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['50', '100', '150'],
            onChange: handleTableChange,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Spin>
    </div>
  );
};

export default withRoleAccess(FullyPaidInvoices, ['superadmin', 'admin']);
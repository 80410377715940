import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Table, Tabs, notification, Descriptions, Row, Col, Input, Button, List} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faHome, faCakeCandles, faUserCheck, faExclamationTriangle, faCircleExclamation, faGraduationCap, faHospital} from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import StudentIDCard from './StudentIDCard';
import StudentPayment from './StudentPayment';
import Graduate from './Graduate';
import './Student.css'


const { TabPane } = Tabs;
const { TextArea } = Input;

function StudentDashboardAdmin() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [adminNote, setAdminNote] = useState(''); 
  const [notes, setNotes] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  

  const API_URL = process.env.REACT_APP_API_URL;

  // Function to format prices without the currency symbol
const formatPrice = (price) => {
  const formattedPrice = Number(price);
  return isNaN(formattedPrice)
    ? '0.00'
    : new Intl.NumberFormat('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedPrice);
};


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const studentResponse = await axios.get(`${API_URL}/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudent(studentResponse.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, [id, API_URL]);


  useEffect(() => {
    const fetchAttendanceLogs = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.data.length === 0) {
          notification.info({ message: t('Student has no attendance records') });
        } else {
          setAttendanceLogs(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          notification.info({ message: t('Student has no attendance records') });
        } else {
          console.error('Error fetching attendance logs:', error);
          notification.error({ message: t('Error fetching attendance logs') });
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchAttendanceLogs();
  }, [API_URL, id, t]);
  



// Wrap fetchNotes in useCallback to make it stable
const fetchNotes = useCallback(async () => {
  setNotesLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/student-info/student/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setNotes(response.data); // Set fetched notes to state
  } catch (error) {
    console.error('Error fetching notes:', error);
    notification.error({ message: 'Error fetching notes' });
  } finally {
    setNotesLoading(false);
  }
}, [API_URL, id]);

// Fetch notes when component mounts
useEffect(() => {
  fetchNotes();
}, [fetchNotes]);

  // Handle adding a new note
  const addNote = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/student-info`, {
        student_id: id,
        info_text: adminNote,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAdminNote(''); // Clear the input
      fetchNotes(); // Fetch notes again after adding
      notification.success({ message: 'Note added successfully!' });
    } catch (error) {
      console.error('Error adding note:', error);
      notification.error({ message: 'Error adding note' });
    }
  };

  const formatDate = (timestamp) => {
    // Add 543 years to convert to Thai Buddhist year
    return moment(timestamp).add(543, 'years').locale(i18n.language).format('LL'); // Localized Thai year format
  };
  
  const formatTime = (timestamp) => {
    return moment(timestamp).locale(i18n.language).format('HH:mm'); // 24-hour time format
  };
  

  const columnsAttendance = [
    { title: t('date'), key: 'date', render: (_, record) => formatDate(record.timestamp) },
    { title: t('time'), key: 'time', render: (_, record) => formatTime(record.timestamp) },
    { title: t('subject'), dataIndex: 'event_title', key: 'event_title' },
    { title: t('description'), dataIndex: 'attendance_description', key: 'attendance_description' },
    { title: t('teacher'), dataIndex: 'firstname', key: 'firstname', render: (_, record) => `${record.firstname} ${record.lastname}` },
  ];
  


  const educationLevels = {
    below_highschool: t('below_highschool'),
    highschool: t('highschool'),
    below_bachelor_degree: t('below_bachelor_degree'),
    bachelor_degree: t('bachelor_degree'),
    above_bachelor_degree: t('above_bachelor_degree'),
  };

  
  if (!student) return <p>{t('Loading...')}</p>;

  return (
    <div className="student-dashboard-container-admin">
      <Sidebar />
      <Header />
      <div className="content">
     

<Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '18px' }}>
  <TabPane tab={t('studentInfo')} key="1" className="tab-content">
          <Row gutter={24}>
              <Col span={7}>
                 <StudentIDCard student={student} />
                     </Col>
                         <Col span={17}>
                             <Card 
                               title={
                                 <div>
                                   <strong>{student.firstnamethai} {student.lastnamethai} ({student.nickname})</strong><br />
                                   {student.firstnameenglish} {student.lastnameenglish}
                                 </div>
                               }
                               style={{ marginBottom: 24, padding: '20px', borderRadius: '8px', backgroundColor: '#f5f5f5' }}
                               headStyle={{ fontSize: '18px', fontWeight: 'bold' }}
                             >
                               <Descriptions bordered size="small" column={1}>
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faUserCheck} /> {t('schoolid')}</>}>
                                   {student.schoolid}
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faUserCheck} /> {t('nationalid')}</>}>
                                   {student.nationalid}
                                 </Descriptions.Item>
                 
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faUser} /> {t('email')}</>}>
                                   {student.email}
                                 </Descriptions.Item>
                 
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faPhone} /> {t('phone')}</>}>
                                   {student.phone}
                                 </Descriptions.Item>
                 
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faHome} /> {t('address')}</>}>
                                   {student.address}
                                 </Descriptions.Item>
                 
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faCakeCandles} /> {t('dob')}</>}>
                                  {formatDate(student.date_of_birth)}
                                </Descriptions.Item>  
                                <Descriptions.Item label={<><FontAwesomeIcon icon={faGraduationCap} /> {t('education')}</>}>
                                  {educationLevels[student.education] || t('unknown')}
                                </Descriptions.Item>   
                                <Descriptions.Item label={<><FontAwesomeIcon icon={faUserCheck} /> {t('massage_education')}</>}>
                                  {student.massage_education}
                                </Descriptions.Item>  
                                <Descriptions.Item label={<><FontAwesomeIcon icon={faHospital} /> {t('medical_history')}</>}>
                                  {student.medicalhistory}
                                </Descriptions.Item>    
                                 <Descriptions.Item label={<><FontAwesomeIcon icon={faCircleExclamation} /> {t('emergencyPhoneNumber')}</>}>
                                   {student.emergency_phone_number}
                                 </Descriptions.Item>
                               </Descriptions>
                               <Descriptions.Item>
                               <div style={{ marginTop: '20px', padding: '10px', margin: '10px', backgroundColor: student.blacklist ? '#ffe6e6' : '#e6ffe6', borderRadius: '5px' }}>
                                <strong>
                                  <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: student.blacklist ? 'red' : 'green' }} />{' '}
                                  {t('Blacklist')}:
                                </strong>{' '}
                                {student.blacklist ? (
                                  <span style={{ color: 'red' }}>
                                    {t(`blacklistReasons.${student.blacklistreason}`, { defaultValue: student.blacklistreason })}
                                  </span>
                                ) : (
                                  <span>{t('No')}</span>
                                )}
                              </div>
                               </Descriptions.Item>
                               {/* Admin Note Section */}
                               <Descriptions.Item label={<><FontAwesomeIcon icon={faExclamationTriangle} /> {t('Admin Note')}</>}>
                              {/* Display List of Notes */}
                              <List
                                bordered
                                loading={notesLoading}
                                dataSource={notes}
                                locale={{ emptyText: t('No notes available') }} // Handle empty state
                                renderItem={note => (
                                  <List.Item>
                                    <List.Item.Meta
                                      title={`${note.created_by_nickname} - ${moment(note.created_at).format('LLL')}`}
                                      description={note.info_text}
                                    />
                                  </List.Item>
                                )}
                              />

                              <TextArea
                                rows={3}
                                value={adminNote}
                                onChange={(e) => setAdminNote(e.target.value)}
                                placeholder={t('enterStudentInfo')}
                              />
                              <Button style={{ marginTop: '10px' }} onClick={addNote}>
                                {t('add')}
                              </Button>
                            </Descriptions.Item>
                </Card>
              </Col>
            </Row>
          </TabPane>


  <TabPane tab={t('Billing & Invoice List')} key="2" className="tab-content">
<StudentPayment />
  </TabPane>

  <TabPane tab={t('Attendance List')} key="3" className="tab-content">
    <Table
      dataSource={attendanceLogs}
      columns={columnsAttendance}
      rowKey="eventid"
      loading={loading}
    />
  </TabPane>


  <TabPane tab={t('Graduation Status')} key="4" className="tab-content">
  <Graduate />
  </TabPane>
</Tabs>

      </div>

    </div>
  );
}

export default withRoleAccess(StudentDashboardAdmin, ['admin', 'superadmin']);
